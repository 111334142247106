/******************************************************************************\
 * File: Error.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Error extends React.Component {
  render() {
    const { className } = this.props;

    const cn = classNames(styles.Error, {
      [className]: className,
    });

    return (
      <svg className={cn} viewBox="0 0 22 19">
        <polygon points="0 19 22 19 11 0" />
        <path
          fill="#FFFFFF"
          d="M12,16 L10,16 L10,14 L12,14 L12,16 Z M12,12 L10,12 L10,8 L12,8 L12,12 Z"
        />
      </svg>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Error;
