// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._35QB8yDbZvTCqJePOrwuAA{display:flex;flex-direction:column;width:100%;min-height:100%}._35QB8yDbZvTCqJePOrwuAA .DC3EW1F-3VPpLjaqlSwqC{display:flex;align-items:stretch;padding-top:120px;flex-grow:2;background-color:#fafafb;width:100%;min-height:100%}._35QB8yDbZvTCqJePOrwuAA .DC3EW1F-3VPpLjaqlSwqC._2gvolpoICvpGreV-zPla_u{padding-top:0}._35QB8yDbZvTCqJePOrwuAA .DC3EW1F-3VPpLjaqlSwqC ._1412YxBwhQSjEdffN9P5Ha{font-weight:bold;text-decoration:underline;font-size:16px;color:#08a59d;margin-top:12px}@media only screen and (max-width: 955px){._35QB8yDbZvTCqJePOrwuAA .DC3EW1F-3VPpLjaqlSwqC{padding-top:74px}}", ""]);
// Exports
exports.locals = {
	"Dashboard": "_35QB8yDbZvTCqJePOrwuAA",
	"Content": "DC3EW1F-3VPpLjaqlSwqC",
	"fullScreen": "_2gvolpoICvpGreV-zPla_u",
	"Links": "_1412YxBwhQSjEdffN9P5Ha"
};
module.exports = exports;
