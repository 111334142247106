//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { ChecklistIcon } from '@cmp/images';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Checkbox extends React.Component {
  render() {
    const { checked, onCheck, children } = this.props;

    const componentClasses = classNames(styles.Checkbox, {
      [styles.Checked]: checked,
    });

    return (
      <div className={componentClasses} onClick={onCheck}>
        <div className={styles.CheckboxInput}>
          {checked && <ChecklistIcon />}
        </div>
        <span className={styles.Text}>{children}</span>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Checkbox;
