//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import ReactSelect, { components } from 'react-select';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
const customStyles = {};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Select extends React.Component {
  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.Select, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <ReactSelect styles={customStyles} {...this.props} />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Select;
