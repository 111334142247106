//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import GET from '@api/methods/get';
//------------------------------------------------------------------------------
// Endpoints -------------------------------------------------------------------
const Endpoints = {
  Products: {
    url: '/products',
  },
  FeaturedCollections: {
    url: '/products/featured-collections',
  },
  Detail: ({ id }) => ({
    url: `/products/${id}`,
  }),
  Metafields: ({ id }) => ({
    url: `/products/${id}/metafields`,
  }),
};
//------------------------------------------------------------------------------
// React Classes ---------------------------------------------------------------
export const Products = ({ children, ...props }) => {
  return (
    <GET endpoint={Endpoints.Products} {...props}>
      {children}
    </GET>
  );
};

export const Detail = ({ children, pathParams, ...props }) => {
  return (
    <GET endpoint={Endpoints.Detail(pathParams)} {...props}>
      {children}
    </GET>
  );
};

export const FeaturedCollections = ({ children, ...props }) => {
  return (
    <GET endpoint={Endpoints.FeaturedCollections} {...props}>
      {children}
    </GET>
  );
};

export const Metafields = ({ children, pathParams, ...props }) => {
  return (
    <GET endpoint={Endpoints.Metafields(pathParams)} {...props}>
      {children}
    </GET>
  );
};
