// to-do: join all these metafield constants into one w/ formatted objects
// this is ugly

export const MetafieldsReadableKeys = {
  details: 'Details',
  characteristics: 'Characteristics',
  benefits: 'Benefits',
  indications_and_usage: 'Indications and Usage',
  information: 'Information',
  safety_info: 'Important Safety Information',
  faq: 'Product FAQ',
  educational_materials: 'Educational Materials',
};

export const MetafieldsKeys = {
  DETAILS: 'details',
  CHARACTERISTICS: 'characteristics',
  BENEFITS: 'benefits',
  INDICATIONS_AND_USAGE: 'indications_and_usage',
  INFORMATION: 'information',
  SAFETY_INFO: 'safety_info',
  FAQ: 'faq',
  DISCOUNTED_PRICE: 'discounted_price',
  FULL_PRICE: 'full_price',
  VIDEOS: 'videos',
  EDUCATIONAL_MATERIALS: 'educational_materials',
};

export const MetafieldOrder = [
  MetafieldsKeys.DETAILS,
  MetafieldsKeys.CHARACTERISTICS,
  MetafieldsKeys.BENEFITS,
  MetafieldsKeys.INDICATIONS_AND_USAGE,
  MetafieldsKeys.INFORMATION,
  MetafieldsKeys.SAFETY_INFO,
  MetafieldsKeys.FAQ,
  MetafieldsKeys.EDUCATIONAL_MATERIALS,
];

export const ProductsSortingOptions = [
  { value: 'best-selling desc', label: 'Best Selling', default: true },
  { value: 'published_at desc', label: 'Newest Added' },
  { value: 'price asc', label: 'Price Low to High' },
  { value: 'price desc', label: 'Price High to Low' },
];
