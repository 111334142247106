// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2GIE0YdhcFquwJCq0TFhsb{display:flex;justify-content:center;align-items:center;background-color:#fafafb;height:100%;overflow:scroll}", ""]);
// Exports
exports.locals = {
	"VerifyEmail": "_2GIE0YdhcFquwJCq0TFhsb"
};
module.exports = exports;
