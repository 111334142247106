//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Form extends React.Component {
  render() {
    const {
      className,
      children,
      title,
      subtitle,
      submit,
      onSubmit,
      submitTo,
      additionalComponent,
      loading,
      errorMessage,
      disabled,
      id,
    } = this.props;

    const componentClasses = classNames(styles.Form, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <h2 className={styles.Title}>{title}</h2>
        {subtitle && <h2 className={styles.Subtitle}>{subtitle}</h2>}
        {(children || id) && (
          <div className={styles.Content} id={id}>
            {children}
          </div>
        )}
        {submit && (
          <Button
            onClick={onSubmit}
            to={submitTo}
            loading={loading}
            className={styles.Submit}
            style={ButtonStyle.Secondary}
            disabled={disabled}
          >
            {submit}
          </Button>
        )}
        {errorMessage && (
          <span className={styles.ErrorMessage}>{errorMessage}</span>
        )}
        {additionalComponent}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Form;
