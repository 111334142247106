export const ReferralStatus = {
  Purchased: {
    className: 'Purchased',
    title: 'Purchased',
    i18nKey: 'dashboard_Purchased',
  },
  AdditionalPurchase: {
    className: 'AdditionalPurchase',
    title: 'Additional Purchase',
    i18nKey: 'dashboard_AdditionalPurchase',
  },
  PendingAdditionalPurchase: {
    className: 'PendingAdditionalPurchase',
    title: 'Pending Additional Purchase',
    i18nKey: 'dashboard_PendingAdditionalPurchase',
  },
  PartiallyPurchased: {
    className: 'PartiallyPurchased',
    title: 'Partially Purchased',
    i18nKey: 'dashboard_PartiallyPurchased',
  },
  Pending: {
    className: 'Pending',
    title: 'Pending',
    i18nKey: 'dashboard_Pending',
  },
};

export const AllReferralStatuses = [
  ReferralStatus.Purchased,
  ReferralStatus.PartiallyPurchased,
  ReferralStatus.AdditionalPurchase,
  ReferralStatus.PendingAdditionalPurchase,
  ReferralStatus.Pending,
];

export const ReferralSortingOptions = (t) => [
  { value: 'consumerName ASC', label: t('dashboard_PatientName') },
  { value: 'createdAt DESC', label: t('dashboard_Date'), default: true },
  { value: 'totalCommissionValue DESC', label: t('dashboard_Earnings')},
  { value: 'status DESC', label: t('dashboard_Status') },
];
