//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import FocusTrap from 'focus-trap-react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Button, ButtonStyle } from '@cmp/common';
import ModalBase from '../base';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class GenericModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTrap: false,
    };
    this.mountTrap = this.mountTrap.bind(this);
    this.unmountTrap = this.unmountTrap.bind(this);
  }
  componentDidMount() {
    if (document.getElementById('truste-consent-content') !== null) {
      this.unmountTrap();
    } else {
      this.mountTrap();
    }
  }
  mountTrap() {
    this.setState({ activeTrap: true });
  }
  unmountTrap() {
    this.setState({ activeTrap: false });
  }

  render() {
    const {
      history,
      location,
      title,
      subtitle,
      action,
      to,
      onClick,
      onClose,
    } = this.props;
    const componentClasses = classNames(styles.GenericModal);

    return this.state.activeTrap ? (
      <FocusTrap>
        <Modal.Base
          className={componentClasses}
          onClose={onClose || (() => history.push(location.state.from))}
        >
          <h2 className={styles.Title}>{title}</h2>
          <span className={styles.Subtitle} tabIndex="0">
            {subtitle}
          </span>
          {action && (
            <Button
              style={ButtonStyle.Secondary}
              to={to}
              onClick={onClick}
              onKeyPress={onClick}
              tabIndex="0"
            >
              {action}
            </Button>
          )}
        </Modal.Base>
      </FocusTrap>
    ) : (
      <Modal.Base
        className={componentClasses}
        onClose={onClose || (() => history.push(location.state.from))}
      >
        <h2 className={styles.Title}>{title}</h2>
        <span className={styles.Subtitle} tabIndex="0">
          {subtitle}
        </span>
        {action && (
          <Button
            style={ButtonStyle.Secondary}
            to={to}
            onClick={onClick}
            onKeyPress={onClick}
            tabIndex="0"
          >
            {action}
          </Button>
        )}
      </Modal.Base>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(GenericModal);
