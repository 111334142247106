// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Th4LIAltvzoUGuSvuW-5q{display:flex;flex-direction:column;width:100%;min-height:100%}._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN{display:flex;align-items:stretch;padding-top:120px;flex-grow:2;background-color:#fafafb;width:100%;min-height:100%}._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN .xhDr5Ra8G9kimfa5bZxxl{display:flex;flex-direction:column;margin:0 auto 0 auto;width:100%;padding:0 100px 50px 100px;max-width:1600px}@media only screen and (max-width: 576px){._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN .xhDr5Ra8G9kimfa5bZxxl{width:95%}}._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN .xhDr5Ra8G9kimfa5bZxxl>*{margin-bottom:48px}._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN .xhDr5Ra8G9kimfa5bZxxl>*:last-child{margin-bottom:0}._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN .xhDr5Ra8G9kimfa5bZxxl ._2dE7bYvhqLzXmatXqjlhoI{display:flex;justify-content:space-between;align-items:center}._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN .xhDr5Ra8G9kimfa5bZxxl ._2dE7bYvhqLzXmatXqjlhoI ._1pa5Hh6w1pPS-7X54Um6ER{font-weight:900;font-size:30px;color:#d2010d}@media only screen and (max-width: 576px){._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN .xhDr5Ra8G9kimfa5bZxxl ._2dE7bYvhqLzXmatXqjlhoI ._1pa5Hh6w1pPS-7X54Um6ER{font-size:26px}}@media only screen and (max-width: 955px){._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN .xhDr5Ra8G9kimfa5bZxxl{padding:56px 16px 0 16px}._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN .xhDr5Ra8G9kimfa5bZxxl>*{margin-bottom:15px}._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN .xhDr5Ra8G9kimfa5bZxxl>*:last-child{margin-bottom:0}}@media only screen and (max-width: 955px){._2Th4LIAltvzoUGuSvuW-5q ._1jS9FEgViCq3VdSeTGCQWN{padding-top:74px}}", ""]);
// Exports
exports.locals = {
	"PatientDirectory": "_2Th4LIAltvzoUGuSvuW-5q",
	"Content": "_1jS9FEgViCq3VdSeTGCQWN",
	"Container": "xhDr5Ra8G9kimfa5bZxxl",
	"Header": "_2dE7bYvhqLzXmatXqjlhoI",
	"Title": "_1pa5Hh6w1pPS-7X54Um6ER"
};
module.exports = exports;
