//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import qs from 'query-string';
import client from '@api/client';
import GET from '@api/methods/get';

//------------------------------------------------------------------------------
// Endpoints -------------------------------------------------------------------
const Endpoints = {
  Providers: {
    url: `/providers/me`,
  },
};
//------------------------------------------------------------------------------
// React Classes ---------------------------------------------------------------
export const Providers = ({ children, pathParams, ...props }) => {
  return (
    <GET endpoint={Endpoints.Providers} {...props}>
      {children}
    </GET>
  );
};
//------------------------------------------------------------------------------
// Inline ----------------------------------------------------------------------
export const loadProviders = (params) => {
  const stringifiedParams = qs.stringify(params, {
    encode: false,
    skipNull: true,
  });
  return client.get(`${Endpoints.Providers.url}?${stringifiedParams}`);
};

export const loadEligibileRewardSelections = () => {
  return client.get('/providers/eligible-reward-selections');
};
