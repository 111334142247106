//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Header, Form, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { ScreenSet, showScreenSet } from '@helpers/gigya';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { DoNotSellMyPersonalDataURL } from '@helpers/constants/colgate';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SignIn extends React.Component {
  componentDidMount() {
    showScreenSet(ScreenSet.SignIn, 'login-container');
  }

  additionalComponent() {
    const { t } = this.props;
    return (
      <>
        <Link to="/signup" className={classNames(styles.Link, styles.Create)}>
          {t('login_CreateAccount')}
        </Link>
        <div className={styles.Footer}>
          <div className={styles.Row}>
            <Link to="/terms-of-service">{t('login_TermsOfService')}</Link>
            <span>&bull;</span>
            <Link to="/privacy-policy">{t('login_PrivacyPolicy')}</Link>
          </div>
          <a
            rel="noopener noreferrer"
            href={DoNotSellMyPersonalDataURL}
            target="_blank"
          >
            {t('login_DoNotSellPersonalData')}
          </a>
          <Link to="/help" target="_blank">
            {t('login_Help')}
          </Link>
        </div>
      </>
    );
  }

  render() {
    const { t } = this.props;
    const componentClasses = classNames(styles.SignIn);

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('landing_LogIn')}</title>
        </Helmet>

        <Header />
        <div className={styles.Content}>
          <Form
            className={styles.Form}
            title={t('login_DentalProfessionalLogin')}
            subtitle={t('login_DentalProfessionalLoginSubtitle')}
            onSubmit={() => this.setState({ submitted: true })}
            additionalComponent={this.additionalComponent()}
            id="login-container"
          />
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(SignIn);
