// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sNZ7t6j4jLMJH0JyVt9Yh{display:flex;justify-content:center;align-items:flex-start;background-color:#fafafb;height:100%;padding-top:100px;overflow:scroll}.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg{max-width:716px}.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg ._1-WWd0aFHb4zRDz1LqAnHu{display:flex}.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg ._1-WWd0aFHb4zRDz1LqAnHu>*{margin-right:8px}.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg ._1-WWd0aFHb4zRDz1LqAnHu>*:last-child{margin-right:0}.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg ._23PSsUAICDocv_hDLYyg28{display:flex;flex-direction:column}.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg ._23PSsUAICDocv_hDLYyg28 ._140H0EnKSqg72quGOEEzrz{align-self:flex-start;margin-top:10px;font-size:12px;color:#959392}.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg ._11BWCVUOjtkzSAnYrSYVXH{display:flex;flex-direction:column;margin-top:25px}.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg ._11BWCVUOjtkzSAnYrSYVXH ._1SKw5afsRHsPyA9MZlsAJ8{font-weight:bold;font-size:14px;color:#e23023;margin-bottom:39px}.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg ._11BWCVUOjtkzSAnYrSYVXH ._1SKw5afsRHsPyA9MZlsAJ8._1qx9ehxV785JlFEXZRELVF{color:#959392;margin-bottom:10px}.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg ._11BWCVUOjtkzSAnYrSYVXH ._1SKw5afsRHsPyA9MZlsAJ8:last-child{margin-bottom:0}.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg ._11BWCVUOjtkzSAnYrSYVXH ._1x2s5dASXWmJXy4LJOqAHw{font-size:14px;color:#8e8e8e;font-weight:bold}@media only screen and (max-width: 955px){.sNZ7t6j4jLMJH0JyVt9Yh ._3sgiOUYjA7lI6Ggpr2Nhzg{width:90%}}", ""]);
// Exports
exports.locals = {
	"SignUp": "sNZ7t6j4jLMJH0JyVt9Yh",
	"Form": "_3sgiOUYjA7lI6Ggpr2Nhzg",
	"Row": "_1-WWd0aFHb4zRDz1LqAnHu",
	"Password": "_23PSsUAICDocv_hDLYyg28",
	"Subtitle": "_140H0EnKSqg72quGOEEzrz",
	"AdditionalComponent": "_11BWCVUOjtkzSAnYrSYVXH",
	"Link": "_1SKw5afsRHsPyA9MZlsAJ8",
	"SecondaryLink": "_1qx9ehxV785JlFEXZRELVF",
	"Text": "_1x2s5dASXWmJXy4LJOqAHw"
};
module.exports = exports;
