//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class InfluencerVideo extends React.Component {
  render() {
    const { t } = this.props;
    const componentClasses = classNames(styles.InfluencerVideo);
    return (
      <div className={componentClasses}>
        <div className={styles.Box}>
          <div className={styles.Container}>
            <iframe
              title="Improve Patient Compliance"
              className={styles.ResponsiveIframe}
              src={t('landing_InfluencerVideoUrl')}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(InfluencerVideo);
