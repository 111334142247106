// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1jx0XAxumlD4XMjPANOb2m{display:flex;flex-direction:column;align-items:center;color:#fff;background-color:#e23023;padding:85px 0 70px 0}._1jx0XAxumlD4XMjPANOb2m ._1t9WQcnkuyheHwQvXKx_YZ{font-weight:bold;font-size:40px;margin-bottom:8px}._1jx0XAxumlD4XMjPANOb2m ._1DqW0wK2YrWBgN6td7_LU5{font-weight:normal;font-size:16px;margin-bottom:40px}._1jx0XAxumlD4XMjPANOb2m ._3SD65DK5ZzE0gLQZw92qK6{font-size:16px}@media only screen and (max-width: 955px){._1jx0XAxumlD4XMjPANOb2m{padding:40px 40px 80px 40px;text-align:center}}", ""]);
// Exports
exports.locals = {
	"CreateAccount": "_1jx0XAxumlD4XMjPANOb2m",
	"Title": "_1t9WQcnkuyheHwQvXKx_YZ",
	"Subtitle": "_1DqW0wK2YrWBgN6td7_LU5",
	"Button": "_3SD65DK5ZzE0gLQZw92qK6"
};
module.exports = exports;
