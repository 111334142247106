//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { withAuthUser } from '@helpers/auth';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.renderRoute = this.renderRoute.bind(this);
  }

  renderRoute(props) {
    const {
      __authUser,
      validation,
      redirectTo,
      component: Component,
      publicComponent: PublicComponent,
    } = this.props;

    const hasAuthenticationUser = __authUser && __authUser.UID && !validation;
    const isValidated = validation && validation(__authUser);

    if (hasAuthenticationUser || isValidated) return <Component {...props} />;
    if (PublicComponent) return <PublicComponent {...props} />;

    return (
      <Redirect
        to={{
          pathname: redirectTo || '/',
          state: { from: props.location },
        }}
      />
    );
  }

  render() {
    const { render, ...rest } = this.props;

    return (
      <Route
        {...rest}
        component={undefined}
        render={render || this.renderRoute}
      />
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(PrivateRoute);
