// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3rcyoe1dwOdrlW1xSVK98f{display:flex}._3rcyoe1dwOdrlW1xSVK98f>*{margin-right:10px}._3rcyoe1dwOdrlW1xSVK98f>*:last-child{margin-right:0}._3rcyoe1dwOdrlW1xSVK98f ._3vS8jltR6IfCyzotGzm5bI{cursor:pointer;padding:10px}._3rcyoe1dwOdrlW1xSVK98f ._3vS8jltR6IfCyzotGzm5bI:hover{opacity:.8}", ""]);
// Exports
exports.locals = {
	"Paginate": "_3rcyoe1dwOdrlW1xSVK98f",
	"Icon": "_3vS8jltR6IfCyzotGzm5bI"
};
module.exports = exports;
