// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Msi0O_rCXmhOZiFJmveu2{display:flex;align-items:stretch;padding-top:120px;flex-grow:2;display:flex;flex-direction:column;margin:0 100px;width:100%;max-width:550px}@media only screen and (max-width: 955px){._1Msi0O_rCXmhOZiFJmveu2{margin:0 auto;max-width:90%}}._9geLCoKuTlYX4GFsdpXJ9{min-height:100%;background-color:#fafafb;display:flex;justify-content:center;align-items:flex-start;background-color:#fafafb;height:100%;overflow:scroll}._9geLCoKuTlYX4GFsdpXJ9 ._2MD9c-H4w1KOiz69fkQmUo{display:flex;justify-content:space-between;align-items:baseline;flex-shrink:0}._9geLCoKuTlYX4GFsdpXJ9 ._2MD9c-H4w1KOiz69fkQmUo ._1IloiU_xFfWc6hUL08M60Z{font-weight:900;color:#d2010d;font-size:30px}._9geLCoKuTlYX4GFsdpXJ9 ._2MD9c-H4w1KOiz69fkQmUo ._2VE28l2IPZ9ZDN2CNOJ8Az{font-weight:bold;font-size:14px;color:#e23023}._9geLCoKuTlYX4GFsdpXJ9 ._2MD9c-H4w1KOiz69fkQmUo ._2KKmWWSu27wvC-9OzE9yB4{border-radius:30px;border-top-right-radius:0;font-weight:bold;font-size:14px;color:#fff;background-color:#e23023}._9geLCoKuTlYX4GFsdpXJ9 ._2R0YHj2ZZ7jyfZfndNHQjc{display:flex;flex-direction:column;flex-shrink:0;padding:33px;margin-top:24px;background:#fff;box-shadow:0px 2px 6px rgba(0,0,0,.06)}._9geLCoKuTlYX4GFsdpXJ9 ._2R0YHj2ZZ7jyfZfndNHQjc>*{margin-bottom:12px}._9geLCoKuTlYX4GFsdpXJ9 ._2R0YHj2ZZ7jyfZfndNHQjc>*:last-child{margin-bottom:0}._9geLCoKuTlYX4GFsdpXJ9 ._2R0YHj2ZZ7jyfZfndNHQjc ._3vlDb5ydmpbRdZ94-DV-R-{align-self:center}@media only screen and (max-width: 955px){._9geLCoKuTlYX4GFsdpXJ9 ._2MD9c-H4w1KOiz69fkQmUo{flex-direction:column}._9geLCoKuTlYX4GFsdpXJ9 ._2MD9c-H4w1KOiz69fkQmUo>*{margin-bottom:6px}._9geLCoKuTlYX4GFsdpXJ9 ._2MD9c-H4w1KOiz69fkQmUo>*:last-child{margin-bottom:0}._9geLCoKuTlYX4GFsdpXJ9 ._2MD9c-H4w1KOiz69fkQmUo ._1IloiU_xFfWc6hUL08M60Z{font-size:24px}._9geLCoKuTlYX4GFsdpXJ9 ._2R0YHj2ZZ7jyfZfndNHQjc{padding:0 10px;margin-top:18px}}", ""]);
// Exports
exports.locals = {
	"Wrapper": "_1Msi0O_rCXmhOZiFJmveu2",
	"Profile": "_9geLCoKuTlYX4GFsdpXJ9",
	"Header": "_2MD9c-H4w1KOiz69fkQmUo",
	"Title": "_1IloiU_xFfWc6hUL08M60Z",
	"Help": "_2VE28l2IPZ9ZDN2CNOJ8Az",
	"Payout": "_2KKmWWSu27wvC-9OzE9yB4",
	"Content": "_2R0YHj2ZZ7jyfZfndNHQjc",
	"ErrorMessage": "_3vlDb5ydmpbRdZ94-DV-R-"
};
module.exports = exports;
