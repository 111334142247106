// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2R8iCXM4w36N2eW1pbRY89{padding:9px 23px;border-radius:30px;font-weight:bold;font-size:14px;color:#fff;background-color:#e23023;text-align:center}._2R8iCXM4w36N2eW1pbRY89:hover{color:#fff}@media screen and (max-width: 1000px){._2R8iCXM4w36N2eW1pbRY89{padding:5px 10px;font-size:11px}}@media only screen and (max-width: 320px){._2R8iCXM4w36N2eW1pbRY89{padding:10px 15px;font-size:12px}}@media only screen and (max-width: 576px){._2R8iCXM4w36N2eW1pbRY89{padding:10px 15px;font-size:14px}}@media only screen and (max-height: 500px)and (orientation: landscape){._2R8iCXM4w36N2eW1pbRY89{padding:10px 15px;font-size:14px}}@media only screen and (min-width: 768px){._2R8iCXM4w36N2eW1pbRY89{padding:10px;font-size:14px}}", ""]);
// Exports
exports.locals = {
	"ViewRecommendationsButton": "_2R8iCXM4w36N2eW1pbRY89"
};
module.exports = exports;
