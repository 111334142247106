//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import GET from '@api/methods/get';
//------------------------------------------------------------------------------
// Endpoints -------------------------------------------------------------------
const Endpoints = {
  History: {
    url: '/referrals',
  },
  Details: ({ id }) => ({
    url: `/referrals/${id}`,
  }),
};
//------------------------------------------------------------------------------
// React Classes ---------------------------------------------------------------
export const History = ({ children, ...props }) => {
  return (
    <GET endpoint={Endpoints.History} {...props}>
      {children}
    </GET>
  );
};

export const Details = ({ children, pathParams, ...props }) => {
  return (
    <GET endpoint={Endpoints.Details(pathParams)} {...props}>
      {children}
    </GET>
  );
};
