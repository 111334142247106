import client from '@api/client';

// Although finishConnect is a GET, it's in this folder to keep both start and
// finish in the same place

export const startConnect = () => {
  return client.post('/payments/start-connect');
};

export const finishConnect = (state, code) => {
  return client.get('/payments/finish-connect', {
    params: { state, code },
  });
};

export const sendAmazonProfile = (profile) => {
  return client.post('/payments/amazonprofile', {
    profile: profile,
  });
};

export const amazonPay = (amazonAccountId) => {
  return client.post('/payments/amazon-pay', {
    amazonAccountId,
  });
};
