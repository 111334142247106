// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3gWHyg0WamR24M_FRhSKR9{display:flex;justify-content:center;align-items:flex-start;background-color:#fafafb;height:100%;overflow:scroll}._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI{margin-top:100px}._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI ._3TOoINxmypQvLuwMmmzBCn ._3dU2fMq9B-wyCUzTvd21Mi{font-weight:bold;font-size:14px;color:#e23023;margin-top:12px}._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI ._3TOoINxmypQvLuwMmmzBCn ._3dU2fMq9B-wyCUzTvd21Mi.FyLuBiVk-xtbp_QHYxDjM{margin-top:24px}._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI ._3TOoINxmypQvLuwMmmzBCn ._3WIm1KlBSX2drEQs0u01ee{display:flex;flex-direction:column;margin-top:46px;font-weight:bold;font-size:14px;color:#959392}._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI ._3TOoINxmypQvLuwMmmzBCn ._3WIm1KlBSX2drEQs0u01ee>*{margin-bottom:10px}._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI ._3TOoINxmypQvLuwMmmzBCn ._3WIm1KlBSX2drEQs0u01ee>*:last-child{margin-bottom:0}._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI ._3TOoINxmypQvLuwMmmzBCn ._3WIm1KlBSX2drEQs0u01ee ._8ALnVgXNmMpaE_KAmvMEn{display:flex}._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI ._3TOoINxmypQvLuwMmmzBCn ._3WIm1KlBSX2drEQs0u01ee ._8ALnVgXNmMpaE_KAmvMEn>*{margin-right:4px}._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI ._3TOoINxmypQvLuwMmmzBCn ._3WIm1KlBSX2drEQs0u01ee ._8ALnVgXNmMpaE_KAmvMEn>*:last-child{margin-right:0}._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI ._3TOoINxmypQvLuwMmmzBCn ._3WIm1KlBSX2drEQs0u01ee a{color:inherit}@media only screen and (max-width: 955px){._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI{width:90%}._3gWHyg0WamR24M_FRhSKR9 .Ylvi_lz1PrdkD_WyTQfpI ._3TOoINxmypQvLuwMmmzBCn{width:100%}}", ""]);
// Exports
exports.locals = {
	"SignIn": "_3gWHyg0WamR24M_FRhSKR9",
	"Content": "Ylvi_lz1PrdkD_WyTQfpI",
	"Form": "_3TOoINxmypQvLuwMmmzBCn",
	"Link": "_3dU2fMq9B-wyCUzTvd21Mi",
	"Create": "FyLuBiVk-xtbp_QHYxDjM",
	"Footer": "_3WIm1KlBSX2drEQs0u01ee",
	"Row": "_8ALnVgXNmMpaE_KAmvMEn"
};
module.exports = exports;
