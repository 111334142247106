//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import { hot } from 'react-hot-loader/root';
import React from 'react';
import qs from 'query-string';
import { Switch, Route, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './App.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import {
  About,
  Dashboard,
  Docs,
  DocsPage,
  Help,
  Welcome,
  Callback,
  Registration,
  PatientDirectory,
  Profile,
  ProfileKind,
  RewardSelection,
  PatientOrders,
} from '@cmp/client';
import { ErrorBoundary, PrivateRoute } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { version } from '~/package.json';
import { auth, deauth, withAuthUser } from '@helpers/auth';
import i18n from '@helpers/i18n';
import { withTranslation } from 'react-i18next';
import {
  completedRegistration,
  checkReferralAndAuthorize,
} from '@helpers/account';
import { checkTouchCapabilities } from '@helpers/device';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { signIn } from '@api/endpoints/post/auth';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class App extends React.Component {
  componentDidMount() {
    checkTouchCapabilities();
  }

  componentWillMount() {
    checkReferralAndAuthorize();
  }

  render() {
    const { __authUser, t } = this.props;
    const componentClasses = classNames(styles.App);

    return (
      <div className={componentClasses}>
        <Helmet
          titleTemplate={`%s | ${t('header_title')}`}
          defaultTitle={`${t('header_title')} - ${t(
            'header_MakeRecommendationsReality'
          )}`}
        />

        <ErrorBoundary>
          <Switch>
            {/* Set up basic routes */}
            <Route
              path="/terms-of-service"
              render={(props) => <Docs page={DocsPage.TERMS_OF_SERVICE} />}
            />
            <Route
              path="/privacy-policy"
              render={(props) => <Docs page={DocsPage.PRIVACY_POLICY} />}
            />
            <Route path="/help" component={Help} />
            <Route path="/about" component={About} />
            {/* Expose the profile route for both authenticated and
              unauthenticated users */}
            <Route
              exact
              path="/myaccount"
              render={(props) => (
                <Profile {...props} kind={ProfileKind.PersonalInfo} />
              )}
            />
            <Route
              exact
              path="/myaccount/update-reward-selection"
              render={(props) => <RewardSelection {...props} />}
            />
            <Route
              exact
              path="/myaccount/password"
              render={(props) => (
                <Profile {...props} kind={ProfileKind.Password} />
              )}
            />

            {/* Set up registration pages in case the user hasn't completed
              their profile or hasn't verified their email */}
            {/* <Route path="/registration" component={Registration} />

            {__authUser && !completedRegistration(__authUser) && (
              <Route path="/" component={Callback} />
            )} */}

            {/* Set up patient directory page */}
            {/* <PrivateRoute
              path="/patient-directory/orders"
              component={PatientOrders}
            /> */}

            {/* <PrivateRoute
              path="/patient-directory"
              component={PatientDirectory}
            /> */}

            {/* Finally, present either the dashboard or the welcome (landing,
              sign in...) based on authentication status */}
            <PrivateRoute
              path="/"
              //component={Dashboard}
              component={Welcome}
              publicComponent={Welcome}
            />

            <Redirect from="*" to="/" />
          </Switch>
        </ErrorBoundary>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default hot(withAuthUser(withTranslation()(App)));
