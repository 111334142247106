//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { default as RegistrationComplete } from './complete';
import { default as VerifyEmail } from './verifyEmail';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Registration extends React.Component {
  componentDidMount() {
    this.verifySource();
  }

  verifySource() {
    const {
      history,
      location: { pathname, state },
    } = this.props;

    if (state && state.fromCallback) {
      // Clearing the state, so when a user refreshes `fromCallback`
      // will be false
      history.replace(pathname);
    } else {
      history.replace('/');
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/registration" component={RegistrationComplete} />
      </Switch>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Registration;
