//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { storeValue, getValue, removeItem } from '@helpers/localStorage';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import {
  VerifiedStripeKey,
  SkipStripe,
  ReferralKey,
} from '@helpers/constants/localStorage';
//------------------------------------------------------------------------------
// Exports ---------------------------------------------------------------------
export const isRegistered = (user) => {
  if (!user || !user.isRegistered) return false;
  if (!user.data || !user.data.payout_option) return false;
  return true;
};

export const skipStripe = () => {
  return getValue(SkipStripe, true);
};

export const verifiedStripe = () => {
  return getValue(VerifiedStripeKey);
};

export const updateStripeVerification = (verified) => {
  if (verified) {
    storeValue(VerifiedStripeKey, true);
  } else {
    removeItem(VerifiedStripeKey);
  }
};

export const checkReferralAndAuthorize = () => {
  const referral = getValue(ReferralKey);
  if (referral) storeValue(SkipStripe, false);
};

export const completedRegistration = (user) => {
  return user && isRegistered(user) && (verifiedStripe() || skipStripe());
};
