// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._32n6Fp8wL0Y9IqiPn09wep{padding:50px 0 92px 0;text-align:center}._32n6Fp8wL0Y9IqiPn09wep .ZxCVQD8sG_WM5Uzg1luwJ>._2ujPA32lAVIJBtpmK0W7z1{color:#e23023;font-size:16px}._32n6Fp8wL0Y9IqiPn09wep .ZxCVQD8sG_WM5Uzg1luwJ>._3_FgpUomoEg_6gDrEqC9ZG{color:#333;font-size:40px;font-weight:bold}._32n6Fp8wL0Y9IqiPn09wep .ZxCVQD8sG_WM5Uzg1luwJ>._227RTK9vpgOCtl8NOTTDts{color:#959392;font-weight:normal;font-size:16px}._32n6Fp8wL0Y9IqiPn09wep .ZxCVQD8sG_WM5Uzg1luwJ ._2GlgktOtRTzmLdGIFZmNCd{margin-top:23px}@media only screen and (max-width: 955px){._32n6Fp8wL0Y9IqiPn09wep .ZxCVQD8sG_WM5Uzg1luwJ>._3_FgpUomoEg_6gDrEqC9ZG{font-size:32px}}@media only screen and (max-width: 320px){._32n6Fp8wL0Y9IqiPn09wep .ZxCVQD8sG_WM5Uzg1luwJ>._3_FgpUomoEg_6gDrEqC9ZG{font-size:24px}}._32n6Fp8wL0Y9IqiPn09wep ._1csF-Cs1jDpChP1aT1gkM9{max-width:900px;max-height:auto;margin:auto}._32n6Fp8wL0Y9IqiPn09wep ._1_o59KHKkBGNNiQh2SEUME{position:relative;overflow:hidden;width:100%;padding-top:56.25%}._32n6Fp8wL0Y9IqiPn09wep ._2H8jyPnh4Eia21QuIhzpxS{position:absolute;top:0;left:0;bottom:0;right:0;width:100%;height:100%}@media only screen and (max-width: 955px){._32n6Fp8wL0Y9IqiPn09wep{padding:26px 40px}._32n6Fp8wL0Y9IqiPn09wep .ZxCVQD8sG_WM5Uzg1luwJ ._3_FgpUomoEg_6gDrEqC9ZG,._32n6Fp8wL0Y9IqiPn09wep .ZxCVQD8sG_WM5Uzg1luwJ ._227RTK9vpgOCtl8NOTTDts{text-align:center}}", ""]);
// Exports
exports.locals = {
	"InfluencerVideo": "_32n6Fp8wL0Y9IqiPn09wep",
	"Subcontent": "ZxCVQD8sG_WM5Uzg1luwJ",
	"Overhead": "_2ujPA32lAVIJBtpmK0W7z1",
	"Title": "_3_FgpUomoEg_6gDrEqC9ZG",
	"Subtitle": "_227RTK9vpgOCtl8NOTTDts",
	"Button": "_2GlgktOtRTzmLdGIFZmNCd",
	"Box": "_1csF-Cs1jDpChP1aT1gkM9",
	"Container": "_1_o59KHKkBGNNiQh2SEUME",
	"ResponsiveIframe": "_2H8jyPnh4Eia21QuIhzpxS"
};
module.exports = exports;
