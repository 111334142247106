import client from '@api/client';

export const createReferral = () => {
  return client.post('/referrals');
};

export const sendReferral = (
  id,
  { consumerName, consumerEmail, consumerPhone },
  existingReferral
) => {
  return client.post(
    `/referrals/${id}/${existingReferral ? 'resend' : 'send'}`,
    {
      consumerName,
      consumerEmail,
      consumerPhone,
    }
  );
};

export const sendProfessionalCode = ({
  consumerName,
  consumerEmail,
  consumerPhone,
}) => {
  return client.post(`/referrals/send-professional-code`, {
    consumerName,
    consumerEmail,
    consumerPhone,
  });
};

export const addReferralItem = (id, itemId) => {
  return client.post(`/referrals/${id}/items`, {
    shopifyProductId: itemId,
  });
};

export const updateReferralItem = (referralId, itemId, params) => {
  return client.patch(`/referrals/${referralId}/items/${itemId}`, params);
};

export const removeReferralItem = (referralId, itemId) => {
  return client.delete(`/referrals/${referralId}/items/${itemId}`);
};
