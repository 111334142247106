//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import axios from 'axios';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { ResponseStatusCode, HeadersKey } from '@helpers/constants/api';
import { AuthorizationStorageKey } from '@helpers/constants/localStorage';
import { getValue } from '@helpers/localStorage';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { deauth } from '@helpers/auth';
//------------------------------------------------------------------------------
// Methods ---------------------------------------------------------------------
const client = axios.create({
  baseURL: window._env.REACT_APP_API_URL,
});

client.interceptors.request.use(
  (request) => {
    const authorizationToken = getValue(AuthorizationStorageKey);
    if (authorizationToken) {
      request.headers.common[
        HeadersKey.Authorization.request
      ] = authorizationToken;
    }
    request.headers.common[HeadersKey.StoreId] = window._env.REACT_APP_STORE_ID

    return request;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    const { response: { status } = {} } = error || {};
    if (
      status === ResponseStatusCode.Unauthorized ||
      status === ResponseStatusCode.Forbidden
    )
      return deauth();
    return Promise.reject(error);
  }
);

export default client;
