// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3LNexrah14pBH1WGI37KqD{display:flex;flex-direction:column;align-items:flex-start;width:100%}._3LNexrah14pBH1WGI37KqD ._39pz_R4VdZptKGAyH8lPZO{font-weight:bold;font-size:12px;margin-bottom:5px}._3LNexrah14pBH1WGI37KqD ._2UdUt99mT2_uMO0EVrfPV{border:1px solid rgba(224,224,224,.5);box-shadow:0px 2px 6px rgba(0,0,0,.06);border-radius:3px;align-self:stretch}._3LNexrah14pBH1WGI37KqD ._2UdUt99mT2_uMO0EVrfPV input{font-size:14px;flex-grow:2;color:inherit;width:100%;height:100%}._3LNexrah14pBH1WGI37KqD ._2UdUt99mT2_uMO0EVrfPV input:focus{outline:none;border:none}._3LNexrah14pBH1WGI37KqD ._2UdUt99mT2_uMO0EVrfPV input::placeholder{color:#d6d6d6;opacity:1}._3LNexrah14pBH1WGI37KqD ._2UdUt99mT2_uMO0EVrfPV input:-ms-input-placeholder{color:#d6d6d6}._3LNexrah14pBH1WGI37KqD ._2UdUt99mT2_uMO0EVrfPV input::-ms-input-placeholder{color:#d6d6d6}._3LNexrah14pBH1WGI37KqD.TMHQN0l5XdzaiOCizhHk5 ._39pz_R4VdZptKGAyH8lPZO{color:#e23023;transition:40ms linear color}._3LNexrah14pBH1WGI37KqD.TMHQN0l5XdzaiOCizhHk5 ._2UdUt99mT2_uMO0EVrfPV{border:1px solid #e23023;box-shadow:0px 4px 12px rgba(226,48,35,.12);transition:400ms linear border,400ms linear box-shadow}", ""]);
// Exports
exports.locals = {
	"Input": "_3LNexrah14pBH1WGI37KqD",
	"Title": "_39pz_R4VdZptKGAyH8lPZO",
	"InputField": "_2UdUt99mT2_uMO0EVrfPV",
	"Focus": "TMHQN0l5XdzaiOCizhHk5"
};
module.exports = exports;
