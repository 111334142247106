const invalidWords = ['colgate', 'admin'];

export const isProfileNameValid = (profileName) => {
  return profileName.length > 0 && profileName.length <= 50;
};

export const isUsernameValid = (username) => {
  if (username.length <= 3 || username.length > 15) return false;

  const lowerCasedUsername = username.toLowerCase();

  for (let i = 0; i < invalidWords.length; i++) {
    const invalidWord = invalidWords[i];
    if (lowerCasedUsername.indexOf(invalidWord) > -1) return false;
  }

  return isAlphanumeric(lowerCasedUsername);
};

export const isPasswordValid = (password) => {
  if (password.length < 10 || password.length > 100) return false;
  return true;
};

export const isAlphanumeric = (value) => {
  const regularExpression = /^[A-Za-z0-9]*$/g;
  return regularExpression.test(value);
};

export const isNameValid = (name) => {
  return name && name.length >= 1;
};
