//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import {
  Breadcrumbs,
  Slideshow,
  Button,
  ButtonStyle,
  AddItem as AddItemComponent,
} from '@cmp/common';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { ChevronUpFilled } from '@cmp/images';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import {
  Detail as ProductDetailRequest,
  Metafields as ProductMetafieldsRequest,
} from '@api/endpoints/get/products';
import { addItem } from '@helpers/referrals';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { shopifyFormatter } from '@helpers/formatter';
import { getTargetUrl } from '@helpers/dom';
import { CurrencyPrefix } from '@helpers/constants/currency';
import {
  MetafieldsKeys,
  MetafieldsReadableKeys,
} from '@helpers/constants/products';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import ExternalVideo from '@classes/externalVideo';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      expandedSections: [],
      productVideos: [],
    };

    this.additionalSectionComponent = this.additionalSectionComponent.bind(
      this
    );
  }

  componentDidMount() {
    const { history, match: { params: { id } = {} } = {} } = this.props;
    if (!id) return history.replace('/');

    this.setState({ id });
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { id } = {} } = {} } = this.props;
    const { id: currentId } = this.state;

    if (id !== currentId) {
      this.setState({ id }, this.refetch);
    }
  }

  pricingComponent(formattedProduct) {
    const hasDiscount = formattedProduct.hasDiscount();
    const fullPrice = formattedProduct.fullPrice(CurrencyPrefix.USD);
    const discountedPrice = formattedProduct.discountedPrice(
      CurrencyPrefix.USD
    );

    if (!hasDiscount && !fullPrice) return '';

    if (!hasDiscount) {
      return <span className={styles.Amount}>{fullPrice}</span>;
    }

    return (
      <>
        {formattedProduct.hasDiscount() && (
          <span className={styles.Amount}>
            {formattedProduct.discountedPrice(CurrencyPrefix.USD)}
          </span>
        )}
        {formattedProduct.fullPrice(CurrencyPrefix.USD) && (
          <span className={styles.OriginalPrice}>
            {formattedProduct.fullPrice(CurrencyPrefix.USD)}
          </span>
        )}
      </>
    );
  }

  productInformationComponent(product) {
    const { t } = this.props;
    const formattedProduct = shopifyFormatter.product(product);

    return (
      <div className={styles.Information}>
        <div className={styles.InformationHeader}>
          <h2 className={styles.Title}>{product.title}</h2>
          <span
            className={styles.Subtitle}
            dangerouslySetInnerHTML={{ __html: product.body_html }}
          ></span>
        </div>
        <div className={styles.Pricing}>
          <div className={styles.Value}>
            {this.pricingComponent(formattedProduct)}
          </div>
          <span className={styles.Disclosure}>
            {t('dashboard_ProductPriceDisclaimer')}
          </span>
        </div>
        <AddItemComponent product={product} className={styles.AddItem} />
        {product.variants &&
          product.variants?.filter((variant) =>
            variant?.metafields?.find((i) => i.key === 'color')
          ).length > 1 && (
            <div className={styles.Variants}>
              <h2 className={styles.VariantsTitle}>
                {t('dashboard_ProductHasVariants')}
              </h2>
              <div className={styles.VariantsList}>
                {product.variants.map((variant) => {
                  const titleArr = variant.title.split(' ');
                  const title = titleArr[titleArr.length - 1];
                  let color = '#425cc7';

                  switch (title) {
                    case 'Purple': {
                      color = '#6a4b97';
                      break;
                    }
                    case 'Teal': {
                      color = '#21a39c';
                      break;
                    }
                    case 'Black': {
                      color = '#2d2d2c';
                      break;
                    }
                    case 'Yellow': {
                      color = '#ede681';
                      break;
                    }
                    case 'Coral': {
                      color = '#e75650';
                      break;
                    }
                    case 'Blue':
                    default: {
                      color = '#425cc7';
                    }
                  }

                  return (
                    <div
                      className={styles.VariantsItem}
                      style={{
                        backgroundColor: color,
                      }}
                      key={variant.id}
                    />
                  );
                })}
              </div>
            </div>
          )}
      </div>
    );
  }

  setupVideos(product) {
    const formattedProduct = shopifyFormatter.product(product);

    // Parse all videos
    const allVideos = formattedProduct.parsedVideos();
    const productVideos = allVideos
      ? allVideos.map((x) => new ExternalVideo(x))
      : [];

    // Request their thumbnails if needed, and update state (so the slideshow
    // refreshes correctly)
    const thumbnailRequests = productVideos.map((x) =>
      x.loadThumbnailIfNeeded()
    );
    Promise.all(thumbnailRequests).then((res) =>
      this.setState({ productVideos })
    );

    this.setState({ productVideos });
  }

  detailsComponent(product) {
    const formattedProduct = shopifyFormatter.product(product);
    const { productVideos } = this.state;

    return (
      <div className={styles.Details}>
        <Slideshow
          videos={productVideos}
          images={formattedProduct.images()}
          className={styles.Slideshow}
          title={product.title}
        />
        {this.productInformationComponent(product)}
      </div>
    );
  }

  additionalComponent(product) {
    const formattedProduct = shopifyFormatter.product(product);
    const sortedMetafields = formattedProduct.metafields.sorted();

    const { id } = this.state;

    return (
      <div className={styles.Additional}>
        {sortedMetafields &&
          sortedMetafields.map(this.additionalSectionComponent)}
      </div>
    );
  }

  additionalSectionComponent({ id, key, value }) {
    if (!MetafieldsReadableKeys[key]) return '';

    const { expandedSections } = this.state;
    const isExpanded = expandedSections.indexOf(id) > -1;

    const componentClasses = classNames(styles.AdditionalSection, {
      [styles.Expanded]: isExpanded,
    });

    return (
      <div className={componentClasses} key={id}>
        <div
          className={styles.SectionHeader}
          onClick={() => this.toggleSection(id)}
          tabIndex="0"
          onKeyPress={() => this.toggleSection(id)}
        >
          <span className={styles.SectionTitle}>
            {MetafieldsReadableKeys[key]}
          </span>
          <ChevronUpFilled className={styles.Chevron} />
        </div>
        {isExpanded && (
          <div
            className={styles.Description}
            dangerouslySetInnerHTML={{ __html: value }}
            onClick={(e) => {
              e.preventDefault();
              const targetUrl = getTargetUrl(e.target);

              if (targetUrl) {
                window.open(targetUrl, '_blank');
              }
            }}
          ></div>
        )}
      </div>
    );
  }

  toggleSection(sectionId) {
    const { expandedSections } = this.state;

    const indexOf = expandedSections.indexOf(sectionId);
    const isExpanded = indexOf > -1;

    if (isExpanded) {
      expandedSections.splice(indexOf, 1);
    } else {
      expandedSections.push(sectionId);
    }

    this.setState({ expandedSections });
  }

  render() {
    const { t } = this.props;
    const { id } = this.state;
    const componentClasses = classNames(styles.Product);

    return (
      <ProductDetailRequest
        pathParams={{ id }}
        skip={!id}
        onComplete={({ product }) => this.setupVideos(product)}
      >
        {({ data, refetch }) => {
          const { product } = data || {};
          if (!product) return '';

          this.refetch = refetch;

          return (
            <div className={componentClasses}>
              <Helmet>
                <title>{product.title}</title>
              </Helmet>

              <Breadcrumbs
                pages={[
                  { title: t('dashboard_AllProducts'), url: '/products' },
                  { title: product.title, url: `/product/${product.id}` },
                ]}
                className={styles.Breadcrumbs}
              />
              {this.detailsComponent(product)}
              {this.additionalComponent(product)}
            </div>
          );
        }}
      </ProductDetailRequest>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Product);
