// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".gUIoJJdT9-tgkBQ7l9aWp{display:flex;flex-direction:column;align-items:center;text-align:center;padding:52px 75px;max-width:475px}.gUIoJJdT9-tgkBQ7l9aWp ._1-KaS6UeGa-9yRhdmIVC1m{font-weight:bold;font-size:24px;margin-bottom:11px}.gUIoJJdT9-tgkBQ7l9aWp ._8gVWuIlCM0O-nCAwg3zUo{font-size:14px;color:#595554;margin-bottom:18px}@media only screen and (max-width: 955px){.gUIoJJdT9-tgkBQ7l9aWp{padding:52px 30px}}@media only screen and (min-width: 768px){.gUIoJJdT9-tgkBQ7l9aWp{padding:52px 30px}}@media only screen and (max-width: 576px){.gUIoJJdT9-tgkBQ7l9aWp{padding:22px 15px;max-width:340px}.gUIoJJdT9-tgkBQ7l9aWp ._1-KaS6UeGa-9yRhdmIVC1m{font-size:22px;margin-bottom:10px;margin-top:15px}}@media only screen and (max-width: 320px){.gUIoJJdT9-tgkBQ7l9aWp{padding:20px 12px;max-width:260px}.gUIoJJdT9-tgkBQ7l9aWp ._1-KaS6UeGa-9yRhdmIVC1m{font-size:20px;margin-bottom:8px;margin-top:10px}}@media only screen and (max-height: 500px)and (orientation: landscape){.gUIoJJdT9-tgkBQ7l9aWp{align-items:center;padding:22px 15px;max-width:340px}.gUIoJJdT9-tgkBQ7l9aWp ._1-KaS6UeGa-9yRhdmIVC1m{font-size:22px;margin-bottom:10px;margin-top:15px}}", ""]);
// Exports
exports.locals = {
	"GenericModal": "gUIoJJdT9-tgkBQ7l9aWp",
	"Title": "_1-KaS6UeGa-9yRhdmIVC1m",
	"Subtitle": "_8gVWuIlCM0O-nCAwg3zUo"
};
module.exports = exports;
