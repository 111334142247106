//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import FocusTrap from 'focus-trap-react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { referralFormatter } from '@helpers/formatter';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Success extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.mountTrap = this.mountTrap.bind(this);
    this.unmountTrap = this.unmountTrap.bind(this);
  }

  componentDidMount() {
    this.mountTrap();
  }

  mountTrap() {
    this.setState({ activeTrap: true });
  }

  unmountTrap() {
    this.setState({ activeTrap: false });
  }

  closeModal = (path) => {
    // Hack: in order to properly update the recommendation history page,
    //       force a full refresh
    const {
      location: { state },
    } = this.props;

    const recommendationsPath = `/${path}`;
    window.location =
      recommendationsPath ||
      (state && state.from && state.from.pathname) ||
      '/';
  };

  render() {
    const {
      location: { state },
      t,
    } = this.props;
    const { user } = state || {};
    const componentClasses = classNames(styles.Success);

    const userName = user.name || t('dashboard_APatient');
    return (
      <FocusTrap>
        <div>
          <Modal.Generic
            title={t('dashboard_ProfessionalCodeSent')}
            subtitle={t('dashboard_SuccessfullySent', { name: userName })}
            onClose={() => this.closeModal('')}
          >
            <Helmet>
              <title>{t('dashboard_Success')}</title>
            </Helmet>
          </Modal.Generic>
        </div>
      </FocusTrap>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Success);
