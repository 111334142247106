//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { loadThumbnail } from '@api/endpoints/get/vimeo';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { vimeoFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
const VIDEO_SOURCE = {
  YouTube: {
    regex: ['youtu.be/(.*)', 'v=(.*)'],
    thumbnailRegex: (id) => `https://img.youtube.com/vi/${id}/0.jpg`,
    embedCode: (id) =>
      `<iframe width="640" height="360" src="https://www.youtube.com/embed/${id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  Vimeo: {
    regex: ['vimeo.com/(.*)'],
    embedCode: (id) =>
      `<iframe src="https://player.vimeo.com/video/${id}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
  },
};
const ALL_VIDEO_SOURCES = [VIDEO_SOURCE.YouTube, VIDEO_SOURCE.Vimeo];
//------------------------------------------------------------------------------
// Class -----------------------------------------------------------------------
export default class ExternalVideo {
  constructor(videoUrl) {
    this.videoUrl = videoUrl;
    this.setupData();
  }

  setupData() {
    const foundSource = this.findSource();
    if (!foundSource) return;

    this.videoSource = foundSource.source;
    this.videoId = foundSource.videoId;
  }

  findSource() {
    for (let i = 0; i < ALL_VIDEO_SOURCES.length; i++) {
      const videoSource = ALL_VIDEO_SOURCES[i];

      for (let j = 0; j < videoSource.regex.length; j++) {
        let result = new RegExp(videoSource.regex[j]).exec(this.videoUrl);
        if (!result) continue;

        return {
          videoId: result[result.length - 1],
          source: videoSource,
        };
      }
    }
  }

  isValid() {
    return this.videoSource;
  }

  id() {
    return this.videoId;
  }

  thumbnailUrl() {
    if (this.customThumbnailUrl) return this.customThumbnailUrl;
    if (!this.videoSource || !this.videoSource.thumbnailRegex) return '';
    return this.videoSource.thumbnailRegex(this.videoId);
  }

  embedCode() {
    if (!this.videoSource) return '';
    return this.videoSource.embedCode(this.videoId);
  }

  loadThumbnailIfNeeded() {
    if (
      this.customThumbnailUrl ||
      !this.videoId ||
      this.videoSource.thumbnailRegex
    )
      return;

    return loadThumbnail(this.videoId).then(({ data }) => {
      this.customThumbnailUrl = vimeoFormatter(data).idealThumbnail();
      return Promise.resolve(this.customThumbnailUrl);
    });
  }
}
