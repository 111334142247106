// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._11NAMlaLu05Bl_sLstWBFg{display:flex;justify-content:center;align-items:flex-start;background-color:#fafafb;height:100%;padding-top:100px;overflow:scroll}._11NAMlaLu05Bl_sLstWBFg ._3amdnbgNp3epCW9hb5zjDK{max-width:716px}._11NAMlaLu05Bl_sLstWBFg ._3amdnbgNp3epCW9hb5zjDK ._3hIzrFoJBg8FcGi5meMf-c{font-size:14px;color:#8e8e8e;font-weight:bold}@media only screen and (max-width: 955px){._11NAMlaLu05Bl_sLstWBFg ._3amdnbgNp3epCW9hb5zjDK{width:90%}}", ""]);
// Exports
exports.locals = {
	"RegistrationComplete": "_11NAMlaLu05Bl_sLstWBFg",
	"Form": "_3amdnbgNp3epCW9hb5zjDK",
	"Text": "_3hIzrFoJBg8FcGi5meMf-c"
};
module.exports = exports;
