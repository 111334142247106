//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Form, Header, Button, ButtonStyle, Checkbox } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { withAuthUser } from '@helpers/auth';
import { ScreenSet, showScreenSet } from '@helpers/gigya';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { startConnect } from '@api/endpoints/post/payments';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RegistrationComplete extends React.Component {
  componentDidMount() {
    const { __authUser } = this.props;
    showScreenSet(ScreenSet.SignUp, 'signup-container', {
      regToken: __authUser && __authUser.regToken,
    });
  }

  render() {
    const { history, t } = this.props;
    const componentClasses = classNames(styles.RegistrationComplete);

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('landing_CompleteYourRegistration')}</title>
        </Helmet>

        <Header />
        <Form
          className={styles.Form}
          title={t('landing_YourInformation')}
          id="signup-container"
        />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withTranslation()(RegistrationComplete));
