//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { FaSpinner } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class StateIndicator extends React.Component {
  render() {
    const { className, loading, error, t } = this.props;
    const componentClasses = classNames(styles.StateIndicator, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {loading && <FaSpinner className={styles.LoadingIndicator} />}
        {error && (
          <div className={styles.ErrorContainer}>
            <MdErrorOutline className={styles.Icon} />
            <span className={styles.Message}>{t('GenericErrorMessage')}</span>
          </div>
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(StateIndicator);
