import React, { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import styles from './index.scss';

export default function QrCode({
  url,
  showDownload = true,
  height = '100px',
  onLoaded,
}) {
  const [data, setData] = useState(null);
  if (window._env.REACT_APP_QR_CODE_ENABLED !== 'true') return null;
  const imageSource = `${window._env.REACT_APP_QR_CODE_BASE_URL}${window._env.REACT_APP_QR_CODE_KEY}&backColor=${window._env.REACT_APP_QR_CODE_BG_COLOR}&qrColor=${window._env.REACT_APP_QR_CODE_QR_COLOR}&size=${window._env.REACT_APP_QR_CODE_SIZE}&logo=${window._env.REACT_APP_QR_CODE_LOGO_URL}&data=${url}`;

  useEffect(() => {
    const canvas = document.createElement('CANVAS');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      onLoaded();
      setData(dataURL);
      canvas = null;
    };
    img.src = imageSource;
  }, [imageSource]);

  return (
    <>
      {!data ? (
        <div
          style={{
            height,
          }}
          className={styles.loadingWrapper}
        >
          <FaSpinner className={styles.spin} />
        </div>
      ) : null}
      <img
        style={!data ? { opacity: 0, height: 0 } : { marginBottom: 0 }}
        src={data}
      />
      <div>
        {data && showDownload ? (
          <a
            className={styles.downloadButton}
            href={data}
            download="qrcode.png"
          >
            Download
          </a>
        ) : null}
      </div>
    </>
  );
}
