// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Eqz8vSbLq7Tlq1oKNFXXV{display:flex;align-items:stretch;background-color:#fafafb;min-height:470px;max-height:470px}.Eqz8vSbLq7Tlq1oKNFXXV ._7HfuzNNpytm1mbbJslhzp>._1jPskcgLCjE8K6R4bA9ESh{color:#e23023;font-size:16px}.Eqz8vSbLq7Tlq1oKNFXXV ._7HfuzNNpytm1mbbJslhzp>._2hng89LcjKL_a970l4YEgF{color:#333;font-size:40px;font-weight:bold}.Eqz8vSbLq7Tlq1oKNFXXV ._7HfuzNNpytm1mbbJslhzp>._2cQps2xpeJ-kOYlqY20aPV{color:#959392;font-weight:normal;font-size:16px}.Eqz8vSbLq7Tlq1oKNFXXV ._7HfuzNNpytm1mbbJslhzp ._2oJ2tZB3obj1lLYy61wFbY{margin-top:23px}@media only screen and (max-width: 955px){.Eqz8vSbLq7Tlq1oKNFXXV ._7HfuzNNpytm1mbbJslhzp>._2hng89LcjKL_a970l4YEgF{font-size:32px}}@media only screen and (max-width: 320px){.Eqz8vSbLq7Tlq1oKNFXXV ._7HfuzNNpytm1mbbJslhzp>._2hng89LcjKL_a970l4YEgF{font-size:24px}}.Eqz8vSbLq7Tlq1oKNFXXV ._19QV7R-YoEsE4lPRqwZFPC{flex-shrink:0;flex-basis:45%;background-size:cover;background-repeat:no-repeat;background-position:center}.Eqz8vSbLq7Tlq1oKNFXXV ._7HfuzNNpytm1mbbJslhzp{display:flex;flex-direction:column;align-items:flex-start;justify-content:center;font-weight:bold;padding:0 100px}.Eqz8vSbLq7Tlq1oKNFXXV ._7HfuzNNpytm1mbbJslhzp>*{margin-bottom:12px}.Eqz8vSbLq7Tlq1oKNFXXV ._7HfuzNNpytm1mbbJslhzp>*:last-child{margin-bottom:0}@media only screen and (max-width: 992px){.Eqz8vSbLq7Tlq1oKNFXXV ._7HfuzNNpytm1mbbJslhzp{padding:0 50px}}@media only screen and (max-width: 955px){.Eqz8vSbLq7Tlq1oKNFXXV{flex-direction:column;min-height:0;max-height:none}.Eqz8vSbLq7Tlq1oKNFXXV ._19QV7R-YoEsE4lPRqwZFPC{min-height:240px;max-height:240px}.Eqz8vSbLq7Tlq1oKNFXXV ._7HfuzNNpytm1mbbJslhzp{padding:39px 32px}}", ""]);
// Exports
exports.locals = {
	"PatientSatisfaction": "Eqz8vSbLq7Tlq1oKNFXXV",
	"Subcontent": "_7HfuzNNpytm1mbbJslhzp",
	"Overhead": "_1jPskcgLCjE8K6R4bA9ESh",
	"Title": "_2hng89LcjKL_a970l4YEgF",
	"Subtitle": "_2cQps2xpeJ-kOYlqY20aPV",
	"Button": "_2oJ2tZB3obj1lLYy61wFbY",
	"SectionImage": "_19QV7R-YoEsE4lPRqwZFPC"
};
module.exports = exports;
