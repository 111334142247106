//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button } from '@cmp/common';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { IconContext } from 'react-icons';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Radio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.selectedOption,
    };

    this.disabledAltText = this.props.disabledAltText;
    this.disabledOptions = this.props.disabledOptions;
    this.detailsOptions = this.props.detailsOptions;
    this.selectionCallback = this.props.selectionCallback;
    this.detailsCallback = this.props.detailsCallback;
    this.selectedOption = this.props.selectedOption;
    this.helpLinkURL = this.props.helpLinkURL;
    this.helpLinkName = this.props.helpLinkName;
    this.optionComponent = this.optionComponent.bind(this);
  }

  optionComponent(option, index) {
    const { selected } = this.state;
    const wasSetAsSelected = this.selectedOption === index;
    const isDisabled = this.disabledOptions
      ? this.disabledOptions.includes(index) && !wasSetAsSelected
      : false;

    const componentClasses = classNames(styles.Option, {
      [styles.Selected]: selected === index,
    });

    return (
      <div className={componentClasses} key={index}>
        {isDisabled ? (
          <div className={styles.RadioInputDisabled}></div>
        ) : (
          <div
            className={styles.RadioInput}
            onClick={
              isDisabled
                ? null
                : () => {
                    this.setState({ selected: index });

                    if (this.selectionCallback) {
                      this.selectionCallback(index);
                    }
                  }
            }
          >
            {selected === index && (
              <div className={styles.RadioInputSelected}></div>
            )}
          </div>
        )}

        <span
          className={isDisabled ? styles.TextDisabled : styles.Text}
          onClick={
            isDisabled
              ? null
              : () => {
                  this.setState({ selected: index });

                  if (this.selectionCallback) {
                    this.selectionCallback(index);
                  }
                }
          }
        >
          {option}
        </span>

        {this.detailsOptions[index] ? (
          <IconContext.Provider value={{ color: 'white' }}>
            <Button
              iconComponent={<AiOutlineInfoCircle />}
              className={styles.Button}
              onClick={() => {
                if (this.detailsCallback) {
                  this.detailsCallback(index);
                }
              }}
            ></Button>
          </IconContext.Provider>
        ) : null}
      </div>
    );
  }

  render() {
    const { title, options } = this.props;
    const componentClasses = classNames(styles.Radio);

    return (
      <div className={componentClasses}>
        <span className={styles.Title}>
          {title}{' '}
          {this.helpLinkURL ? (
            <a
              href={this.helpLinkURL}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.Link}
            >
              {this.helpLinkName}
            </a>
          ) : null}
        </span>
        <div className={styles.Options}>
          {options.map(this.optionComponent)}
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Radio;
