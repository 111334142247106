export default class FormValidator {
  constructor(items, exclusive) {
    this.items = items;
    this.exclusive = exclusive;
  }

  validateAll(data) {
    if (!this.items || this.items.length <= 0) return true;

    for (let i = 0; i < this.items.length; i++) {
      let validationItem = this.items[i];
      let dataItem = data[validationItem.key];

      const valid = this.validateItem(validationItem, dataItem, data);

      if (valid !== validationItem.validWhen) {
        return {
          isValid: false,
          error: validationItem.error,
        };
      }
    }

    return { isValid: true };
  }

  validateItem(validationItem, dataItem, data) {
    const valid = validationItem.method(
      dataItem || validationItem.defaultValue,
      data
    );

    return valid;
  }
}
