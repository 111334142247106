// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3xgUq29LomM6fy8biQlfvf{color:#e23023}", ""]);
// Exports
exports.locals = {
	"link": "_3xgUq29LomM6fy8biQlfvf"
};
module.exports = exports;
