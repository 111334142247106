export const ResponseStatusCode = {
  Unauthorized: 401,
  Forbidden: 403,
  Success: 200,
};

export const HeadersKey = {
  Authorization: {
    response: 'set-authorization',
    request: 'Authorization',
  },
  StoreId: 'store-id',
};

export const GenericErrorMessage = 'Something went wrong. Please try again.';
