// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-moz-keyframes _43hHaLzkAzM8ib2pfpMRQ{100%{-moz-transform:rotate(360deg)}}@-webkit-keyframes _43hHaLzkAzM8ib2pfpMRQ{100%{-webkit-transform:rotate(360deg)}}@keyframes _43hHaLzkAzM8ib2pfpMRQ{100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}._2TweMe8h-vh4vukLKqpwOp{display:flex;justify-content:center;align-items:flex-start;background-color:#fafafb;height:100%;overflow:scroll}._2TweMe8h-vh4vukLKqpwOp .SaWvbpJs1hAOEq9tAZaP9{margin-top:100px}._2TweMe8h-vh4vukLKqpwOp .SaWvbpJs1hAOEq9tAZaP9 ._3-wTkR5AXLiIliyT1RcARj ._1Blx2lUrQ2LOFuOsej7KK7{animation:_43hHaLzkAzM8ib2pfpMRQ 1500ms linear infinite}._2TweMe8h-vh4vukLKqpwOp .SaWvbpJs1hAOEq9tAZaP9 ._3-wTkR5AXLiIliyT1RcARj ._1TBsDWTp_NPw2wXVW-7VGQ{font-weight:bold;font-size:14px;color:#e23023;margin-top:12px}", ""]);
// Exports
exports.locals = {
	"Payment": "_2TweMe8h-vh4vukLKqpwOp",
	"Content": "SaWvbpJs1hAOEq9tAZaP9",
	"Form": "_3-wTkR5AXLiIliyT1RcARj",
	"Spinner": "_1Blx2lUrQ2LOFuOsej7KK7",
	"spin": "_43hHaLzkAzM8ib2pfpMRQ",
	"GoBack": "_1TBsDWTp_NPw2wXVW-7VGQ"
};
module.exports = exports;
