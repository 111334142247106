//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { completedRegistration, verifiedStripe } from '@helpers/account';
import { withAuthUser, deauth } from '@helpers/auth';
import { DoNotSellMyPersonalDataURL } from '@helpers/constants/colgate';
import { Providers as ProvidersRequest } from '@api/endpoints/get/providers';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import {
  dashboardIconUrl,
  shoppingCartIconUrl,
  patientDirectoryIconUrl,
} from '@cmp/images';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
const MenuSections = [
  {
    className: styles.MainSection,
    items: [
      {
        i18nTitleKey: 'header_RecommendedProducts',
        path: '/',
        icon: (i18n) => shoppingCartIconUrl(i18n),
        requiresCompleteRegistration: true,
      },
      {
        i18nTitleKey: 'header_Dashboard',
        path: '/recommendations',
        icon: (i18n) => dashboardIconUrl(i18n),
        requiresCompleteRegistration: true,
      },
      {
        i18nTitleKey: 'header_PatientDirectory',
        path: '/patient-directory',
        icon: (i18n) => patientDirectoryIconUrl(i18n),
        requiresCompleteRegistration: true,
      },
    ],
  },
  {
    items: [
      {
        i18nTitleKey: 'header_MyAccount',
        path: '/myaccount',
      },
      {
        i18nTitleKey: 'header_PatientLogin',
        onClick: () =>
          (window.location.href = window._env.REACT_APP_SHOPIFY_PUBLIC_URL),
      },
      {
        i18nTitleKey: 'header_RequestPatientMaterials',
        onClick: () => window.open('https://forms.gle/4G4kR4x85cfZMZCSA'),
      },
      { i18nTitleKey: 'header_Help', path: '/help' },
      { i18nTitleKey: 'header_About', path: '/about' },
      {
        i18nTitleKey: 'header_Logout',
        onClick: () => deauth(),
        requireAuthentication: true,
      },
    ],
  },
  {
    items: [
      {
        i18nTitleKey: 'header_DoNotSellPersonalData',
        externalUrl: DoNotSellMyPersonalDataURL,
      },
    ],
  },
];
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.sectionComponent = this.sectionComponent.bind(this);
  }

  sectionComponent(section, index) {
    const { onClose, __authUser, t, i18n } = this.props;

    const sectionClasses = classNames(styles.Section, {
      [section.className]: section.className,
    });

    return (
      <div className={sectionClasses} key={index}>
        {section.items.map((x) => {
          if (x.requireAuthentication && !__authUser) return '';
          if (
            x.requiresCompleteRegistration &&
            !completedRegistration(__authUser)
          )
            return '';

          if (x.path) {
            return (
              <Link
                to={x.path}
                className={styles.Item}
                onClick={onClose}
                key={x.i18nTitleKey}
              >
                {x.icon && <img className={styles.Icon} src={x.icon(i18n)} />}
                {t(x.i18nTitleKey)}
              </Link>
            );
          }

          if (x.onClick) {
            return (
              <span
                className={styles.Item}
                onClick={x.onClick}
                key={x.i18nTitleKey}
              >
                {t(x.i18nTitleKey)}
              </span>
            );
          }

          if (x.externalUrl) {
            return (
              <a
                rel="noopener noreferrer"
                className={styles.Item}
                href={x.externalUrl}
                target="_blank"
                key={x.i18nTitleKey}
              >
                {t(x.i18nTitleKey)}
              </a>
            );
          }

          return '';
        })}
      </div>
    );
  }

  contentComponent() {
    const { __authUser, location, onClose, t } = this.props;
    return (
      <div className={styles.Content}>
        <div className={styles.Sections}>
          {MenuSections.map(this.sectionComponent)}
          {__authUser && completedRegistration(__authUser) && verifiedStripe() && (
            <ProvidersRequest>
              {({ data, refetch }) => {
                this.refetch = refetch;

                return (
                  <Link
                    to={{
                      pathname: '/recommendation/professional-code',
                      state: { from: location, modal: true },
                    }}
                    onClick={onClose}
                    className={styles.MenuOption}
                  >
                    {t('header_ShareYourCode', {
                      code: data ? data.referralCode : '',
                    })}
                  </Link>
                );
              }}
            </ProvidersRequest>
          )}
        </div>
        <div className={styles.Footer}></div>
      </div>
    );
  }

  render() {
    const { isVisible, onClose, className } = this.props;

    const componentClasses = classNames(styles.Menu, {
      [className]: className,
      [styles.Visible]: isVisible,
    });

    return (
      <div className={componentClasses}>
        <div className={styles.Overlay} onClick={() => onClose()}></div>
        {this.contentComponent()}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withTranslation()(Menu));
