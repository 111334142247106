//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Button, ButtonStyle } from '@cmp/common';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { ProductsList } from './components';
import { SearchInput, Paginate, ViewRecommendationsButton } from '@cmp/common';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import {
  Products as ProductsRequest,
  FeaturedCollections as FeaturedCollectionsRequest,
} from '@api/endpoints/get/products';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { dashboardIconUrl, searchIconUrl } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { shopifyFormatter, responseFormatter } from '@helpers/formatter';
import { ProductsSortingOptions } from '@helpers/constants/products';
import { getValue } from '@helpers/localStorage';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import {
  UserStorageKey,
  VerifiedStripeKey,
  refCode,
} from '@helpers/constants/localStorage';
import { withTranslation } from 'react-i18next';
import { StoreConfigContext } from '@helpers/storeConfigContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue:
        new URLSearchParams(window.location.search).get('search') || '',
      sortBy: ProductsSortingOptions.find((x) => x.default),
      selectedCollectionId: '',
      paginatedUrl: null,
    };
    this.tagComponent = this.tagComponent.bind(this);
    this.listRef = React.createRef();
    this.itemRef = React.createRef();
  }

  componentDidUpdate() {
    const listNode = this.listRef.current;
    const itemNode = this.itemRef.current;
    if (listNode && itemNode) {
      listNode.scrollLeft =
        itemNode.offsetLeft -
        listNode.clientWidth / 2 +
        itemNode.clientWidth / 2;
    }
  }

  providerCodeComponent() {
    const user = getValue(UserStorageKey, true);
    const payoutOption = user.data.payout_option;
    const hasStripe = getValue(VerifiedStripeKey, true);
    const referralCode = getValue(refCode);
    const profCodeEnabled = hasStripe || payoutOption === 'donate';
    const { location, history, t } = this.props;
    return (
      <div>
        {profCodeEnabled && (
          <Link
            to={{
              pathname: '/recommendation/professional-code',
              state: { from: location, modal: true },
            }}
          >
            <span className={classNames(styles.LinkTitle, styles.btnShareCode)}>
              {t('header_ShareYourCode', { code: referralCode })}
            </span>
          </Link>
        )}
      </div>
    );
  }

  headerComponent() {
    const { history, t, i18n } = this.props;
    return (
      <header className={styles.Header}>
        <h1 className={styles.Title}>{t('dashboard_Headline')}</h1>
        <span
          className={classNames(
            styles.Subtitle,
            styles.DashboardSubtitle,
            styles.shareCode
          )}
        >
          {this.providerCodeComponent()}
        </span>

        <span className={classNames(styles.Subtitle, styles.ProductsSubtitle)}>
          <div dangerouslySetInnerHTML={{ __html: t('dashboard_SubCopy') }} />
        </span>
      </header>
    );
  }

  tagComponent(collection) {
    const { selectedCollectionId } = this.state;
    const isSelected = selectedCollectionId === collection.id;

    const componentClasses = classNames(styles.Tag, {
      [styles.Selected]: isSelected,
    });

    return (
      <span
        ref={isSelected ? this.itemRef : null}
        className={componentClasses}
        key={collection.id}
        tabIndex="0"
        onKeyPress={() => {
          this.setState(
            {
              paginatedUrl: null,
              selectedCollectionId: isSelected ? null : collection.id,
            },
            this.refetch
          );
        }}
        onClick={() => {
          this.setState(
            {
              paginatedUrl: null,
              selectedCollectionId: isSelected ? null : collection.id,
            },
            this.refetch
          );
        }}
      >
        {collection.title}
      </span>
    );
  }

  collectionsComponent() {
    const { selectedCollectionId } = this.state;

    return (
      <FeaturedCollectionsRequest stateIndicatorComponent={null}>
        {({ data }) => {
          if (!data) return '';

          let { custom_collections } = data;
          const topList = {
            'smart-and-manual-toothbrushes': 'Toothbrushes',
            toothpaste: null,
            mouthwash: null,
            kids: null,
            'best-sellers': 'Shop All',
          };
          let bottomList = {
            whitening: null,
            'gum-and-sensitivity': 'Sensitivity/Caries',
            ortho: 'Gum Care/Ortho',
          };

          const mapHandleToCollection = ([handle, title]) => {
            const collection = custom_collections.find(
              (collection) => collection.handle === handle
            );
            if (collection) {
              if (title) collection.title = title;
              return collection;
            } else {
              return null;
            }
          };

          const topCollections = Object.entries(topList)
            .map(mapHandleToCollection)
            .filter((collection) => !!collection);
          const bottomCollections = Object.entries(bottomList)
            .map(mapHandleToCollection)
            .filter((collection) => !!collection);

          return (
            <div ref={this.listRef} className={styles.TagsContainer}>
              <div className={styles.Tags}>
                {topCollections && topCollections.map(this.tagComponent)}
              </div>
              <div className={styles.Tags}>
                {bottomCollections && bottomCollections.map(this.tagComponent)}
              </div>
            </div>
          );
        }}
      </FeaturedCollectionsRequest>
    );
  }

  listHeaderComponent() {
    const { t, i18n } = this.props;
    const { searchValue } = this.state;

    return (
      <div className={styles.ListHeader}>
        {this.collectionsComponent()}
        <div className={styles.Filter}>
          <SearchInput
            className={styles.SearchInput}
            value={searchValue}
            placeholder={t('dashboard_SearchFor')}
            icon={searchIconUrl(i18n)}
            onChange={(searchValue) => {
              this.setState(
                { searchValue, paginatedUrl: null, selectedCollectionId: '' },
                () => {
                  history.pushState(
                    searchValue,
                    t('dashboard_Products'),
                    `?search=${searchValue}`
                  );
                  if (this.searchTimeout) clearTimeout(this.searchTimeout);
                  this.searchTimeout = setTimeout(
                    this.refetch,
                    searchValue.length > 0 ? 600 : 0
                  );
                }
              );
            }}
          />
        </div>
      </div>
    );
  }

  listComponent() {
    const {
      sortBy,
      searchValue,
      selectedCollectionId,
      paginatedUrl,
    } = this.state;

    return (
      <div className={styles.List}>
        {this.listHeaderComponent()}
        <ProductsRequest
          params={
            !paginatedUrl && {
              title: searchValue,
              sort: sortBy.value,
              collection_id: selectedCollectionId,
              limit: 50,
            }
          }
          customUrl={paginatedUrl}
        >
          {({ data, headers, refetch }) => {
            this.refetch = refetch;

            const { products, collects } = data || {};
            const paginationUrls = responseFormatter
              .headers(headers)
              .pagination();

            if (products) {
              products.sort(
                (a, b) => collects.indexOf(a.id) - collects.indexOf(b.id)
              );
            }

            return (
              <>
                <ProductsList
                  products={products}
                  className={styles.ProductsList}
                  onAdd={() => undefined}
                />
                <Paginate
                  className={styles.Paginate}
                  onNext={
                    paginationUrls.nextUrl &&
                    (() =>
                      this.setState(
                        { paginatedUrl: paginationUrls.nextUrl },
                        refetch
                      ))
                  }
                  onPrevious={
                    paginationUrls.previousUrl &&
                    (() =>
                      this.setState(
                        { paginatedUrl: paginationUrls.previousUrl },
                        refetch
                      ))
                  }
                />
              </>
            );
          }}
        </ProductsRequest>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const componentClasses = classNames(styles.Products);

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('dashboard_Products')}</title>
        </Helmet>

        {this.headerComponent()}
        {this.listComponent()}
        <ViewRecommendationsButton className={styles.Recommendations} />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Products);

Products.contextType = StoreConfigContext;
