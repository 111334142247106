//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Badge } from '@cmp/common';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class BasicInfo extends React.Component {
  render() {
    const { title, subtitleComponent, status, className } = this.props;
    const componentClasses = classNames(styles.BasicInfo, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <h2 className={styles.Title}>{title}</h2>
        {subtitleComponent}
        {status && <Badge status={status} className={styles.Badge} />}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default BasicInfo;
