//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import { Auth, Payment } from './client';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Callback extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Auth} />
        <Route exact path="/callback/payment" component={Payment} />
        <Redirect from="/callback/*" to="/" />
      </Switch>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Callback;
