//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import {
  createReferral as createReferralRequest,
  sendReferral as sendReferralRequest,
  sendProfessionalCode as sendProfessionalCodeRequest,
  addReferralItem as addReferralItemRequest,
  updateReferralItem as updateReferralItemRequest,
  removeReferralItem as removeReferralItemRequest,
} from '@api/endpoints/post/referrals';
import { loadReferral } from '@api/endpoints/get/referrals';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { storeValue, removeAll, getValue } from '@helpers/localStorage';
import { shopifyFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { ReferralKey } from '@helpers/constants/localStorage';
import { ResponseStatusCode } from '@helpers/constants/api';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import Observable from '@classes/observable';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
const storageObservable = new Observable();

export const withReferral = (WrappedComponent) => {
  return class WithReferral extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        referral: null,
      };
      this.referralChanged = this.referralChanged.bind(this);
    }

    referralChanged(referral) {
      this.setState({ referral });
    }

    componentDidMount() {
      this.setState({
        referral: getValue(ReferralKey, true),
      });

      storageObservable.subscribe(this.referralChanged);
    }

    componentWillUnmount() {
      storageObservable.unsubscribe(this.referralChanged);
    }

    render() {
      const { referral } = this.state;

      return (
        <WrappedComponent {...this.props} __referral={referral}>
          {this.children}
        </WrappedComponent>
      );
    }
  };
};

export const addItem = (item) => {
  let referral = currentReferral();

  function addReferralItem(referral) {
    return new Promise((resolve, reject) => {
      addReferralItemRequest(referral.id, item.id)
        .then(() => loadReferralAndStore().then(resolve).catch(reject))
        .catch(reject);
    });
  }

  if (referral) {
    // If there's a referral stored locally, use it and add the item
    return addReferralItem(referral);
  } else {
    return new Promise((resolve, reject) => {
      // If there isn't a local referral, create it
      createReferralRequest()
        .then(({ status, data }) => {
          // As a sanity check, verify the status and reject the promise if needed
          if (status !== ResponseStatusCode.Success) return Promise.reject();

          // Store the referral
          storeReferral(data);

          // Add the item to it, and resolve or reject addItem
          addReferralItem(data).then(resolve).catch(reject);
        })
        .catch((err) => Promise.reject(err));
    });
  }
};

export const updateItem = (item, quantity) => {
  const referral = currentReferral();
  if (!referral) return Promise.reject();

  return updateReferralItemRequest(referral.id, item.id, {
    quantityRecommended: quantity,
  })
    .then(() => loadReferralAndStore())
    .catch((err) => Promise.reject(err));
};

export const removeItem = (item) => {
  const referral = currentReferral();
  if (!referral) return Promise.reject();

  return removeReferralItemRequest(referral.id, item.id).finally(() =>
    loadReferralAndStore()
  );
};

export const sendReferral = (
  { name: consumerName, email: consumerEmail, phoneNumber: consumerPhone },
  existingReferral
) => {
  const referral = existingReferral || currentReferral();
  if (!referral || !referral.id) return Promise.reject();

  return sendReferralRequest(
    referral.id,
    {
      consumerName,
      consumerEmail,
      consumerPhone,
    },
    existingReferral
  );
};

export const sendProfessionalCode = ({
  name: consumerName,
  email: consumerEmail,
  phoneNumber: consumerPhone,
}) => {
  return sendProfessionalCodeRequest({
    consumerName,
    consumerEmail,
    consumerPhone,
  });
};

export const loadReferralAndStore = () => {
  const referral = currentReferral();
  if (!referral) return Promise.reject();

  return loadReferral(referral.id)
    .then(({ data }) => {
      storeReferral(data);
      return Promise.resolve(data);
    })
    .catch((err) => Promise.reject(err));
};

export const currentReferral = () => {
  return getValue(ReferralKey, true);
};

const storeReferral = (referral) => {
  storeValue(ReferralKey, JSON.stringify(referral));
  storageObservable.notify(referral);
};

export const clearReferral = () => {
  storeValue(ReferralKey, null);
  storageObservable.notify();
};
