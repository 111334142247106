//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Detail, Review } from './components/client';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Recommendation extends React.Component {
  render() {
    const { isModal } = this.props;
    const componentClasses = classNames(styles.Recommendation);

    return (
      <div className={componentClasses}>
        <Switch>
          {isModal && (
            <Route exact path="/recommendation/review" component={Review} />
          )}
          {isModal && (
            <Route exact path="/recommendation/:id" component={Detail} />
          )}
        </Switch>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Recommendation;
