// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1i0Psv-IYIMqKaAVTwYBOS{display:flex;flex-direction:column;margin-top:25px}._1i0Psv-IYIMqKaAVTwYBOS>*{margin-bottom:23px}._1i0Psv-IYIMqKaAVTwYBOS>*:last-child{margin-bottom:0}._1i0Psv-IYIMqKaAVTwYBOS ._2Lydipo3SZTY_UaLZPc761{display:flex;flex-direction:column}._1i0Psv-IYIMqKaAVTwYBOS ._2Lydipo3SZTY_UaLZPc761>*{margin-bottom:11px}._1i0Psv-IYIMqKaAVTwYBOS ._2Lydipo3SZTY_UaLZPc761>*:last-child{margin-bottom:0}._1i0Psv-IYIMqKaAVTwYBOS ._2Lydipo3SZTY_UaLZPc761 ._1MwApGtHITLtUqJ_A9xUZ0{display:flex;justify-content:space-between}._1i0Psv-IYIMqKaAVTwYBOS ._2Lydipo3SZTY_UaLZPc761 ._1MwApGtHITLtUqJ_A9xUZ0 ._1JMICI382yThlI2kbsjUCB{font-size:14px;color:#595554}._1i0Psv-IYIMqKaAVTwYBOS ._2Lydipo3SZTY_UaLZPc761 ._1MwApGtHITLtUqJ_A9xUZ0 ._3K9sjAg3mtGy1JGr1_jCLF{font-weight:bold;font-size:16px}._1i0Psv-IYIMqKaAVTwYBOS ._23fcqexPbdQV6RWuaCfNTH{border:1px solid #959392}._1i0Psv-IYIMqKaAVTwYBOS ._23fcqexPbdQV6RWuaCfNTH:focus{border:1px solid #e23023}", ""]);
// Exports
exports.locals = {
	"CheckoutDetails": "_1i0Psv-IYIMqKaAVTwYBOS",
	"Values": "_2Lydipo3SZTY_UaLZPc761",
	"Row": "_1MwApGtHITLtUqJ_A9xUZ0",
	"Title": "_1JMICI382yThlI2kbsjUCB",
	"Value": "_3K9sjAg3mtGy1JGr1_jCLF",
	"OptionalNote": "_23fcqexPbdQV6RWuaCfNTH"
};
module.exports = exports;
