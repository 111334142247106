//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Stripe extends React.Component {
  render() {
    const { className, color = '#FFFFFF' } = this.props;
    const componentClasses = classNames(styles.Stripe, {
      [className]: className,
    });

    return (
      <svg
        className={componentClasses}
        width="64px"
        height="91px"
        viewBox="0 0 64 91"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Stripe-logo---white" fill={color}>
            <path
              d="M25.3,27.1 C25.3,23.2 28.5,21.7 33.8,21.7 C41.4,21.7 51,24 58.6,28.1 L58.6,4.6 C50.3,1.3 42.1,7.10542736e-15 33.8,7.10542736e-15 C13.5,7.10542736e-15 0,10.6 0,28.3 C0,55.9 38,51.5 38,63.4 C38,68 34,69.5 28.4,69.5 C20.1,69.5 9.5,66.1 1.1,61.5 L1.1,85.3 C10.4,89.3 19.8,91 28.4,91 C49.2,91 63.5,80.7 63.5,62.8 C63.4,33 25.3,38.3 25.3,27.1 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </svg>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Stripe;
