// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3DcGVepgWFIFuO8vA3de2T{display:flex;flex-direction:column;width:100%}._3DcGVepgWFIFuO8vA3de2T ._2ZUfWckgPNNLGwxgfXpyrV{background:#d2010d;color:#fff;align-self:center;font:italic normal 700 14px Colgate Ready;padding:7px 16px;min-height:38px}._3DcGVepgWFIFuO8vA3de2T._3ZlnRPdOQ9JyEwIObsjT-D{cursor:default;opacity:.8;background:#d6d6d6 0% 0% no-repeat padding-box !important;border-radius:100px 0px 100px 100px}._3DcGVepgWFIFuO8vA3de2T .RBEJpWd20o_Ul0JYWSnqK{margin-top:8px}._3DcGVepgWFIFuO8vA3de2T._3M24JA7env2_AwY9hA-FWf ._2ZUfWckgPNNLGwxgfXpyrV{background-color:#e23023;text-align:left;font:italic normal 700 14px Colgate Ready;letter-spacing:0px;background:#1c838a 0% 0% no-repeat padding-box;border-radius:100px 0px 100px 100px !important;opacity:1}._3DcGVepgWFIFuO8vA3de2T._3M24JA7env2_AwY9hA-FWf ._2ZUfWckgPNNLGwxgfXpyrV:active{opacity:.94;transition:linear 100ms opacity}@media(hover: hover)and (pointer: fine){._3DcGVepgWFIFuO8vA3de2T._3M24JA7env2_AwY9hA-FWf ._2ZUfWckgPNNLGwxgfXpyrV:active{background:#d2010d 0% 0% no-repeat padding-box !important}}._3DcGVepgWFIFuO8vA3de2T._3M24JA7env2_AwY9hA-FWf ._2ZUfWckgPNNLGwxgfXpyrV:hover{opacity:.94;transition:linear 100ms opacity}@media(hover: hover)and (pointer: fine){._3DcGVepgWFIFuO8vA3de2T._3M24JA7env2_AwY9hA-FWf ._2ZUfWckgPNNLGwxgfXpyrV:hover{background:#d2010d 0% 0% no-repeat padding-box !important}}@media only screen and (max-width: 955px){._3DcGVepgWFIFuO8vA3de2T ._2ZUfWckgPNNLGwxgfXpyrV{width:100%}}", ""]);
// Exports
exports.locals = {
	"AddItem": "_3DcGVepgWFIFuO8vA3de2T",
	"Button": "_2ZUfWckgPNNLGwxgfXpyrV",
	"Adding": "_3ZlnRPdOQ9JyEwIObsjT-D",
	"ErrorMessage": "RBEJpWd20o_Ul0JYWSnqK",
	"Added": "_3M24JA7env2_AwY9hA-FWf"
};
module.exports = exports;
