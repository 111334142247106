// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1W824xxH1Ppj9X44rxRe1b{display:flex;flex-direction:column;max-width:675px;padding:52px 58px}._1W824xxH1Ppj9X44rxRe1b .jyWjDnrDQ0FkigPatlitN{margin-bottom:22px}._1W824xxH1Ppj9X44rxRe1b ._2l5DcfpeNVllMTgrlkz62i{font-size:14px;color:#959392}._1W824xxH1Ppj9X44rxRe1b ._1ajKVGHPEVffKQ6Z4HGv64{display:flex;flex-direction:column;margin-top:20px}._1W824xxH1Ppj9X44rxRe1b ._1ajKVGHPEVffKQ6Z4HGv64>*{margin-bottom:10px}._1W824xxH1Ppj9X44rxRe1b ._1ajKVGHPEVffKQ6Z4HGv64>*:last-child{margin-bottom:0}._1W824xxH1Ppj9X44rxRe1b ._1ajKVGHPEVffKQ6Z4HGv64 .MY-A1_cyDSXklyS2J5OJf{font-size:14px;color:#959392}", ""]);
// Exports
exports.locals = {
	"Details": "_1W824xxH1Ppj9X44rxRe1b",
	"BasicInfo": "jyWjDnrDQ0FkigPatlitN",
	"Description": "_2l5DcfpeNVllMTgrlkz62i",
	"DroppedProducts": "_1ajKVGHPEVffKQ6Z4HGv64",
	"Title": "MY-A1_cyDSXklyS2J5OJf"
};
module.exports = exports;
