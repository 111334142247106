import React from 'react';
import { CurrencyPrefix } from '@helpers/constants/currency';
import styles from './index.scss';

export default function BalanceComponent({
  balance,
  title,
  description,
  icon,
  children,
  color,
}) {
  const formattedBalance =
    `${CurrencyPrefix.USD}` + (Math.round(`${balance}` * 100) / 100).toFixed(2);

  return (
    <div className={styles.Card}>
      <div className={styles.header}>
        {icon ? <img src={icon} className={styles.Icon} alt="" /> : null}
        <b style={{ color }} className={styles.CardTitle}>
          {title}
        </b>
      </div>
      <div className={styles.content}>
        <p style={{ color }} className={styles.BalanceText}>
          {formattedBalance}
        </p>
        {children}
      </div>
      <p className={styles.InfoText}>{description}</p>
    </div>
  );
}
