export const DisabledTouchAttribute = 'data-disabled-touch';

function checkTouchCapabilities() {
  if (!document.documentElement) return;

  if (!isTouchEnabled()) {
    document.documentElement.setAttribute(DisabledTouchAttribute, '');
  }
}

function isTouchEnabled() {
  return 'ontouchstart' in document.documentElement;
}

export { checkTouchCapabilities, isTouchEnabled };
