//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
import clientStyles from '@style/common/client.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { Checkmark } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { addItem, updateItem, withReferral } from '@helpers/referrals';
import { responseFormatter, shopifyFormatter } from '@helpers/formatter';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class AddItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: null,
      addedTransition: false,
    };
  }

  isAdded() {
    const { __referral, product } = this.props;
    return (
      __referral &&
      __referral.items &&
      __referral.items.findIndex(
        (x) => x.shopifyProductId.toString() === product.id.toString()
      ) > -1
    );
  }

  startAddedTransition() {
    this.setState({ addedTransition: true });

    setTimeout(() => {
      this.setState({ addedTransition: false });
    }, 1000);
  }

  addOrUpdateItem(added, product, __referral) {
    let productItem = null;

    if (!added) {
      productItem = this.props.product;
    } else {
      productItem = __referral.items.find(
        ({ shopifyProductId }) => shopifyProductId == product.id
      );
    }

    return !added
      ? addItem(productItem)
      : updateItem(productItem, productItem.quantityRecommended + 1);
  }

  render() {
    let that = this;
    const { loading, errorMessage, addedTransition } = this.state;
    const { __referral, product, className, didAddItem, t } = this.props;
    const added = this.isAdded();

    const componentClasses = classNames(styles.AddItem, {
      [className]: className,
      [styles.Added]: added,
      [styles.Adding]: loading || addedTransition,
    });

    const defaultVariant = shopifyFormatter.product(product).defaultVariant();
    const isAvailable = shopifyFormatter.variant(defaultVariant).isAvailable();

    return (
      <div className={componentClasses}>
        {/* to-do: use a vector for the plus sign */}
        <Button
          className={styles.Button}
          loading={loading}
          disabled={!isAvailable || loading || addedTransition}
          iconComponent={added && addedTransition && <Checkmark alt="" />}
          onKeyPress={() => {
            if (!added) {
              this.setState({ loading: true });
              this.addOrUpdateItem(added, product, __referral)
                .then(() => {
                  if (didAddItem) didAddItem(product);
                  this.startAddedTransition();
                })
                .catch((err) => {
                  const formattedError = responseFormatter.error(err);
                  that.setState({ errorMessage: formattedError.message() });
                  setTimeout(() => that.setState({ errorMessage: null }), 3000);
                })
                .finally(() => that.setState({ loading: false }));
            }
          }}
          onClick={() => {
            this.setState({ loading: true });
            this.addOrUpdateItem(added, product, __referral)
              .then((res) => {
                // if (didAddItem) didAddItem(product);
                this.startAddedTransition();
              })
              .catch((err) => {
                const formattedError = responseFormatter.error(err);
                that.setState({ errorMessage: formattedError.message() });
                setTimeout(() => that.setState({ errorMessage: null }), 3000);
              })
              .finally(() => that.setState({ loading: false }));
          }}
        >
          {loading
            ? t('dashboard_Adding')
            : isAvailable
            ? added
              ? addedTransition
                ? t('dashboard_Added')
                : t('dashboard_AddMore')
              : `${t('dashboard_AddToList')}`
            : t('dashboard_SoldOut')}
        </Button>
        {errorMessage && (
          <span
            className={classNames(
              styles.ErrorMessage,
              clientStyles.ErrorMessage
            )}
          >
            {errorMessage}
          </span>
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withReferral(withTranslation()(AddItem));
