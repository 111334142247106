// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1BQghCKEQMfG3dFFRQy7XD{display:flex;justify-content:center;width:100%;min-height:100%;background:rgba(17,17,17,.95);padding:68px 0 0 0}._1BQghCKEQMfG3dFFRQy7XD ._2SYMBHzKlxQcXHB8RjgkhS{display:flex;flex-direction:column;position:relative;background-color:#fff;box-shadow:0px 2px 6px rgba(0,0,0,.06);min-width:475px;max-width:475px;padding:52px 46px 26px 46px}._1BQghCKEQMfG3dFFRQy7XD ._2SYMBHzKlxQcXHB8RjgkhS>*{margin-bottom:19px}._1BQghCKEQMfG3dFFRQy7XD ._2SYMBHzKlxQcXHB8RjgkhS>*:last-child{margin-bottom:0}._1BQghCKEQMfG3dFFRQy7XD ._2SYMBHzKlxQcXHB8RjgkhS ._1MgcfBlLPLzc9yVzsVRFc9{align-self:center;font-weight:900;color:#d2010d;font-size:30px}@media only screen and (max-width: 576px){._1BQghCKEQMfG3dFFRQy7XD ._2SYMBHzKlxQcXHB8RjgkhS ._1MgcfBlLPLzc9yVzsVRFc9{font-size:26px}}._1BQghCKEQMfG3dFFRQy7XD ._2SYMBHzKlxQcXHB8RjgkhS ._3Je-pc91BMuJcZnNd18H5m{font-size:14px}._1BQghCKEQMfG3dFFRQy7XD ._2SYMBHzKlxQcXHB8RjgkhS ._3Je-pc91BMuJcZnNd18H5m>p{margin:10px 0}._1BQghCKEQMfG3dFFRQy7XD ._2SYMBHzKlxQcXHB8RjgkhS ._2kykoY6L5MoxWErNWrkIiT{position:absolute;right:24px;top:24px;cursor:pointer}@media only screen and (max-width: 955px){._1BQghCKEQMfG3dFFRQy7XD{padding:38px 0 0 0}._1BQghCKEQMfG3dFFRQy7XD ._2SYMBHzKlxQcXHB8RjgkhS{width:100%;min-width:0;max-width:none;padding:26px}._1BQghCKEQMfG3dFFRQy7XD ._2SYMBHzKlxQcXHB8RjgkhS>*{margin-bottom:0}._1BQghCKEQMfG3dFFRQy7XD ._2SYMBHzKlxQcXHB8RjgkhS>*:last-child{margin-bottom:0}}", ""]);
// Exports
exports.locals = {
	"Docs": "_1BQghCKEQMfG3dFFRQy7XD",
	"Content": "_2SYMBHzKlxQcXHB8RjgkhS",
	"Title": "_1MgcfBlLPLzc9yVzsVRFc9",
	"Text": "_3Je-pc91BMuJcZnNd18H5m",
	"Close": "_2kykoY6L5MoxWErNWrkIiT"
};
module.exports = exports;
