//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SearchInput extends React.Component {
  render() {
    const { className, value, placeholder, icon, onChange } = this.props;
    const componentClasses = classNames(styles.SearchInput, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <img src={icon} className={styles.Icon} alt="Search" />
        <input
          value={value}
          placeholder={placeholder}
          onChange={({ currentTarget: { value } }) => onChange(value)}
          className={styles.Input}
        />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SearchInput;
