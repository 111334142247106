//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Breadcrumbs extends React.Component {
  /*
  pages: [{ title, url }]
  */

  pageComponent(page, index, current) {
    const titleComponentClasses = classNames(styles.Title, {
      [styles.Current]: current,
    });

    return (
      <div className={styles.Page} key={index}>
        <Link to={page.url} className={titleComponentClasses}>
          {page.title}
        </Link>
        {!current && <span className={styles.Separator}>/</span>}
      </div>
    );
  }

  render() {
    const { className, pages } = this.props;
    const componentClasses = classNames(styles.Breadcrumbs, {
      [className]: className,
    });

    if (!pages) return '';

    return (
      <div className={componentClasses}>
        {pages.map((page, index) =>
          this.pageComponent(page, index, index >= pages.length - 1)
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Breadcrumbs;
