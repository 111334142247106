//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import axios from 'axios';
//------------------------------------------------------------------------------
// Package ---------------------------------------------------------------------
import { version } from '~/package.json';
//------------------------------------------------------------------------------
// Inline ----------------------------------------------------------------------
const vimeoClient = () => {
  return axios.create({
    baseURL: window._env.REACT_APP_VIMEO_API_BASE_URL,
    headers: {
      common: {
        Authorization: `Bearer ${window._env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
        Accept: `application/vnd.vimeo.*+json;version=${window._env.REACT_APP_VIMEO_API_VERSION}`,
      },
    },
  });
};

export const loadThumbnail = (videoId) => {
  return vimeoClient().get(`/videos/${videoId}/pictures`);
};
