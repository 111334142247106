// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NYekn449OULqF0qQxAwxT{height:100%}.NYekn449OULqF0qQxAwxT .ziLk5O5KHhH2FaWIKTsiJ{position:fixed;bottom:10px;right:10px;font-size:12px;color:#fff;background-color:rgba(44,44,44,.4);padding:2px 5px;border-radius:8px;z-index:998}", ""]);
// Exports
exports.locals = {
	"App": "NYekn449OULqF0qQxAwxT",
	"Title": "ziLk5O5KHhH2FaWIKTsiJ"
};
module.exports = exports;
