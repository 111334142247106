//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import validator from 'validator';
import { Helmet } from 'react-helmet';
import FocusTrap from 'focus-trap-react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
import clientStyles from '@style/common/client.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Badge, Button, ButtonStyle, Input, QrCode } from '@cmp/common';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import FormValidator from '@classes/formValidator';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { shareQRHeroUrl, shareQRProductsUrl, LogoImage } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { sendProfessionalCode } from '@helpers/referrals';
import { responseFormatter } from '@helpers/formatter';
import { isNameValid } from '@helpers/validation';
import { refCode } from '@helpers/constants/localStorage';
import { getValue } from '@helpers/localStorage';
import { withTranslation } from 'react-i18next';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ProfessionalCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phoneNumber: '',
      loading: false,
      errorMessage: null,
      showQrCode: false,
      qrCodeLoading: true,
      showSendForm: false,
    };

    this.onSendClick = this.onSendClick.bind(this);
    this.setupValidator();
    this.mountTrap = this.mountTrap.bind(this);
    this.unmountTrap = this.unmountTrap.bind(this);
  }

  componentDidMount() {
    this.setupData();
    this.mountTrap();
  }

  mountTrap() {
    this.setState({ activeTrap: true });
  }

  unmountTrap() {
    this.setState({ activeTrap: false });
  }
  setupData() {
    const { location: { state } = {} } = this.props;

    const { fromReferral } = state || {};
    if (!fromReferral) return;

    const {
      consumerEmail: email = '',
      consumerName: name = '',
      consumerPhone: phoneNumber = '',
    } = fromReferral;
    this.setState({ email, name, phoneNumber });
  }

  setupValidator() {
    // to-do: create exclusivity rules in FormValidator
    let emailValidationMethod = (string) => validator.isEmail(string);
    let phoneNumberValidationMethod = (string) =>
      validator.isMobilePhone(string, 'en-US');

    const items = [
      {
        key: 'name',
        defaultValue: '',
        method: (string) => isNameValid(string),
        validWhen: true,
      },
      {
        key: 'email',
        defaultValue: '',
        method: (string, data) => {
          if (!data['phoneNumber'] || !data['email']) {
            return (
              emailValidationMethod(string) ||
              phoneNumberValidationMethod(data['phoneNumber'])
            );
          }

          return (
            emailValidationMethod(string) &&
            phoneNumberValidationMethod(data['phoneNumber'])
          );
        },
        validWhen: true,
      },
      {
        key: 'phoneNumber',
        defaultValue: '',
        method: (string, data) => {
          if (!data['phoneNumber'] || !data['email']) {
            return (
              phoneNumberValidationMethod(string) ||
              emailValidationMethod(data['email'])
            );
          }

          return (
            phoneNumberValidationMethod(string) &&
            emailValidationMethod(data['email'])
          );
        },
        validWhen: true,
      },
    ];

    this.validator = new FormValidator(items);
  }

  headerComponent() {
    const { showQrCode } = this.state;
    const { t } = this.props;

    if (showQrCode) {
      return (
        <div className={styles.Header}>
          <h2 className={styles.Title}>{t('dashboard_YourQrCode')}</h2>
        </div>
      );
    }

    return (
      <div className={styles.Header}>
        <h2 className={styles.Title}>{t('dashboard_PatientDetails')}</h2>
        <span className={styles.Subtitle}>
          {t('dashboard_PatientDetailsForReferral')}
        </span>
      </div>
    );
  }

  onSendClick() {
    let that = this;
    const { name, email, phoneNumber, loading, errorMessage } = this.state;
    const { history, location, __referral } = this.props;
    const data = { name, email, phoneNumber };
    this.setState({ loading: true });

    sendProfessionalCode(data)
      .then(() => {
        history.push({
          pathname: '/recommendation/professional-code-success',
          state: {
            user: data,
            from: location.state.from,
            modal: true,
          },
        });
      })
      .catch((err) =>
        that.setState({
          errorMessage: responseFormatter.error(err).message(),
        })
      )
      .finally(() => that.setState({ loading: false }));
  }

  QRFlyer = ({ id, containerStyle, referralCode, i18n }) => {
    return (
      <div id={id} className={containerStyle}>
        <div className={styles.Hero}>
          <img className={styles.HeroImage} src={shareQRHeroUrl(i18n)} />
          <LogoImage className={styles.LogoImage} />
          <div className={styles.HeroTitle}>
            Unlock up to <span>{' 30% Off '}</span>Colgate Products
          </div>
          <img className={styles.Products} src={shareQRProductsUrl(i18n)} />
        </div>
        <div className={styles.QRContainer}>
          <QrCode
            url={`${window._env.REACT_APP_SHOPIFY_PUBLIC_URL}/pages/ref?${referralCode}`}
            showDownload={false}
            height="200px"
            onLoaded={() => this.setState({ qrCodeLoading: false })}
          />
          <p>
            Scan the QR code or go to <span>ColgateDirect.com</span> and become
            a member to gain access to your discount and other smile-worthy
            treats from Colgate.
          </p>
        </div>
      </div>
    );
  };
  qrComponent() {
    const { i18n } = this.props;
    const { QRFlyer } = this;
    const referralCode = getValue(refCode);

    return (
      <>
        <QRFlyer
          id="referralQRCode"
          containerStyle={styles.DownloadableQR}
          referralCode={referralCode}
          i18n={i18n}
        />
        <QRFlyer
          id="socialQRCode"
          containerStyle={styles.DownloadableSocialFlyer}
          referralCode={referralCode}
          i18n={i18n}
        />
      </>
    );
  }

  onDownloadQRCode = (type = 'social') => () => {
    const { t } = this.props;
    let elementId = type === 'social' ? 'socialQRCode' : 'referralQRCode';
    let format = type === 'social' ? [160, 160] : [210, 245];
    let fileName =
      type === 'social'
        ? 'dashboard_DownloadSocialFileName'
        : 'dashboard_DownloadQRCodeFileName';
    const qrCode = document.getElementById(elementId);

    this.setState({ qrCodeLoading: true }, () => {
      html2canvas(qrCode, { scale: 1 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          unit: 'mm',
          format,
        });
        const imgHeight = pdf.internal.pageSize.getWidth();
        if (type === 'social') {
          pdf.addImage(imgData, 'PNG', 0, 0, imgHeight, imgHeight);
        } else {
          pdf.addImage(imgData, 'PNG', 0, 0);
        }
        pdf.save(`${t(fileName)}.pdf`);
        this.setState({ qrCodeLoading: false });
      });
    });
  };

  formComponent() {
    let that = this;
    const { name, email, phoneNumber, loading, errorMessage } = this.state;
    const { history, location, t } = this.props;

    const validateAll = this.validator.validateAll(this.state);

    return (
      <>
        {this.headerComponent()}
        <div className={styles.Form}>
          <Input
            title={t('dashboard_PatientName')}
            placeholder={t('dashboard_PatientNamePH')}
            className={styles.Input}
            value={name}
            onChange={(name) => this.setState({ name })}
            id="focusedInput"
          />
          <span className={styles.Auxiliar}>
            {t('dashboard_PatientNameAux')}
          </span>
          <Input
            title={t('dashboard_Email')}
            placeholder={t('dashboard_EmailPH')}
            className={styles.Input}
            value={email}
            onChange={(email) => this.setState({ email })}
          />
          <Input
            title={t('dashboard_Phone')}
            placeholder={t('dashboard_PhonePH')}
            className={styles.Input}
            value={phoneNumber}
            onChange={(phoneNumber) => this.setState({ phoneNumber })}
          />
          <Button
            style={ButtonStyle.Secondary}
            className={styles.Button}
            onClick={this.onSendClick}
            onKeyPress={this.onSendClick}
            loading={loading}
            disabled={!validateAll.isValid}
            onKeyPress={validateAll.isValid ? this.onSendClick : undefined}
          >
            {t('dashboard_SendReferralCode')}
          </Button>
          {errorMessage && (
            <span className={clientStyles.ErrorMessage}>{errorMessage}</span>
          )}
          <span className={styles.Disclosure}>
            {t('dashboard_ConfirmingConsent')}
          </span>
        </div>
      </>
    );
  }

  render() {
    const { history, location, t } = this.props;
    const { showSendForm, qrCodeLoading } = this.state;
    const componentClasses = classNames(styles.Patient);

    return (
      <FocusTrap
        focusTrapOptions={{
          initialFocus: '#focusedInput',
        }}
      >
        <Modal.Base
          className={componentClasses}
          onClose={() => history.push(location.state.from)}
        >
          <Helmet>
            <title>{t('dashboard_ShareQRCode')}</title>
          </Helmet>
          {showSendForm ? (
            this.formComponent()
          ) : (
            <div className={styles.Form}>
              <div className={styles.Header}>
                <h2 className={styles.PopupTitle}>
                  {t('dashboard_ShareQRHeader')}
                </h2>
              </div>
              <Button
                style={ButtonStyle.Secondary}
                className={styles.Button}
                onClick={this.onDownloadQRCode('basic')}
                onKeyPress={this.onDownloadQRCode('basic')}
                loading={qrCodeLoading}
                disabled={qrCodeLoading}
                onKeyPress={this.onDownloadQRCode('basic')}
              >
                {t('dashboard_DownloadQRCode')}
              </Button>
              <Button
                style={ButtonStyle.Secondary}
                className={styles.Button}
                onClick={this.onDownloadQRCode('social')}
                onKeyPress={this.onDownloadQRCode('social')}
                loading={qrCodeLoading}
                disabled={qrCodeLoading}
                onKeyPress={this.onDownloadQRCode('social')}
              >
                {t('dashboard_DownloadSocialQRCode')}
              </Button>

              <Button
                style={ButtonStyle.Secondary}
                className={styles.Button}
                onClick={() => {
                  this.setState({ showSendForm: !showSendForm });
                }}
                onKeyPress={() => {
                  this.setState({ showSendForm: !showSendForm });
                }}
                // loading={loading}
                // disabled={!validateAll.isValid}
              >
                {t('dashboard_SendToPatient')}
              </Button>
              {this.qrComponent()}
            </div>
          )}
        </Modal.Base>
      </FocusTrap>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(ProfessionalCode);
