//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { FaSpinner } from 'react-icons/fa';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
import { t } from 'i18next';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
export const ButtonStyle = {
  Default: 'Default',
  Secondary: 'Secondary',
  Custom: 'Custom',
  SecondaryGreen: 'SecondaryGreen',
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Button extends React.Component {
  render() {
    const {
      className,
      children,
      history,
      style = ButtonStyle.Default,
      disabled,
      loading,
      to,
      onClick,
      iconComponent,
      onKeyPress,
    } = this.props;

    const componentClasses = classNames(styles.Button, {
      [className]: className,
      [styles[style]]: style,
      [styles.Disabled]: disabled,
    });

    const linkClasses = classNames(styles.Link, {
      [styles.leftMargin]: iconComponent,
    });

    return (
      <div
        className={componentClasses}
        onClick={
          onClick
            ? onClick
            : !disabled && !loading
            ? onClick || (() => history.push(to))
            : undefined
        }
        tabIndex="0"
        onKeyPress={
          onKeyPress
            ? onKeyPress
            : !disabled && !loading
            ? onKeyPress || (() => history.push(to))
            : undefined
        }
      >
        {loading ? (
          <>
            <FaSpinner className={styles.Icon} />
            {children}
          </>
        ) : (
          <>
            {iconComponent}
            <span className={linkClasses}>{children}</span>
          </>
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(Button);
