// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2sMOK7o3cO8rmNSZnvj4LH{padding:3px 12px;font-weight:bold;font-size:14px;border-radius:20px}._2sMOK7o3cO8rmNSZnvj4LH.muVN3Uw2pDMqelC1zB7Ey{color:#009ca6;background-color:rgba(0,156,166,.1)}._2sMOK7o3cO8rmNSZnvj4LH._2R_-28AQ07ySq5IQw-Bvxq{color:#311b92;background-color:rgba(49,27,146,.1)}._2sMOK7o3cO8rmNSZnvj4LH.G-X91ZourLIXwBkS6cBBt{color:#a51c1f;background:rgba(165,28,31,.1)}._2sMOK7o3cO8rmNSZnvj4LH._3oFCAoZ8heG0RVb7MxX6gs,._2sMOK7o3cO8rmNSZnvj4LH._2tj7x991Ia9X56B1YTjl6w{color:#fd6a28;background:rgba(253,106,40,.1)}", ""]);
// Exports
exports.locals = {
	"Badge": "_2sMOK7o3cO8rmNSZnvj4LH",
	"Purchased": "muVN3Uw2pDMqelC1zB7Ey",
	"AdditionalPurchase": "_2R_-28AQ07ySq5IQw-Bvxq",
	"PartiallyPurchased": "G-X91ZourLIXwBkS6cBBt",
	"Pending": "_3oFCAoZ8heG0RVb7MxX6gs",
	"PendingAdditionalPurchase": "_2tj7x991Ia9X56B1YTjl6w"
};
module.exports = exports;
