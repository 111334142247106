// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2cw286_Av3H-MzM78c-0C3{display:flex;flex-direction:column;align-items:center;text-align:center}._2cw286_Av3H-MzM78c-0C3 ._3-IXTDSuxlX207GvITpkcJ{font-weight:900;font-size:30px;color:#d2010d;font-style:italic;margin-bottom:8px}@media only screen and (max-width: 955px){._2cw286_Av3H-MzM78c-0C3 ._3-IXTDSuxlX207GvITpkcJ{font-size:24px}}._2cw286_Av3H-MzM78c-0C3 ._1iEvCZSMtbIqh-0EZkbvKb{margin-top:18px}", ""]);
// Exports
exports.locals = {
	"BasicInfo": "_2cw286_Av3H-MzM78c-0C3",
	"Title": "_3-IXTDSuxlX207GvITpkcJ",
	"Badge": "_1iEvCZSMtbIqh-0EZkbvKb"
};
module.exports = exports;
