import { UserStorageKey } from '@helpers/constants/localStorage';

export const storeValue = (key, value) => {
  localStorage.setItem(key, value);
};

export const removeAll = () => {
  localStorage.clear();
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export const getValue = (key, parsing) => {
  if (!localStorage) return;

  const localItem = localStorage.getItem(key);
  if (!localItem) return;

  return parsing ? JSON.parse(localItem) : localItem;
};
