// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1G4rEHZjx4XDbYakJkRipN{max-width:400px;width:90%;padding-top:0px;padding-bottom:0px;margin:0 auto;top:50%;transform:translateY(-50%);position:fixed !important;display:flex;flex-direction:column;align-items:stretch;padding:50px 20px 20px 20px;min-width:0}._1G4rEHZjx4XDbYakJkRipN._3j_mbXdeFhasOu-bfR1gJo{min-height:0;max-height:none;height:auto;height:80%;width:100%}._1G4rEHZjx4XDbYakJkRipN._3j_mbXdeFhasOu-bfR1gJo ._3ypdr0c9o--D_NNbmIk9Fz>*{width:100%;height:100%}._1G4rEHZjx4XDbYakJkRipN ._3ypdr0c9o--D_NNbmIk9Fz{display:flex;flex-direction:column;flex-grow:2}@media only screen and (max-width: 955px){._1G4rEHZjx4XDbYakJkRipN._3j_mbXdeFhasOu-bfR1gJo{height:60%}}", ""]);
// Exports
exports.locals = {
	"Media": "_1G4rEHZjx4XDbYakJkRipN",
	"MediaVideo": "_3j_mbXdeFhasOu-bfR1gJo",
	"Content": "_3ypdr0c9o--D_NNbmIk9Fz"
};
module.exports = exports;
