//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Header, Form, Button, ButtonStyle } from '@cmp/common';
import { Dashboard } from '@cmp/client';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { signIn } from '@api/endpoints/post/auth';
import { startConnect } from '@api/endpoints/post/payments';
import { loadProviders } from '@api/endpoints/get/providers';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { verifyLogin } from '@helpers/gigya';
import { getValue, storeValue } from '@helpers/localStorage';
import { StripeConnectURL } from '@helpers/stripe';
import { auth, deauth, withAuthUser } from '@helpers/auth';
import { updateStripeVerification, isRegistered } from '@helpers/account';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import {
  AuthorizationStorageKey,
  SkipStripe,
  StripeRegistrationUrl,
  refCode,
} from '@helpers/constants/localStorage';
import { findPayoutOption } from '@helpers/constants/user';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      stripeUrl: null,
      user: null,
    };
    this.verifyAccount = this.verifyAccount.bind(this);
    this.verifyStripe = this.verifyStripe.bind(this);
  }

  componentDidMount() {
    let that = this;
    const { __authUser } = this.props;

    verifyLogin(__authUser).then(that.verifyAccount).catch(deauth);
  }

  verifyAccount(user) {
    const { history } = this.props;
    const pushState = { fromCallback: true };

    console.log('1. verifyAccount', user);

    if (!isRegistered(user)) {
      console.log('1.1 !isRegistered');

      history.push({
        pathname: '/registration',
        state: pushState,
      });
    } else {
      this.verifyAuthenticationTokenAndStripe(user);
    }
  }

  verifyAuthenticationTokenAndStripe(user) {
    let that = this;
    const authorizationToken = getValue(AuthorizationStorageKey);

    console.log('2. verifyAuthenticationTokenAndStripe');

    if (authorizationToken) {
      console.log('2.1 authorizationToken');

      this.verifyStripe(user);
    } else {
      console.log('2.2 !authorizationToken');

      signIn(user)
        .then(({ headers }) => {
          auth({}, true, headers);
          that.verifyStripe(user);
        })
        .catch(deauth);
    }
  }

  verifyStripe(user) {
    let that = this;
    const idealUser = user || this.props.__authUser;
    const { data } = idealUser;
    const payoutOption = findPayoutOption(data.payout_option);

    console.log('4. verifyStripe');

    this.setState({ user: idealUser });

    loadProviders()
      .then(({ data }) => {
        console.log('4.1 loadProviders', data);

        const { stripeConnected, referralCode } = data || {};
        storeValue(refCode, referralCode);

        if (payoutOption && !payoutOption.requireStripeAccount) {
          console.log(
            `4.2 Payout option (${payoutOption.key}) doesn't require Stripe`
          );

          updateStripeVerification(true);

          console.log(
            '4.2.1 End flow. Authenticate user:',
            idealUser.identities
          );
          auth(idealUser);
        } else {
          console.log('4.3 check if stripe connected');
          if (stripeConnected) {
            updateStripeVerification(true);

            console.log(
              '4.3.1 End flow. Authenticate user:',
              idealUser.identities
            );
            auth(idealUser);
          } else {
            that.startStripeConnect(user);
          }
        }
      })
      .catch((err) => that.setState({ error: err }));
  }

  skipStripe(url) {
    storeValue(SkipStripe, true);
    storeValue(StripeRegistrationUrl, url);
  }

  startStripeConnect(user) {
    let that = this;

    console.log('4. startStripeConnect');

    startConnect()
      .then(({ data }) => {
        const { csrfToken } = data || {};
        if (!csrfToken) return;

        const url = StripeConnectURL(
          window._env.REACT_APP_STRIPE_CLIENT_ID,
          csrfToken,
          user
        );

        that.setState({
          stripeUrl: url,
        });
      })
      .catch((err) => console.log(err));
  }

  loadingComponent() {
    return (
      <Form className={styles.Form}>
        <FaSpinner className={styles.Spinner} />
      </Form>
    );
  }

  errorComponent() {
    const { t } = this.props;
    return (
      <Form
        className={styles.Form}
        title={t('GenericErrorMessage')}
        subtitle={t('login_PleaseReloadPage')}
        additionalComponent={
          <Link to="/login" className={styles.GoBack}>
            {t('login_GoBackToLogin')}
          </Link>
        }
      />
    );
  }

  stripeComponent() {
    const { stripeUrl, user } = this.state;

    return (
      <Form
        className={styles.Form}
        title={t('login_WhereShouldDeposit')}
        subtitle={
          <div>
            {t('login_WhereShouldDepositDescription')}
            <br />
            {t('login_ColgatePartneredWith')}{' '}
            <a
              href="https://stripe.com/about"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.Links}
            >
              Stripe
            </a>{' '}
            {t('login_ColgatePartneredWithDescription')}
          </div>
        }
        additionalComponent={
          <div>
            <Button
              style={ButtonStyle.Secondary}
              onClick={() => (window.location = stripeUrl)}
            >
              {t('login_CreateStripeAccount')}
            </Button>

            {/* Adding second button to give skip stripe option */}

            <Button
              style={ButtonStyle.Secondary}
              onClick={() => {
                this.skipStripe(stripeUrl);
                auth(user);
              }}
            >
              {t('login_CreateStripeAccountLater')}
            </Button>
          </div>
        }
      />
    );
  }

  idealComponent() {
    const { stripeUrl, error } = this.state;

    if (stripeUrl) {
      return this.stripeComponent();
    } else if (error) {
      return this.errorComponent();
    } else {
      return this.loadingComponent();
    }
  }

  render() {
    const componentClasses = classNames(styles.Auth);

    return (
      <div className={componentClasses}>
        <Header />
        <div className={styles.Content}>{this.idealComponent()}</div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withTranslation()(Auth));
