// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2JUydzqb_HSbAtZWBew-2Q{height:19px;width:22px;fill:#949494}", ""]);
// Exports
exports.locals = {
	"Error": "_2JUydzqb_HSbAtZWBew-2Q"
};
module.exports = exports;
