// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TbQmbf45g39-4mVef43kK{display:flex;flex-direction:column;align-items:center;background:#fff;border-radius:3px;max-width:508px;padding:42px 80px;text-align:center}.TbQmbf45g39-4mVef43kK ._3mpC_6rH49PQnsCVuPl1A1{font-weight:900;font-size:26px;font-style:italic;color:#d2010d;margin-bottom:11px}@media only screen and (max-width: 576px){.TbQmbf45g39-4mVef43kK ._3mpC_6rH49PQnsCVuPl1A1{font-size:26px}}.TbQmbf45g39-4mVef43kK ._2KLVFMigxrrcyShzKt-Mgb{font-weight:normal;font-size:14px;margin-bottom:32px}.TbQmbf45g39-4mVef43kK ._3s_53EMPrJArUcJGCjPR_T{align-self:stretch;min-width:350px}.TbQmbf45g39-4mVef43kK ._3s_53EMPrJArUcJGCjPR_T>*{margin-bottom:16px}.TbQmbf45g39-4mVef43kK ._3s_53EMPrJArUcJGCjPR_T>*:last-child{margin-bottom:0}.TbQmbf45g39-4mVef43kK ._1I4Vm55ll811RS8_Uof5ro{width:100%}.TbQmbf45g39-4mVef43kK ._2MURyzgkTrh8xLmRxpejDY{color:#959392;font-size:12px;font-weight:bold;margin-top:12px;cursor:default}@media only screen and (max-width: 955px){.TbQmbf45g39-4mVef43kK{padding:22px 30px;max-width:none}.TbQmbf45g39-4mVef43kK ._3s_53EMPrJArUcJGCjPR_T{min-width:0}}", ""]);
// Exports
exports.locals = {
	"Form": "TbQmbf45g39-4mVef43kK",
	"Title": "_3mpC_6rH49PQnsCVuPl1A1",
	"Subtitle": "_2KLVFMigxrrcyShzKt-Mgb",
	"Content": "_3s_53EMPrJArUcJGCjPR_T",
	"Submit": "_1I4Vm55ll811RS8_Uof5ro",
	"ErrorMessage": "_2MURyzgkTrh8xLmRxpejDY"
};
module.exports = exports;
