//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Header, Footer } from '@cmp/common';
import { PatientList } from './components';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PatientDirectory extends React.Component {
  headerComponent() {
    const { t } = this.props;
    return (
      <div className={styles.Header}>
        <h2 className={styles.Title}>{t('header_PatientDirectory')}</h2>
      </div>
    );
  }

  render() {
    const { t, history, location } = this.props;
    const componentClasses = classNames(styles.PatientDirectory);

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('header_PatientDirectory')}</title>
        </Helmet>
        <Header />
        <div className={styles.Content}>
          <div className={styles.Container}>
            {this.headerComponent()}
            <PatientList
              onChangeRefetch={(refetch) => (this.listRefetch = refetch)}
              onClick={({ id, name, email, refcode }) => {
                history.push({
                  pathname: '/',
                  state: {
                    from: location,
                    fromPatientDirectory: {
                      id,
                      name,
                      email,
                      refcode,
                    },
                  },
                });
                /* Check this out later
                history.push({
                  pathname: `/recommendations`,
                  state: {
                    from: location,
                    modal: true,
                    fromPatientDirectory: {
                      id,
                      name,
                      email,
                      refcode
                    },
                  },
                });
                */
              }}
              onOrderHistoryClick={({ id, name, email, refcode }) => {
                history.push({
                  pathname: '/patient-directory/orders',
                  state: {
                    from: location,
                    fromPatientDirectory: {
                      id,
                      name,
                      email,
                      refcode,
                    },
                  },
                });
              }}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(withTranslation()(PatientDirectory));
