import qs from 'query-string';

const StripeConnectCallbackPath = '/callback/payment';

export const StripeConnectURL = (client_id, state, authUser) => {
  const baseUrl = 'https://connect.stripe.com/express/oauth/authorize';

  const query = {
    client_id,
    state,
    redirect_uri: window._env.REACT_APP_PUBLIC_URL + StripeConnectCallbackPath,
    ...connectUser(authUser),
  };

  const parsedUrl = qs.stringifyUrl({ url: baseUrl, query });
  return parsedUrl;
};

const connectUser = (user) => {
  const { profile, data: { addresses } = {} } = user || {};

  return {
    'stripe_user[email]': profile && profile.email,
    'stripe_user[first_name]': profile && profile.first_name,
    'stripe_user[last_name]': profile && profile.last_name,
    'stripe_user[url]': 'https://pro.colgatedirect.com',

    'stripe_user[street_address]': addresses && addresses.street,
    'stripe_user[city]': addresses && addresses.city,
    'stripe_user[state]': addresses && addresses.state_province,
    'stripe_user[zip]': addresses && addresses.postal_cd,

    'stripe_user[country]': 'US',
    'stripe_user[currency]': 'usd',
  };
};
