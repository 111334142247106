// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1pSkrd-aG8YjhasW5LJaTj{margin-top:70px}._1pSkrd-aG8YjhasW5LJaTj ._164aqNTkqkowCsiv4eKoSl{display:flex;flex-direction:column;align-items:stretch}@media only screen and (max-width: 955px){._1pSkrd-aG8YjhasW5LJaTj{margin-top:62px}}._1vySjjvFWE6sVz1KItweQA{display:flex;min-height:calc((195 / 529) * 100vw);flex-direction:column;width:100%;background-image:url(\"https://cdn.shopify.com/s/files/1/0399/7427/3181/files/desktop_splash_screen_image.png?v=1711475137\");background-repeat:no-repeat;background-size:100%}._3CsSbu8fr7_V4BFPbnmjgh{margin-top:1em;text-align:left;color:#fff;width:50%}._3CsSbu8fr7_V4BFPbnmjgh p{font-size:30px;font-weight:300;font-size:1.4em}._3CsSbu8fr7_V4BFPbnmjgh a{color:#fff;font-weight:800}._1HJqmU2uu0xYu__MlUIIWM{width:100%;background:red;height:50px;border-radius:8px 0px 8px 8px;text-align:center;margin-top:10px;color:#fff}._3a2cd7fVzVN1IbXbV1DtaP{padding:7%}._62pZQGD8Or0sIlUCuQoNE{margin-top:2em;display:flex;align-items:flex-start;justify-content:start;width:60%}._62pZQGD8Or0sIlUCuQoNE h1{font-size:18px;color:#fff;font-weight:600;font-size:45px;text-align:left}._2EBAzWkuTVT4LcnkRRqu1M{display:none}._3Tw-9MPuvlYVDsJ5rZiTfl{display:flex;flex-direction:row;justify-content:space-between;width:90%;margin-left:auto;margin-right:auto;margin-top:2em}._3Tw-9MPuvlYVDsJ5rZiTfl ._1N8QX2MJqV-jqqqjj_p894{height:1em}@media all and (max-width: 960px){._3CsSbu8fr7_V4BFPbnmjgh{width:100%;margin-right:0}._1vySjjvFWE6sVz1KItweQA{background-image:none}._2Jmm4-j8zvDtKTyZrwfulu{width:100%}a{font-weight:bold;color:#000}._3CsSbu8fr7_V4BFPbnmjgh p{color:#000;font-size:1em;margin-bottom:1em}._62pZQGD8Or0sIlUCuQoNE{width:100%;font-size:30px}._62pZQGD8Or0sIlUCuQoNE h1{color:red;font-size:1em;margin:0}._3Tw-9MPuvlYVDsJ5rZiTfl{display:flex;align-items:center;flex-direction:column;width:80%;margin-left:auto;margin-right:auto}.IzoSCTtAhpSbNoq2dHSK4{margin-bottom:1em}._1N8QX2MJqV-jqqqjj_p894{margin-bottom:1em}}", ""]);
// Exports
exports.locals = {
	"Landing": "_1pSkrd-aG8YjhasW5LJaTj",
	"Content": "_164aqNTkqkowCsiv4eKoSl",
	"center": "_1vySjjvFWE6sVz1KItweQA",
	"centerMessage": "_3CsSbu8fr7_V4BFPbnmjgh",
	"smile20": "_1HJqmU2uu0xYu__MlUIIWM",
	"splashMessage": "_3a2cd7fVzVN1IbXbV1DtaP",
	"thankyou": "_62pZQGD8Or0sIlUCuQoNE",
	"mobileBanner": "_2EBAzWkuTVT4LcnkRRqu1M",
	"splashFooter": "_3Tw-9MPuvlYVDsJ5rZiTfl",
	"allRightsReserved": "_1N8QX2MJqV-jqqqjj_p894",
	"mobileBannerImg": "_2Jmm4-j8zvDtKTyZrwfulu",
	"colgatePalmoliveLogo": "IzoSCTtAhpSbNoq2dHSK4"
};
module.exports = exports;
