//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import FocusTrap from 'focus-trap-react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { referralFormatter } from '@helpers/formatter';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Success extends React.Component {
  constructor(props) {
    super(props);
    this.mountTrap = this.mountTrap.bind(this);
    this.unmountTrap = this.unmountTrap.bind(this);
  }

  componentDidMount() {
    this.mountTrap();
  }

  mountTrap() {
    this.setState({ activeTrap: true });
  }

  unmountTrap() {
    this.setState({ activeTrap: false });
  }

  closeModal = (path) => {
    // Hack: in order to properly update the recommendation history page,
    //       force a full refresh
    const {
      location: { state },
    } = this.props;

    const recommendationsPath = `/${path}`;
    window.location =
      recommendationsPath ||
      (state && state.from && state.from.pathname) ||
      '/';
  };

  render() {
    const {
      location: { state },
    } = this.props;
    const { user, referral } = state || {};

    const componentClasses = classNames(styles.Success);
    const totalRecommendedCount = referralFormatter.totalRecommendedCount(
      referral
    );

    return (
      <FocusTrap>
        <div id="successModal" tabIndex="0">
          <Modal.Generic
            title={t('dashboard_RecommendationSent')}
            subtitle={t('dashboard_SuccessfullyRecommended', {
              count: totalRecommendedCount,
              name: user.name || t('dashboard_APatient'),
            })}
            action={t('dashboard_ViewRecommendation')}
            to="/recommendations"
            onClick={() => this.closeModal('recommendations')}
            onClose={() => this.closeModal('')}
          >
            <Helmet>
              <title>{t('dashboard_Success')}</title>
            </Helmet>
          </Modal.Generic>
        </div>
      </FocusTrap>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Success);
