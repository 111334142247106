export const GigyaStatus = {
  SUCCESS: 'OK',
  FAIL: 'FAIL',
};

export const GigyaErrorStatus = {
  AccountPendingRegistration: 206001,
  InvalidLoginID: 403042,
};

const GigyaSessionDurationDays = 30;
export const GigyaSessionDuration = -2; // GigyaSessionDurationDays * 24 * 60 * 60;
