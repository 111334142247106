// Generic
export { default as DashboardIcon } from './dashboard-icon.svg';
export { default as ShoppingCartIcon } from './shopping-cart-icon.svg';
export { default as DownChevronIcon } from './down-chevron-icon.svg';

// Dashboard
export { default as GiftCardIcon } from './gift-card-icon.svg';
export { default as DollarSignIcon } from './dollar-sign-icon.svg';
export { default as LifelineIcon } from './lifeline-icon.svg';
export { default as FlagIcon } from './flag-icon.svg';
export { default as PurchasesIcon } from './purchases-icon.svg';
export { default as ChevronLeftIcon } from './chevron-left-icon.svg';
export { default as ChevronRightIcon } from './chevron-right-icon.svg';
export { default as ChevronDownFilledIcon } from './chevron-down-filled-icon.svg';
export { default as SearchIcon } from './search-icon.svg';
