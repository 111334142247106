//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { ForgotPassword, ResetPassword } from './client';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Password extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/password/forgot" component={ForgotPassword} />
        <Route path="/password/reset" component={ResetPassword} />
        <Redirect from="/password*" to="/password/forgot" />
      </Switch>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Password;
