//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import App from '@cmp/App';
//------------------------------------------------------------------------------
// History ---------------------------------------------------------------------
import history from '@helpers/history';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import './index.scss';
import { FaSpinner } from 'react-icons/fa';
//------------------------------------------------------------------------------
// Entry Point -----------------------------------------------------------------
const entryPoint = document.getElementById('root');
//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
if (entryPoint) {
  ReactDOM.render(
    <Router history={history}>
      <Suspense
        fallback={
          <FaSpinner style={{ animation: 'spin 1500ms linear infinite' }} />
        }
      >
        <App />
      </Suspense>
    </Router>,
    entryPoint
  );
}
