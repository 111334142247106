// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Hj7OeRLls5XcDPVvxHvVu{display:flex;align-items:baseline;flex-wrap:wrap}._1Hj7OeRLls5XcDPVvxHvVu>*{margin-right:3px}._1Hj7OeRLls5XcDPVvxHvVu>*:last-child{margin-right:0}._1Hj7OeRLls5XcDPVvxHvVu ._1j1GvdrzycIZGuEPfaXZui{display:flex;align-items:baseline;font-weight:bold;font-size:14px}._1Hj7OeRLls5XcDPVvxHvVu ._1j1GvdrzycIZGuEPfaXZui>*{margin-right:3px}._1Hj7OeRLls5XcDPVvxHvVu ._1j1GvdrzycIZGuEPfaXZui>*:last-child{margin-right:0}._1Hj7OeRLls5XcDPVvxHvVu ._1j1GvdrzycIZGuEPfaXZui ._3zN_LnUe65dm7pml-C42f5,._1Hj7OeRLls5XcDPVvxHvVu ._1j1GvdrzycIZGuEPfaXZui ._1ZV64mHXhZCMrADANz6I3b{color:#959392}._1Hj7OeRLls5XcDPVvxHvVu ._1j1GvdrzycIZGuEPfaXZui ._3zN_LnUe65dm7pml-C42f5._3EtTMz-d-jCx_OYfB8KVzZ,._1Hj7OeRLls5XcDPVvxHvVu ._1j1GvdrzycIZGuEPfaXZui ._1ZV64mHXhZCMrADANz6I3b._3EtTMz-d-jCx_OYfB8KVzZ{color:#000}", ""]);
// Exports
exports.locals = {
	"Breadcrumbs": "_1Hj7OeRLls5XcDPVvxHvVu",
	"Page": "_1j1GvdrzycIZGuEPfaXZui",
	"Title": "_3zN_LnUe65dm7pml-C42f5",
	"Separator": "_1ZV64mHXhZCMrADANz6I3b",
	"Current": "_3EtTMz-d-jCx_OYfB8KVzZ"
};
module.exports = exports;
