//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Form, Button, ButtonStyle, Header } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { withAuthUser } from '@helpers/auth';
import { ScreenSet, showScreenSet } from '@helpers/gigya';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class VerifyEmail extends React.Component {
  componentDidMount() {
    const { __authUser: { regToken } = {} } = this.props;

    showScreenSet(ScreenSet.VerifyEmail, 'verify-container', {
      regToken,
    });
  }

  render() {
    const componentClasses = classNames(styles.VerifyEmail);

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('landing_VerifyYourEmail')}</title>
        </Helmet>

        <Header />
        <div className={styles.Content}>
          <Form
            className={styles.Form}
            title={t('landing_VerifyEmail')}
            id="verify-container"
          />
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withTranslation()(VerifyEmail));
