// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-moz-keyframes OesyOcMdRYNnA6bn1dDql{100%{-moz-transform:rotate(360deg)}}@-webkit-keyframes OesyOcMdRYNnA6bn1dDql{100%{-webkit-transform:rotate(360deg)}}@keyframes OesyOcMdRYNnA6bn1dDql{100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}._3V3fkh6Ga4hF1Qkz5T2zsf{display:flex;justify-content:center;align-items:flex-start;background-color:#fafafb;height:100%;overflow:scroll}._3V3fkh6Ga4hF1Qkz5T2zsf ._2VNsXUIzDSx-2s9GjlROeE{margin-top:100px}._3V3fkh6Ga4hF1Qkz5T2zsf ._2VNsXUIzDSx-2s9GjlROeE ._2ZPfTeNAPjd9X2giCSYhp7 ._2XwTMxDNJLaV_szU_gjg5w{animation:OesyOcMdRYNnA6bn1dDql 1500ms linear infinite}._3V3fkh6Ga4hF1Qkz5T2zsf ._2VNsXUIzDSx-2s9GjlROeE ._2ZPfTeNAPjd9X2giCSYhp7 .l1vhUKMuXUUUNG3SLK1Gw{font-weight:bold;font-size:14px;color:#e23023;margin-top:12px}._3V3fkh6Ga4hF1Qkz5T2zsf ._2VNsXUIzDSx-2s9GjlROeE ._2ZPfTeNAPjd9X2giCSYhp7 ._1Mopdh8_7sDPveB5GguZ0o{font-weight:bold;font-style:italic;text-decoration:underline;font-size:16px;color:#08a59d;margin-top:12px}", ""]);
// Exports
exports.locals = {
	"Auth": "_3V3fkh6Ga4hF1Qkz5T2zsf",
	"Content": "_2VNsXUIzDSx-2s9GjlROeE",
	"Form": "_2ZPfTeNAPjd9X2giCSYhp7",
	"Spinner": "_2XwTMxDNJLaV_szU_gjg5w",
	"spin": "OesyOcMdRYNnA6bn1dDql",
	"GoBack": "l1vhUKMuXUUUNG3SLK1Gw",
	"Links": "_1Mopdh8_7sDPveB5GguZ0o"
};
module.exports = exports;
