//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
import { Trans, withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Testimonials extends React.Component {
  testimonialComponent(author, testimonial) {
    return (
      <div className={styles.Testimonial}>
        <div className={styles.Details}>
          <span className={styles.Overhead}>“</span>
          <span className={styles.Text}>{testimonial}</span>
          <span className={styles.Author}>{author}</span>
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const componentClasses = classNames(styles.Testimonials);

    return (
      <div className={styles.Testimonials}>
        <div className={styles.Subcontent}>
          <span className={styles.Overhead}>{t('landing_Testimonials')}</span>
          <h1 className={styles.Title}>{t('landing_TestimonialsTitle')}</h1>
          <span className={styles.Subtitle}>
            {t('landing_TestimonialsSubtitle')}
          </span>
        </div>
        <div className={styles.List}>
          {this.testimonialComponent(
            t('landing_Testimonial1Name'),
            <Trans i18nKey={'landing_Testimonial1Text'}>
              I think it's a great idea, especially for offices like mine that
              do not sell any products in our office.
              <br />
              <br />I can check to see if they actually get the products I
              recommend, and we can talk about it at their next appointment.
            </Trans>
          )}
          {this.testimonialComponent(
            t('landing_Testimonial2Name'),
            <Trans i18nKey={'landing_Testimonial2Text'}>
              Brilliant idea! It will make it so easy to recommend products to
              patients.
              <br />
              <br />
              Everybody loves a discount, and patients have the products right
              there so all they have to do is click and purchase.
            </Trans>
          )}
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Testimonials);
