//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { LogoImage } from '@cmp/images';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Logo extends React.Component {
  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.Logo, {
      [className]: className,
    });

    return (
      <Link to="/">
        <LogoImage className={componentClasses} alt="Logo" aria-label="Logo" />
      </Link>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Logo;
