// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._17HbpqbG6eFSauGluDiAq9{-webkit-animation:_1DEgvtQ3AWA9k7BiqUUItk 2s infinite linear;animation:_1DEgvtQ3AWA9k7BiqUUItk 2s infinite linear}._2oxLyuPFuF74jyro8-7Raa{display:block;float:right;font-size:11px;font-weight:bold;color:#d2010d}._2oxLyuPFuF74jyro8-7Raa:hover{opacity:.9;color:#d2010d}@-webkit-keyframes _1DEgvtQ3AWA9k7BiqUUItk{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}@keyframes _1DEgvtQ3AWA9k7BiqUUItk{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}.jMIHDz_cAtfka2breqvWz{height:100px;display:flex;align-items:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"spin": "_17HbpqbG6eFSauGluDiAq9",
	"icon-spin": "_1DEgvtQ3AWA9k7BiqUUItk",
	"downloadButton": "_2oxLyuPFuF74jyro8-7Raa",
	"loadingWrapper": "jMIHDz_cAtfka2breqvWz"
};
module.exports = exports;
