// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3-xuV8JjOmlb4cNc4vGlYu{display:flex;flex-direction:column;padding:31px 39px;flex-grow:1;flex-basis:0;background:#fff;border-radius:3px;box-shadow:0 1px 1px rgba(0,0,0,.05),0 2px 3px rgba(0,0,0,.04),0 4px 5px rgba(0,0,0,.03),0 6px 7px rgba(0,0,0,.02),0 8px 9px rgba(0,0,0,.01);transition:box-shadow ease-out 100ms;margin-right:20px}._3-xuV8JjOmlb4cNc4vGlYu:hover{transition:box-shadow ease-out 100ms;box-shadow:0 1px 1px rgba(0,0,0,.03),0 2px 2px rgba(0,0,0,.02),0 3px 3px rgba(0,0,0,.01),0 4px 4px rgba(0,0,0,.01),0 5px 5px rgba(0,0,0,.01)}._3-xuV8JjOmlb4cNc4vGlYu>*{margin-bottom:16px}._3-xuV8JjOmlb4cNc4vGlYu>*:last-child{margin-bottom:0}._3-xuV8JjOmlb4cNc4vGlYu ._1R7I4ZNM3ykOJ_igygq2K-{font-size:small}._3-xuV8JjOmlb4cNc4vGlYu .Ellm9U21a84UOSwVfBTNq{font-size:xx-large;font-weight:bold;margin-right:20px}._3-xuV8JjOmlb4cNc4vGlYu ._2g36E33CMVn7rKVC89jS2w{flex-direction:row;display:flex;align-items:center}._3-xuV8JjOmlb4cNc4vGlYu ._2GQjGIbR1CHT8HVy_HRYF5{display:flex;flex-direction:row}._3-xuV8JjOmlb4cNc4vGlYu ._3EsbKeIPyKEMkpe2ZVdDZ2{margin-right:5px}._3-xuV8JjOmlb4cNc4vGlYu .s8MjmpE1MzZ-nzbv9-Mxu{font-size:18px}._3-xuV8JjOmlb4cNc4vGlYu:last-of-type{margin-right:0}@media only screen and (max-width: 800px){._3-xuV8JjOmlb4cNc4vGlYu{margin-right:0px;margin-top:16px}}", ""]);
// Exports
exports.locals = {
	"Card": "_3-xuV8JjOmlb4cNc4vGlYu",
	"InfoText": "_1R7I4ZNM3ykOJ_igygq2K-",
	"BalanceText": "Ellm9U21a84UOSwVfBTNq",
	"content": "_2g36E33CMVn7rKVC89jS2w",
	"header": "_2GQjGIbR1CHT8HVy_HRYF5",
	"Icon": "_3EsbKeIPyKEMkpe2ZVdDZ2",
	"CardTitle": "s8MjmpE1MzZ-nzbv9-Mxu"
};
module.exports = exports;
