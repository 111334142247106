// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3QlnfvEVBFGU3ABxUfop_v{display:flex;flex-direction:column;align-items:stretch;background-color:#fff;padding:30px;z-index:17;border-radius:30px}._3QlnfvEVBFGU3ABxUfop_v>*{margin-bottom:15px}._3QlnfvEVBFGU3ABxUfop_v>*:last-child{margin-bottom:0}._3QlnfvEVBFGU3ABxUfop_v ._3BBTY_ozcHOV-TNqd9QaSl{margin-top:2px;font-size:18px;flex-grow:2;font-weight:bold;margin-bottom:20px}._3QlnfvEVBFGU3ABxUfop_v .USzbq00kkK23DnAjtHf-k{font-size:16px;line-height:22px;font-weight:normal}._3QlnfvEVBFGU3ABxUfop_v .USzbq00kkK23DnAjtHf-k a{color:inherit;text-decoration:underline}._3QlnfvEVBFGU3ABxUfop_v .JDfHSFz1i-XL7j59HS-fb{background-color:#f5f5f5;line-height:22px;padding:15px;border-radius:10px;color:#959392}", ""]);
// Exports
exports.locals = {
	"GenericPopover": "_3QlnfvEVBFGU3ABxUfop_v",
	"Title": "_3BBTY_ozcHOV-TNqd9QaSl",
	"Subtitle": "USzbq00kkK23DnAjtHf-k",
	"Auxiliar": "JDfHSFz1i-XL7j59HS-fb"
};
module.exports = exports;
