//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import axios from 'axios';
const CancelToken = axios.CancelToken;
//------------------------------------------------------------------------------
// Client ----------------------------------------------------------------------
import client from '@api/client';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import { StateIndicator } from '@cmp/common';
//------------------------------------------------------------------------------
// Methods ---------------------------------------------------------------------
class GET extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: null,
      error: null,
      headers: null,
    };
  }

  componentDidMount() {
    const { skip } = this.props;

    if (!skip) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps) {
    const { skip: prevSkip } = prevProps;
    const { skip } = this.props;

    if (!skip && skip !== prevSkip) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel();
    }
  }

  loadData() {
    let that = this;
    const { endpoint, params, customUrl, onComplete } = this.props;

    if (this.source) {
      this.source.cancel();
    }

    this.setState({ loading: true, error: null });
    this.source = CancelToken.source();

    client
      .get(customUrl || endpoint.url, {
        cancelToken: this.source && this.source.token,
        params,
      })
      .then(({ data, headers }) => {
        that.setState({ data, headers, loading: false, error: null });
        if (onComplete) onComplete(data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          that.setState({ error, loading: false });
        }
      });
  }

  render() {
    const {
      children,
      stateIndicatorComponent: StateIndicatorComponent = StateIndicator,
    } = this.props;
    const { loading, error, data, headers } = this.state;
    const refetch = () => this.loadData();

    if (StateIndicatorComponent && (loading || error)) {
      return <StateIndicatorComponent loading={loading} error={error} />;
    } else {
      return children({ loading, error, data, headers, refetch });
    }
  }
}

export default GET;
