//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { patientSatisfactionImageUrl } from '@cmp/images';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PatientSatisfaction extends React.Component {
  render() {
    const { t, i18n } = this.props;
    const componentClasses = classNames(styles.PatientSatisfaction);

    return (
      <div className={componentClasses}>
        <div
          className={styles.SectionImage}
          style={{
            backgroundImage: `url(${patientSatisfactionImageUrl(i18n)})`,
          }}
        ></div>
        <div className={styles.Subcontent}>
          <h1 className={styles.Title}>
            {t('landing_EmpowerPatientsToFollow')}
          </h1>
          <span className={styles.Subtitle}>
            {t('landing_EmpowerPatientsToFollowSubtitle')}
          </span>
          <Button
            to="/signup"
            className={styles.Button}
            style={ButtonStyle.Secondary}
          >
            {t('landing_GetStarted')}
          </Button>
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(PatientSatisfaction);
