//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonStyle } from '@cmp/common';
import { ChevronLeftIcon, ChevronRightIcon } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------
import { withTranslation } from 'react-i18next';
import { getValue } from '@helpers/localStorage';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Customers as CustomerListRequest } from '@api/endpoints/get/customers';
import { History as RecommendationHistoryRequest } from '@api/endpoints/get/recommendation';
import { t } from 'i18next';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { refCode } from '@helpers/constants/localStorage';
const PatientListPageCount = 12;
// React Class -----------------------------------------------------------------
class PatientList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
    };
  }

  headerComponent() {
    return (
      <div className={styles.Header}>
        <span className={styles.HeaderItem}>Name</span>
        <span className={styles.HeaderItem}>Email Address</span>
        <span className={styles.HeaderItem}></span>
      </div>
    );
  }

  itemComponent({ id, name, email, refcode }) {
    const { onClick, onOrderHistoryClick } = this.props;

    return (
      <div className={styles.Item} key={id}>
        <span className={styles.PatientName}>{name || '-'}</span>
        <span className={styles.EmailAddress}>{email || '-'}</span>
        <div className={styles.Buttons}>
          <Button
            style={ButtonStyle.Secondary}
            className={styles.Button}
            onClick={() => onClick({ id, name, email, refcode })}
          >
            {t('patientDirectory_MakeRecommendation')}
          </Button>
          <Button
            style={ButtonStyle.SecondaryGreen}
            className={styles.Button}
            onClick={() => onOrderHistoryClick({ id, name, email, refcode })}
          >
            {t('patientDirectory_OrderHistory')}
          </Button>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    // Ideally the pagination component wouldn't be controlled by History,
    // and all the changes/refetches would come from the top

    const { searchValue, sortBy } = this.props;

    if (searchValue !== prevProps.searchValue || sortBy !== prevProps.sortBy) {
      this.setState({ currentPage: 0 }, this.refetch);
    }
  }

  params() {
    const { currentPage } = this.state;
    const { searchValue, sortBy } = this.props;

    const params = {
      // ReactPaginate has a zero-based page count, while our API doesn't
      page: currentPage + 1,
      limit: PatientListPageCount,
      sentOnly: true,
    };

    if (searchValue && searchValue.length > 0) {
      params.search = searchValue;
    }

    if (sortBy && sortBy.length > 0) {
      params.sort = sortBy;
    }

    const referralCode = getValue(refCode);

    if (referralCode) {
      params.refcode = referralCode;
    }

    return params;
  }

  render() {
    const { currentPage } = this.state;
    const { className, onChangeRefetch, t } = this.props;

    const componentClasses = classNames(styles.PatientList, {
      [className]: className,
    });

    return (
      <RecommendationHistoryRequest
        params={{ sort: 'consumerName ASC', sentOnly: true }}
      >
        {({ data, refetch }) => {
          this.refetch = refetch;
          onChangeRefetch(refetch);

          const { referrals, total } = data || {};
          if (!data) return '';

          return (
            <CustomerListRequest params={this.params()}>
              {({ data, refetch }) => {
                this.refetch = refetch;
                onChangeRefetch(refetch);

                let { customers, total } = data || {};
                if (!data) return '';
                let i = 1;
                referrals.forEach((referral) => {
                  if (
                    customers.filter((e) => e.email === referral.consumerEmail)
                      .length <= 0
                  ) {
                    customers.push({
                      email: referral.consumerEmail,
                      firstName: referral.consumerName.split(' ')[0],
                      lastName: referral.consumerName.split(' ')[1],
                      id: `referralID${i}`,
                    });
                    i++;
                  }
                });

                customers = customers.sort((a, b) =>
                  a.lastName.localeCompare(b.lastName)
                );

                return (
                  <div className={componentClasses}>
                    <div className={styles.Table}>
                      {customers.length > 0 && this.headerComponent()}
                      {customers.map((customer) => {
                        return this.itemComponent({
                          id: customer.id,
                          name: `${customer.firstName} ${customer.lastName}`,
                          email: customer.email,
                          refcode: customer.refcode,
                        });
                      })}
                    </div>
                    {total > 1 && (
                      <ReactPaginate
                        // Basic set up
                        initialPage={currentPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        previousLabel={
                          <ChevronLeftIcon alt="" aria-label="Previous" />
                        }
                        nextLabel={
                          <ChevronRightIcon alt="" aria-label="Next" />
                        }
                        // Page
                        forcePage={currentPage}
                        onPageChange={({ selected }) => {
                          if (currentPage !== selected) {
                            this.setState({ currentPage: selected }, refetch);
                          }
                        }}
                        pageCount={Math.ceil(total / PatientListPageCount)}
                        // Styling
                        containerClassName={styles.Pagination}
                        activeLinkClassName={styles.PageActive}
                      />
                    )}
                  </div>
                );
              }}
            </CustomerListRequest>
          );
        }}
      </RecommendationHistoryRequest>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(PatientList);
