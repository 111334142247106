// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1aP_ActwTEmPLbEcymWMq-{display:flex;align-items:center;cursor:pointer}._1aP_ActwTEmPLbEcymWMq- ._3uygQ9PfbLKPw3UfOL6CdF{font-weight:bold;font-size:12px}._1aP_ActwTEmPLbEcymWMq- ._3uygQ9PfbLKPw3UfOL6CdF a{color:#e23023;text-decoration:underline}._1aP_ActwTEmPLbEcymWMq- .TUFl4jLU9u78EiGrPq8mM{display:flex;align-items:center;justify-content:center;min-width:16px;max-width:16px;min-height:16px;max-height:16px;background:#fff;border:2px solid rgba(214,214,214,.5);margin-right:9px;border-radius:4px}._1aP_ActwTEmPLbEcymWMq-._10dKiXuKHr6Fn5ZyEBNTnc .TUFl4jLU9u78EiGrPq8mM{border:none;background-color:#e23023}", ""]);
// Exports
exports.locals = {
	"Checkbox": "_1aP_ActwTEmPLbEcymWMq-",
	"Text": "_3uygQ9PfbLKPw3UfOL6CdF",
	"CheckboxInput": "TUFl4jLU9u78EiGrPq8mM",
	"Checked": "_10dKiXuKHr6Fn5ZyEBNTnc"
};
module.exports = exports;
