// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2jhf2fYX2T4dgO-VheP-hC{margin:0 30px;padding:50px 0 92px 0}._2jhf2fYX2T4dgO-VheP-hC .KrNg-mnukIERYOU0oWogr>._1E54wayL-JLPknz1k78qHL{color:#e23023;font-size:16px}._2jhf2fYX2T4dgO-VheP-hC .KrNg-mnukIERYOU0oWogr>._2hi0e6qGJZ79V-vsZTpzRl{color:#333;font-size:40px;font-weight:bold}._2jhf2fYX2T4dgO-VheP-hC .KrNg-mnukIERYOU0oWogr>._3vJeI8-NplVPwc-2ucEk10{color:#959392;font-weight:normal;font-size:16px}._2jhf2fYX2T4dgO-VheP-hC .KrNg-mnukIERYOU0oWogr ._8k9OrPcCMHIPP6aYEYq2Z{margin-top:23px}@media only screen and (max-width: 955px){._2jhf2fYX2T4dgO-VheP-hC .KrNg-mnukIERYOU0oWogr>._2hi0e6qGJZ79V-vsZTpzRl{font-size:32px}}@media only screen and (max-width: 320px){._2jhf2fYX2T4dgO-VheP-hC .KrNg-mnukIERYOU0oWogr>._2hi0e6qGJZ79V-vsZTpzRl{font-size:24px}}._2jhf2fYX2T4dgO-VheP-hC .KrNg-mnukIERYOU0oWogr{display:flex;flex-direction:column;align-items:center}._2jhf2fYX2T4dgO-VheP-hC .KrNg-mnukIERYOU0oWogr ._2hi0e6qGJZ79V-vsZTpzRl{margin-bottom:46px}@media only screen and (max-width: 955px){._2jhf2fYX2T4dgO-VheP-hC{padding:26px 40px}._2jhf2fYX2T4dgO-VheP-hC .KrNg-mnukIERYOU0oWogr ._2hi0e6qGJZ79V-vsZTpzRl,._2jhf2fYX2T4dgO-VheP-hC .KrNg-mnukIERYOU0oWogr ._3vJeI8-NplVPwc-2ucEk10{text-align:center}}", ""]);
// Exports
exports.locals = {
	"HowItWorks": "_2jhf2fYX2T4dgO-VheP-hC",
	"Subcontent": "KrNg-mnukIERYOU0oWogr",
	"Overhead": "_1E54wayL-JLPknz1k78qHL",
	"Title": "_2hi0e6qGJZ79V-vsZTpzRl",
	"Subtitle": "_3vJeI8-NplVPwc-2ucEk10",
	"Button": "_8k9OrPcCMHIPP6aYEYq2Z"
};
module.exports = exports;
