// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cLY3eEFLeenYmtPO1iMpR{display:flex;justify-content:center;align-items:flex-start;background-color:#fafafb;height:100%}.cLY3eEFLeenYmtPO1iMpR ._10wDRnO95KRTZv-26tpXUO{margin:100px 0 20px 0}.cLY3eEFLeenYmtPO1iMpR ._10wDRnO95KRTZv-26tpXUO ._1F1MGdAH35SNeMNReL-2tA{font-weight:bold;font-size:14px;color:#e23023;margin-top:12px}@media only screen and (max-width: 955px){.cLY3eEFLeenYmtPO1iMpR ._10wDRnO95KRTZv-26tpXUO{width:90%}}", ""]);
// Exports
exports.locals = {
	"ForgotPassword": "cLY3eEFLeenYmtPO1iMpR",
	"Form": "_10wDRnO95KRTZv-26tpXUO",
	"GoBack": "_1F1MGdAH35SNeMNReL-2tA"
};
module.exports = exports;
