//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import FocusTrap from 'focus-trap-react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Popover, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class GenericPopover extends React.Component {
  constructor(props) {
    super(props);
    this.mountTrap = this.mountTrap.bind(this);
    this.unmountTrap = this.unmountTrap.bind(this);
  }
  componentDidMount() {
    this.mountTrap();
  }
  mountTrap() {
    this.setState({ activeTrap: true });
  }
  unmountTrap() {
    this.setState({ activeTrap: false });
  }

  render() {
    const {
      history,
      location,
      title,
      subtitle,
      auxiliar,
      visible,
      action,
      to,
      onClick,
      onClose,
      className,
    } = this.props;
    const componentClasses = classNames(styles.GenericPopover, className);

    return visible ? (
      <FocusTrap>
        <Popover.Base
          className={componentClasses}
          onClose={onClose || (() => history.push(location.state.from))}
        >
          {title && <h2 className={styles.Title}>{title}</h2>}
          {subtitle && <span className={styles.Subtitle}>{subtitle}</span>}
          {auxiliar && <span className={styles.Auxiliar}>{auxiliar}</span>}
        </Popover.Base>
      </FocusTrap>
    ) : null;
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(GenericPopover);
