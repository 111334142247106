//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonStyle } from '@cmp/common';
import { Stripe as StripeIcon } from '@cmp/common/icons';
import { CurrencyPrefix } from '@helpers/constants/currency';
import { Modal } from '@cmp/common';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { StripeConnectURL } from '@helpers/stripe';
import { withAuthUser } from '@helpers/auth';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { createLoginLink } from '@api/endpoints/post/providers';
import { amazonPay } from '@api/endpoints/post/payments';
import { Trans, withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class AmzPayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingLink: false,
      successModalDisplayed: false,
      failureModalDisplayed: false,
      userProfile: null,
    };

    this.onLoad = this.onLoad.bind(this);
  }

  onLoad() {
    let that = this;
    const { __authUser } = this.props;
  }

  componentDidUpdate(prevProps) {
    const { userProfile } = this.state;
    this.loadAmzSdk();
    if (userProfile) {
      this.postAmazonData();
      this.amazonLogout();
      this.props.providerCall();
    }
  }

  handleAuth() {
    const formattedBalance = `${CurrencyPrefix.USD}${this.props.balance}`;
    if (formattedBalance === '$0') return;
    document.getElementById('LoginWithAmazon').onclick = function () {
      let options = {};
      options.scope = 'profile';
      options.scope_data = {
        profile: { essential: false },
      };
      amazon.Login.authorize(options, window.location.href);
      return false;
    };
    document.getElementById('LoginWithAmazon').onkeypress = function () {
      let options = {};
      options.scope = 'profile';
      options.scope_data = {
        profile: { essential: false },
      };
      amazon.Login.authorize(options, window.location.href);
      return false;
    };
  }

  getAmazonProfile() {
    const token = amazon.Login.retrieveToken();
    if (amazon && token) {
      amazon.Login.retrieveProfile(token.access_token, (response) =>
        this.setState({ userProfile: response.profile })
      );
    } else {
      this.setState({ userProfile: null });
    }
  }

  loadAmzSdk() {
    const that = this;
    window.onAmazonLoginReady = function () {
      amazon.Login.setClientId(window._env.REACT_APP_AMZ_CLIENT_ID);
      that.handleAuth();
      that.getAmazonProfile();
    };
    (function (d) {
      let a = d.createElement('script');
      a.type = 'text/javascript';
      a.async = true;
      a.id = 'amazon-login-sdk';
      a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
      d.getElementById('amazon-root').appendChild(a);
    })(document);
  }

  amazonLogout() {
    amazon.Login.logout();
    this.getAmazonProfile();
  }

  async postAmazonData() {
    const { userProfile } = this.state;
    if (!userProfile?.CustomerId) {
      return;
    }
    // sandbox account
    //await amazonPay('amzn1.account.AFEM4VZRQQMBAAMVQEP3BPBH7OYQ');
    // prod env
    //await amazonPay(userProfile?.CustomerId);
    try {
      await amazonPay(userProfile?.CustomerId);
      this.setState({ successModalDisplayed: true });
    } catch (error) {
      this.setState({ failureModalDisplayed: true });
    }
  }

  render() {
    const { className, t } = this.props;
    const { style } = this.props;
    const {
      loadingLink,
      successModalDisplayed,
      failureModalDisplayed,
    } = this.state;
    const componentClasses = classNames(styles.amzPayout, {
      [className]: className,
    });
    const formattedBalance = `${CurrencyPrefix.USD}${this.props.balance}`;

    if (
      !window._env.REACT_APP_AGCOD_PAYOUT_ENABLE ||
      window._env.REACT_APP_AGCOD_PAYOUT_ENABLE === 'false'
    ) {
      return null;
    }

    return (
      <>
        {successModalDisplayed ? (
          <Modal.Generic
            title={t('dashboard_BalanceRedeemed')}
            subtitle={t(`dashboard_BalanceRedeemedSubtitle`)}
            onClose={() => this.setState({ successModalDisplayed: false })}
          ></Modal.Generic>
        ) : (
          <></>
        )}
        {failureModalDisplayed ? (
          <Modal.Generic
            title={t('dashboard_TransferFailed')}
            subtitle={[
              <Trans i18nKey="dashboard_TransferFailedSubtitle">
                <Link
                  target="_blank"
                  className={styles.link}
                  to="/help#help_AmazonDigitalGC"
                ></Link>
              </Trans>,
            ]}
            onClose={() => this.setState({ failureModalDisplayed: false })}
          ></Modal.Generic>
        ) : (
          <></>
        )}
        {formattedBalance !== '$0' ? (
          <a id="LoginWithAmazon" tabIndex="0">
            <img
              border="0"
              alt={t('dashboard_CashOutBalance')}
              style={{ cursor: 'pointer' }}
              src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
              width="156"
              height="32"
              onLoad={() => this.handleAuth()}
            />
          </a>
        ) : null}
      </>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withTranslation()(AmzPayout));
