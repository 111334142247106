//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Password, Landing, SignIn, SignUp } from './client';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Welcome extends React.Component {
  render() {
    const componentClasses = classNames(styles.Welcome);

    return (
      <div className={componentClasses}>
        <Switch>
          <Route path="/password" component={Password} />
          <Route path="/login" component={SignIn} />
          <Route path="/signup" component={SignUp} />
          <Route exact path="/" component={Landing} />
          <Redirect from="*" to="/" />
        </Switch>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Welcome;
