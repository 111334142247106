//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// React Dates -----------------------------------------------------------------
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '@style/globals/_react_dates_overrides.scss';
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from 'react-dates';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import {
  Button,
  ButtonStyle,
  Select,
  FilterSelector,
  SearchInput,
  PayoutButton,
  AmzPayout,
} from '@cmp/common';
import {
  RecommendationHistory,
  Overview,
  AmazonBalance,
  StripeBalance,
} from './components';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { searchIconUrl } from '@cmp/images';
import { dollarSignIconUrl, giftCardIconUrl } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { withReferral } from '@helpers/referrals';
import { ReferralSortingOptions } from '@helpers/constants/referral';
import { withAuthUser } from '@helpers/auth';
import { findPayoutOption } from '@helpers/constants/user';
import { loadProviders } from '@api/endpoints/get/providers';
import BalanceComponent from './components/balance-component';
import { withTranslation } from 'react-i18next';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Recommendations extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      searchValue: '',
      sortBy: ReferralSortingOptions(t).find((option) => option.default),
      startDate: null,
      endDate: null,
      focusedInput: null,
      providerData: {
        amzBalance: 0,
        balance: 0,
        purchaseCount: 0,
        recommendationCount: 0,
        totalEarnings: 0,
      },
    };
  }

  componentDidMount() {
    const { history, location } = this.props;
    const { state } = location || {};
    const { from, fromPatientDirectory } = state || {};
    const { id, name, email, refcode } = fromPatientDirectory || {};

    if (from?.pathname === '/patient-directory') {
      history.push({
        pathname: `/recommendation/patient`,
        state: {
          fromReferral: {
            consumerName: name,
            consumerEmail: email,
          },
          from: location,
          modal: true,
        },
      });
    } else {
      this.fetchProviders();
    }
  }

  async fetchProviders() {
    const { startDate, endDate } = this.state;
    const shouldSendDate = endDate && startDate;
    const params = shouldSendDate
      ? {
          startTime: startDate.toISOString(),
          endTime: endDate.toISOString(),
        }
      : null;

    this.historyRefetch();
    const { data } = await loadProviders(params);
    this.setState({ providerData: data });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.startDate !== prevState.startDate ||
      this.state.endDate !== prevState.endDate
    ) {
      this.fetchProviders();
    }
  }

  dashboardAuxiliarComponent() {
    const { startDate, endDate, focusedInput } = this.state;
    const { __referral, t } = this.props;

    return (
      <div className={styles.AuxiliarComponent}>
        <div id="amazon-root"></div>
        <div className={styles.DatePickers}>
          <FilterSelector
            title={t('dashboard_DateRange')}
            aria-labelledby={t('dashboard_DateRange')}
            custom={
              <DateRangePicker
                startDate={startDate}
                startDateId="start_date_id"
                endDate={endDate}
                isOutsideRange={(date) => date.isAfter(moment(), 'day')}
                endDateId="end_date_id"
                onDatesChange={({ startDate, endDate }) =>
                  this.setState({
                    startDate: startDate && startDate.startOf('day'),
                    endDate: endDate && endDate.endOf('day'),
                  })
                }
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                }
                noBorder={true}
                small={true}
                hideKeyboardShortcutsPanel={true}
              />
            }
          />
        </div>
        {!__referral ||
          !__referral.items ||
          (__referral.items.length <= 0 && (
            <Button
              style={ButtonStyle.Secondary}
              className={styles.CreateRecommendation}
              to="/"
            >
              {t('dashboard_CreateRecommendation')}
            </Button>
          ))}
      </div>
    );
  }

  historyAuxiliarComponent() {
    const { __authUser, t, i18n } = this.props;
    const { sortBy, searchValue } = this.state;
    const componentClasses = classNames(
      styles.AuxiliarComponent,
      styles.DashboardAuxiliarComponent
    );

    const { data } = __authUser || {};
    const foundPayoutOption = data && findPayoutOption(data.payout_option);
    const shouldPresentPayoutButton =
      foundPayoutOption && foundPayoutOption.requireStripeAccount;

    return (
      <div className={componentClasses}>
        <FilterSelector
          title={t('dashboard_SortBy')}
          aria-label={t('dashboard_SortBy')}
          options={ReferralSortingOptions(t)}
          value={sortBy}
          onFocus={() => {
            this.setState({ focusedInput: null });
          }}
          onChange={(sortBy) => this.setState({ sortBy }, this.historyRefetch)}
        />

        <SearchInput
          value={searchValue}
          placeholder={t('dashboard_SearchFor')}
          icon={searchIconUrl(i18n)}
          onChange={(searchValue) => {
            this.setState({ searchValue }, () => {
              if (this.searchTimeout) clearTimeout(this.searchTimeout);
              this.searchTimeout = setTimeout(
                this.historyRefetch,
                searchValue.length > 0 ? 600 : 0
              );
            });
          }}
        />
        {/* {shouldPresentPayoutButton && (
          <PayoutButton className={styles.PayoutButton} />
        )}

        <AmzPayout className={styles.AmzPayout} /> */}
      </div>
    );
  }

  headerComponent(title, auxiliar) {
    return (
      <div className={styles.Header}>
        {title}
        {auxiliar}
      </div>
    );
  }

  render() {
    const {
      searchValue,
      sortBy,
      startDate,
      endDate,
      providerData,
    } = this.state;
    const {
      totalEarnings,
      recommendationCount,
      purchaseCount,
      balance,
      amzBalance,
    } = providerData;

    const { history, location } = this.props;
    const componentClasses = classNames(styles.All);

    const { __authUser, t, i18n } = this.props;
    const { data } = __authUser || {};
    const foundPayoutOption = data && findPayoutOption(data.payout_option);
    const shouldPresentPayoutButton =
      foundPayoutOption && foundPayoutOption.requireStripeAccount;
    const AMZFeatureFlag = window._env.REACT_APP_AGCOD_PAYOUT_ENABLE === 'true';
    // Displaying both Amazon and Stripe regardless of payout selection and if AMZFeatureFlag is set to true.
    let showAmazon = AMZFeatureFlag;
    let showStripe = true;

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('dashboard_Dashboard')}</title>
        </Helmet>

        <div className={styles.Section}>
          {this.headerComponent(
            <h2 className={styles.Title}>{t('dashboard_Dashboard')}</h2>,
            this.dashboardAuxiliarComponent()
          )}
          <Overview
            totalEarnings={totalEarnings}
            recommendationCount={recommendationCount}
            purchaseCount={purchaseCount}
          />
        </div>
        <div className={styles.Section}>
          {this.headerComponent(
            <h2 className={styles.Subtitle}>{t('dashboard_Balances')}</h2>
          )}
          <div className={styles.UnpaidBalanceContainers}>
            {showStripe ? (
              <BalanceComponent
                icon={dollarSignIconUrl(i18n)}
                title={t('dashboard_DirectDeposit')}
                balance={balance}
                color="#A51C1F"
                description={t('dashboard_DirectDepositDescription')}
              />
            ) : null}
            {showAmazon && AMZFeatureFlag ? (
              <BalanceComponent
                color="#fd6a28"
                icon={giftCardIconUrl(i18n)}
                balance={amzBalance === '0.00' ? '0.00' : amzBalance}
                title="Amazon"
                description={
                  amzBalance === '0.00' || String(amzBalance) === '0.00'
                    ? ''
                    : t('dashboard_RedeemAsAmz')
                }
              >
                <AmzPayout
                  providerCall={() => this.fetchProviders()}
                  balance={amzBalance === '0.00' ? '0.00' : amzBalance}
                />
              </BalanceComponent>
            ) : null}
          </div>
        </div>

        <div className={styles.Section}>
          {this.headerComponent(
            <h2 className={styles.Subtitle}>
              {t('dashboard_RecommendationHistory')}
            </h2>,
            this.historyAuxiliarComponent()
          )}
          <RecommendationHistory
            searchValue={searchValue}
            sortBy={sortBy.value}
            onChangeRefetch={(refetch) => (this.historyRefetch = refetch)}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onClick={(id) => {
              history.push({
                pathname: `/recommendation/${id}`,
                state: { from: location, modal: true },
              });
            }}
          />
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withReferral(withTranslation()(Recommendations)));
