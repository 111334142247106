//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonStyle } from '@cmp/common';
import { Stripe as StripeIcon } from '@cmp/common/icons';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { StripeConnectURL } from '@helpers/stripe';
import { withAuthUser } from '@helpers/auth';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { createLoginLink } from '@api/endpoints/post/providers';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PayoutButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingLink: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    let that = this;
    const { __authUser } = this.props;

    this.setState({ loadingLink: true });

    createLoginLink()
      .then(({ data }) => {
        if (!data) return;

        const { url } = data;
        window.location = url;
      })
      .finally(() => {
        that.setState({ loadingLink: false });
      });
  }

  render() {
    const { className, t } = this.props;
    const { style } = this.props;
    const { loadingLink } = this.state;
    const componentClasses = classNames(styles.PayoutButton, {
      [className]: className,
    });

    return (
      <Button
        className={componentClasses}
        style={style ? style : ButtonStyle.Secondary}
        loading={loadingLink}
        onClick={this.onClick}
      >
        {t('profile_PayoutDetails')}
      </Button>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withTranslation()(PayoutButton));
