export default [
  {
    titleI18nKey: 'help_title_text',
    questions: [
      {
        titleI18nKey: 'help_WhatIs',
        answerI18nKey: 'help_WhatIsAnswer',
      },
      {
        titleI18nKey: 'help_HowDoesItWork',
        answerI18nKey: 'help_HowDoesItWorkAnswer',
      },
      {
        titleI18nKey: 'help_HowCanISendRecommendation',
        answerI18nKey: 'help_HowCanISendRecommendationAnswer',
      },
      {
        titleI18nKey: 'help_HowCanIShareCode',
        answerI18nKey: 'help_HowCanIShareCodeAnswer',
      },
      {
        titleI18nKey: 'help_HowDoITrackRecommendations',
        answerI18nKey: 'help_HowDoITrackRecommendationsAnswer',
      },
      {
        titleI18nKey: 'help_HowCanIReceiveCommisions',
        answerI18nKey: 'help_HowCanIReceiveCommisionsAnswer',
      },
      {
        titleI18nKey: 'help_HowFrequentlyCanIChange',
        answerI18nKey: 'help_HowFrequentlyCanIChangeAnswer',
      },
      {
        titleI18nKey: 'help_WhatDoINeedToSignUp',
        answerI18nKey: 'help_WhatDoINeedToSignUpAnswer',
      },
      {
        titleI18nKey: 'help_HowCanIAccess',
        answerI18nKey: 'help_HowCanIAccessAnswer',
      },
      {
        titleI18nKey: 'help_WhoDoICallIfQuestions',
        answerI18nKey: 'help_WhoDoICallIfQuestionsAnswer',
      },
      {
        titleI18nKey: 'help_HowCanICreateShortcut',
        answerI18nKey: 'help_HowCanICreateShortcutAnswer',
      },
    ],
  },
  {
    titleI18nKey: 'Account',
    questions: [
      {
        titleI18nKey: 'help_HowCanICreateAccount',
        answerI18nKey: 'help_HowCanICreateAccountAnswer',
      },
      {
        titleI18nKey: 'help_HowDoIResetPassword',
        answerI18nKey: 'help_HowDoIResetPasswordAnswer',
      },
      {
        titleI18nKey: 'help_HowDoIChangeAccountInfo',
        answerI18nKey: 'help_HowDoIChangeAccountInfoAnswer',
      },
    ],
  },
  {
    titleI18nKey: 'Stripe',
    questions: [
      {
        titleI18nKey: 'help_WhoIsStripe',
        answerI18nKey: 'help_WhoIsStripeAnswer',
      },
      {
        titleI18nKey: 'help_WhatDoINeedToCreateStripeAccount',
        answerI18nKey: 'help_WhatDoINeedToCreateStripeAccountAnswer',
      },
      {
        titleI18nKey: 'help_HowDoIReceiveEarnings',
        answerI18nKey: 'help_HowDoIReceiveEarningsAnswer',
      },
      {
        titleI18nKey: 'help_HowCanIReportTaxes',
        answerI18nKey: 'help_HowCanIReportTaxesAnswer',
      },
      {
        titleI18nKey: 'help_WhatShouldIDoProblemPayout',
        answerI18nKey: 'help_WhatShouldIDoProblemPayoutAnswer',
      },
      {
        titleI18nKey: 'help_WhereCanIGetHelpStripeAccount',
        answerI18nKey: 'help_WhereCanIGetHelpStripeAccountAnswer',
      },
    ],
  },
  {
    titleI18nKey: 'help_AmazonDigitalGC',
    questions: [
      {
        titleI18nKey: 'help_HowCanITransferToAmazon',
        answerI18nKey: 'help_HowCanITransferToAmazonAnswer',
      },
      {
        titleI18nKey: 'help_HowCanIReportTaxesAmazon',
        answerI18nKey: 'help_HowCanIReportTaxesAmazonAnswer',
      },
      {
        titleI18nKey: 'help_WhatShouldIDoIfNeedHelp',
        answerI18nKey: 'help_WhatShouldIDoIfNeedHelpAnswer',
      },
    ],
  },
  {
    titleI18nKey: 'help_YourPatientsQuestions',
    questions: [
      {
        titleI18nKey: 'help_CanPatientChangeOrder',
        answerI18nKey: 'help_CanPatientChangeOrderAnswer',
      },
      {
        titleI18nKey: 'help_HowDoPatientsTrackStatus',
        answerI18nKey: 'help_HowDoPatientsTrackStatusAnswer',
      },
      {
        titleI18nKey: 'help_WhatPatientDoIfProblem',
        answerI18nKey: 'help_WhatPatientDoIfProblemAnswer',
      },
      {
        titleI18nKey: 'help_WhatAreDirectShippingOptions',
        answerI18nKey: 'help_WhatAreDirectShippingOptionsAnswer',
      },
      {
        titleI18nKey: 'help_DoesCDShipInternationally',
        answerI18nKey: 'help_DoesCDShipInternationallyAnswer',
      },
      {
        titleI18nKey: 'help_HowDoPatientsGetFreeShipping',
        answerI18nKey: 'help_HowDoPatientsGetFreeShippingAnswer',
      },
      {
        titleI18nKey: 'help_WhatIsCDReturnPolicy',
        answerI18nKey: 'help_WhatIsCDReturnPolicyAnswer',
      },
      {
        titleI18nKey: 'help_HowDoesYourPatientReturnOrder',
        answerI18nKey: 'help_HowDoesYourPatientReturnOrderAnswer',
      },
      {
        titleI18nKey: 'help_HowCanPatientCreateAccount',
        answerI18nKey: 'help_HowCanPatientCreateAccountAnswer',
      },
      {
        titleI18nKey: 'help_CanPatientCheckoutWithoutAccount',
        answerI18nKey: 'help_CanPatientCheckoutWithoutAccountAnswer',
      },
      {
        titleI18nKey: 'help_WhatPaymentOptionsPatientsUse',
        answerI18nKey: 'help_WhatPaymentOptionsPatientsUseAnswer',
      },
      {
        titleI18nKey: 'help_WhatShouldPatientDoIfLinkNotWorking',
        answerI18nKey: 'help_WhatShouldPatientDoIfLinkNotWorkingAnswer',
      },
    ],
  },
  {
    titleI18nKey: 'help_SocialResponsibility',
    questions: [
      {
        titleI18nKey: 'help_SupporEducation',
        answerI18nKey: 'help_SupporEducationAnswer',
      },
    ],
  },
];
