//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonStyle, AddItem as AddItemComponent } from '@cmp/common';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { logoPlaceholderUrl } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { shopifyFormatter } from '@helpers/formatter';
import { CurrencyPrefix } from '@helpers/constants/currency';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = { flashingItems: [] };
    this.itemComponent = this.itemComponent.bind(this);
  }

  pricingComponent(formattedProduct) {
    if (!formattedProduct) return '';

    const hasDiscount = formattedProduct.hasDiscount();
    const fullPrice = formattedProduct.fullPrice(CurrencyPrefix.USD);
    const discountedPrice = formattedProduct.discountedPrice(
      CurrencyPrefix.USD
    );

    if (!hasDiscount && !fullPrice) return '';

    if (!hasDiscount) {
      return <span className={styles.Amount}>{fullPrice}</span>;
    }

    return (
      <>
        {formattedProduct.hasDiscount() && (
          <span className={styles.Amount}>
            {formattedProduct.discountedPrice(CurrencyPrefix.USD)}
          </span>
        )}
        {formattedProduct.fullPrice(CurrencyPrefix.USD) && (
          <span className={styles.OriginalAmount}>
            {formattedProduct.fullPrice(CurrencyPrefix.USD)}
          </span>
        )}
      </>
    );
  }

  itemComponent(item) {
    let that = this;
    const { flashingItems } = this.state;
    const { onAdd, i18n } = this.props;
    const formattedProduct = shopifyFormatter.product(item);

    const componentClasses = classNames(styles.Item, {
      [styles.Flashing]: flashingItems.indexOf(item.id) > -1,
    });

    return (
      <div className={componentClasses} key={item.id}>
        <Link className={styles.Info} to={`/product/${item.id}`}>
          <div
            className={styles.Image}
            style={{
              backgroundImage: `url(${
                (item.image && item.image.src) || logoPlaceholderUrl(i18n)
              })`,
            }}
          ></div>
          <p className={styles.Name}>{item.title}</p>
        </Link>

        <div className={styles.Info}>
          <div className={styles.Pricing}>
            {this.pricingComponent(formattedProduct)}
          </div>
        </div>
        <AddItemComponent product={item} className={styles.AddItem} />
      </div>
    );
  }

  render() {
    const { products, className, t } = this.props;
    if (!products?.length)
      return <p className={styles.NoResultsText}>{t('NoResultsFound')}</p>;

    const componentClasses = classNames(styles.List, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>{products.map(this.itemComponent)}</div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(List);
