//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Badge extends React.Component {
  // to-do: make badge more generic (not only attached to recommendations)
  render() {
    const { status, className, t } = this.props;
    const componentClasses = classNames(
      styles.Badge,
      styles[status.className],
      {
        [className]: className,
      }
    );

    return <span className={componentClasses}>{t(status.i18nKey)}</span>;
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Badge);
