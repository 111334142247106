// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2g30SQ3L00e3e3fP-AukbE{height:100%}", ""]);
// Exports
exports.locals = {
	"Welcome": "_2g30SQ3L00e3e3fP-AukbE"
};
module.exports = exports;
