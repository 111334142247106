//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Custom ----------------------------------------------------------------------
import { getValue, storeValue, removeAll } from '@helpers/localStorage';
import {
  UserStorageKey,
  AuthorizationStorageKey,
} from '@helpers/constants/localStorage';
import { HeadersKey } from '@helpers/constants/api';
//------------------------------------------------------------------------------
// Exports ---------------------------------------------------------------------

export const withAuthUser = (WrappedComponent) => ({ children, ...props }) => (
  <WrappedComponent {...props} __authUser={getValue(UserStorageKey, true)}>
    {children}
  </WrappedComponent>
);

export const auth = (params = {}, skipRedirect, headers) => {
  const authUser = getValue(UserStorageKey, true) || {};

  const newAuthUser = {
    ...authUser,
    ...params,
  };

  if (Object.keys(newAuthUser).length > 0) {
    storeValue(UserStorageKey, JSON.stringify(newAuthUser));

    if (headers) {
      storeValue(
        AuthorizationStorageKey,
        headers[HeadersKey.Authorization.response]
      );
    }
  } else {
    removeAll();
  }

  if (!skipRedirect) {
    window.location = '/';
  }
};

export const deauth = async (skipRedirect) => {
  await gigya.accounts.logout();
  removeAll();
  if (!skipRedirect) window.location = '/';
};
