//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { lifelineIconUrl, flagIconUrl, purchasesIconUrl } from '@cmp/images';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Providers as ProvidersRequest } from '@api/endpoints/get/providers';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { CurrencyPrefix } from '@helpers/constants/currency';
import { withAuthUser } from '@helpers/auth';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Overview extends React.Component {
  itemComponent(title, value, icon, className) {
    const componentClasses = classNames(styles.Item, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <div className={styles.Header}>
          <img src={icon} className={styles.Icon} alt={title} />
          <span className={styles.Title}>{title}</span>
        </div>
        <span className={styles.Value}>{value}</span>
      </div>
    );
  }

  render() {
    const {
      className,
      totalEarnings = '0',
      recommendationCount = '0',
      purchaseCount = '0',
      t,
      i18n,
    } = this.props;
    const componentClasses = classNames(styles.History, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {this.itemComponent(
          t('dashboard_TotalEarnings'),
          CurrencyPrefix.USD +
            (Math.round(totalEarnings * 100) / 100).toFixed(2),
          lifelineIconUrl(i18n),
          styles.TotalEarnings
        )}
        {this.itemComponent(
          t('dashboard_Recommendations'),
          recommendationCount,
          flagIconUrl(i18n),
          styles.Recommendations
        )}
        {this.itemComponent(
          t('dashboard_Purchases'),
          purchaseCount,
          purchasesIconUrl(i18n),
          styles.Purchases
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withTranslation()(Overview));
