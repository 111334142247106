// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2QDnSuN42mhatQYcK5KETe{font-size:13px;color:red}", ""]);
// Exports
exports.locals = {
	"ErrorMessage": "_2QDnSuN42mhatQYcK5KETe"
};
module.exports = exports;
