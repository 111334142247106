//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import qs from 'query-string';
import client from '@api/client';
import GET from '@api/methods/get';
//------------------------------------------------------------------------------
// Endpoints -------------------------------------------------------------------
const Endpoints = {
  Customers: {
    url: '/referrals/patient-directory/customers',
  },
};
//------------------------------------------------------------------------------
// React Classes ---------------------------------------------------------------
export const Customers = ({ children, ...props }) => {
  return (
    <GET endpoint={Endpoints.Customers} {...props}>
      {children}
    </GET>
  );
};
//------------------------------------------------------------------------------
// Inline ----------------------------------------------------------------------
export const loadCustomers = (params) => {
  const stringifiedParams = qs.stringify(params, {
    encode: false,
    skipNull: true,
  });
  return client.get(`${Endpoints.Customers.url}?${stringifiedParams}`);
};
