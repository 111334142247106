// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1eN6gmIaqegIUfHYvCeEMZ{display:flex;align-items:center;justify-content:space-between;padding:12px 46px;font-size:14px;background-color:#fafafb;color:#595554;font-weight:normal}._1eN6gmIaqegIUfHYvCeEMZ ._1vF3V-q-L3cWJyhVutOZiE,._1eN6gmIaqegIUfHYvCeEMZ ._--XsM7baWBQ8iIuTPh-sx{font-size:inherit;color:inherit;font-weight:normal;text-transform:none}._1eN6gmIaqegIUfHYvCeEMZ ._2GMglg_D6TdvaQ7QuEmRQI{display:flex}._1eN6gmIaqegIUfHYvCeEMZ ._2GMglg_D6TdvaQ7QuEmRQI>*{margin-right:12px}._1eN6gmIaqegIUfHYvCeEMZ ._2GMglg_D6TdvaQ7QuEmRQI>*:last-child{margin-right:0}@media only screen and (max-width: 955px){._1eN6gmIaqegIUfHYvCeEMZ{flex-direction:column;text-align:center;padding:20px 12px 80px 12px}._1eN6gmIaqegIUfHYvCeEMZ>*{margin-bottom:18px}._1eN6gmIaqegIUfHYvCeEMZ>*:last-child{margin-bottom:0}._1eN6gmIaqegIUfHYvCeEMZ ._2GMglg_D6TdvaQ7QuEmRQI{flex-direction:column;align-items:center;text-align:center}._1eN6gmIaqegIUfHYvCeEMZ ._2GMglg_D6TdvaQ7QuEmRQI>*{margin-right:0}._1eN6gmIaqegIUfHYvCeEMZ ._2GMglg_D6TdvaQ7QuEmRQI>*:last-child{margin-right:0}._1eN6gmIaqegIUfHYvCeEMZ ._2GMglg_D6TdvaQ7QuEmRQI>*{margin-bottom:12px}._1eN6gmIaqegIUfHYvCeEMZ ._2GMglg_D6TdvaQ7QuEmRQI>*:last-child{margin-bottom:0}}", ""]);
// Exports
exports.locals = {
	"Footer": "_1eN6gmIaqegIUfHYvCeEMZ",
	"Disclosure": "_1vF3V-q-L3cWJyhVutOZiE",
	"Link": "_--XsM7baWBQ8iIuTPh-sx",
	"Docs": "_2GMglg_D6TdvaQ7QuEmRQI"
};
module.exports = exports;
