//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { logoPlaceholderUrl, PlayIcon } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { isTouchEnabled } from '@helpers/device';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Slideshow extends React.Component {
  constructor(props) {
    super(props);
    this.swiper = null;
    this.state = {
      currentImage: null,
      currentVideo: null,
      swiperDirection: window.innerWidth <= 576 ? 'horizontal' : 'vertical',
    };
  }

  componentDidMount() {
    this.setupCurrentImageIfNeeded();
    if (this.onFocus) {
      this.props.onMouseOver;
    }
    window.addEventListener('resize', () => this.handleResize());
  }

  componentWillUnmount() {
    window.addEventListener('resize', null);
  }

  handleResize() {
    if (
      window.innerWidth <= 576 &&
      this.state.swiperDirection !== 'horizontal'
    ) {
      this.setState({ swiperDirection: 'horizontal' }, () => {
        if (this.swiper) this.swiper.update();
      });
    }
    if (window.innerWidth > 576 && this.state.swiperDirection !== 'vertical') {
      this.setState({ swiperDirection: 'vertical' }, () => {
        if (this.swiper) this.swiper.update();
      });
    }
  }

  componentDidUpdate() {
    this.setupCurrentImageIfNeeded();
  }

  setupCurrentImageIfNeeded() {
    const { currentImage, currentVideo } = this.state;
    const { images } = this.props;

    if (!currentImage && !currentVideo && images.length > 0) {
      this.setState({ currentImage: images[0] });
    }
  }

  allImagesComponent() {
    const { videos, images, i18n, title } = this.props;
    const { swiperDirection } = this.state;
    if (!images) return '';

    return (
      <div className={styles.AllImages}>
        <Swiper
          spaceBetween={10}
          updateOnWindowResize
          style={{ height: '100%', width: '100%' }}
          direction={swiperDirection}
          slidesPerView={4}
          onSwiper={(swiper) => {
            window.swiper = swiper;
            return (this.swiper = swiper);
          }}
        >
          {images.map((image) => (
            <SwiperSlide>
              <img
                alt={title ? title : ''}
                className={[
                  styles.Thumbnail,
                  this.state.currentImage?.id === image.id
                    ? styles.CurrentThumbnail
                    : '',
                ].join(' ')}
                src={image.src}
                key={image.id}
                onMouseOver={() => {
                  if (!isTouchEnabled()) {
                    this.setState({ currentImage: image, currentVideo: null });
                  }
                }}
                onClick={() => {
                  if (isTouchEnabled()) {
                    this.setState({ currentImage: image, currentVideo: null });
                  }
                }}
                onFocus={() => {
                  if (!isTouchEnabled()) {
                    this.setState({ currentImage: image, currentVideo: null });
                  }
                }}
                tabIndex="0"
                onKeyPress={() => {
                  if (isTouchEnabled()) {
                    this.setState({ currentImage: image, currentVideo: null });
                  }
                }}
              />
            </SwiperSlide>
          ))}
          {videos &&
            videos.map((video) => {
              if (!video.isValid()) return '';

              return (
                <SwiperSlide>
                  <div
                    className={classNames(
                      styles.Thumbnail,
                      styles.VideoThumnail
                    )}
                    key={video.id()}
                    onMouseOver={() => {
                      if (!isTouchEnabled()) {
                        this.setState({
                          currentImage: null,
                          currentVideo: video,
                        });
                      }
                    }}
                    onFocus={() => {
                      if (!isTouchEnabled()) {
                        this.setState({
                          currentImage: null,
                          currentVideo: video,
                        });
                      }
                    }}
                    onClick={() => {
                      if (isTouchEnabled()) {
                        this.setState({
                          currentImage: null,
                          currentVideo: video,
                        });
                      } else {
                        this.pushMedia(null, video.videoUrl);
                      }
                    }}
                    tabIndex="0"
                    onKeyPress={() => {
                      if (isTouchEnabled()) {
                        this.setState({
                          currentImage: null,
                          currentVideo: video,
                        });
                      } else {
                        this.pushMedia(null, video.videoUrl);
                      }
                    }}
                  >
                    <div className={styles.Overlay}>
                      <PlayIcon className={styles.PlayIcon} />
                    </div>
                    <img
                      src={video.thumbnailUrl() || logoPlaceholderUrl(i18n)}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    );
  }

  videoPreviewComponent() {
    const { currentVideo } = this.state;
    const { history, i18n } = this.props;
    const videoPreviewClasses = classNames(styles.Main, styles.VideoPreview);

    return (
      <div className={videoPreviewClasses}>
        <img
          src={currentVideo.thumbnailUrl() || logoPlaceholderUrl(i18n)}
          className={styles.Current}
          onClick={() => this.pushMedia(null, currentVideo.videoUrl)}
          tabIndex="0"
          onKeyPress={() => this.pushMedia(null, currentVideo.videoUrl)}
        />
        <PlayIcon className={styles.PlayIcon} />
      </div>
    );
  }

  mainImageComponent() {
    const { currentImage } = this.state;
    const { i18n, title } = this.props;

    return (
      <div className={styles.Main}>
        <img
          src={(currentImage && currentImage.src) || logoPlaceholderUrl(i18n)}
          className={styles.Current}
          onClick={() => this.pushMedia(currentImage && currentImage.src)}
          onKeyPress={() => this.pushMedia(currentImage && currentImage.src)}
          tabIndex="0"
          alt={title ? title : ''}
        />
      </div>
    );
  }

  pushMedia(imageUrl, videoUrl) {
    const { history, images } = this.props;
    const { currentImage } = this.state;
    history.push({
      pathname: '/media',
      state: {
        modal: true,
        from: history.location,
        imageUrl,
        videoUrl,
        images,
        currentImage,
      },
    });
  }

  render() {
    const { currentImage, currentVideo } = this.state;
    const { className } = this.props;
    const componentClasses = classNames(styles.Slideshow, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {currentImage && this.mainImageComponent()}
        {currentVideo && this.videoPreviewComponent()}
        {this.allImagesComponent()}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(withTranslation()(Slideshow));
