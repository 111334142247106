//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Error as ErrorIcon } from '@cmp/common/icons';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Boundary extends React.Component {
  static getDerivedStateFromError = () => {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  };

  state = {
    hasError: false,
  };

  componentDidCatch = (error, info) => {
    console.error(error, info);
  };

  render() {
    const { className, children } = this.props;
    const { hasError } = this.state;

    const cn = classNames(styles.Boundary, {
      [className]: className,
    });

    if (!hasError) return children;

    return (
      <div className={cn}>
        <ErrorIcon className={styles.icon} />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Boundary;
