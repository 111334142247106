//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
import './index.css';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Badge, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import ExternalVideo from '@classes/externalVideo';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Media extends React.Component {
  constructor(props) {
    super(props);
    this.mountTrap = this.mountTrap.bind(this);
    this.unmountTrap = this.unmountTrap.bind(this);
    this.state = {
      externalVideo: null,
      imageUrl: null,
    };
  }

  componentDidMount() {
    const {
      location: { state },
    } = this.props;

    if (state.videoUrl) {
      this.setState({
        externalVideo: new ExternalVideo(state.videoUrl),
      });
    } else if (state.imageUrl) {
      this.setState({
        imageUrl: state.imageUrl,
      });
    }
    this.mountTrap();
  }
  mountTrap() {
    this.setState({ activeTrap: true });
  }

  unmountTrap() {
    this.setState({ activeTrap: false });
  }

  videoComponent() {
    const { externalVideo } = this.state;

    return (
      <div
        className={styles.Content}
        dangerouslySetInnerHTML={{ __html: externalVideo.embedCode() }}
      ></div>
    );
  }

  imageComponent() {
    const { imageUrl } = this.state;
    const { images, currentImage } = this.props.location.state;
    const selectedIndex = images.findIndex((image) => image == currentImage);

    return (
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true, style: { bottom: '0px' } }}
        style={{ width: '90%', paddingBottom: '30px' }}
        initialSlide={selectedIndex}
      >
        {images &&
          images.length > 0 &&
          images.map((image) => (
            <SwiperSlide>
              <img src={image.src} key={image.id}></img>
            </SwiperSlide>
          ))}
      </Swiper>
    );
  }

  render() {
    const { externalVideo, imageUrl } = this.state;
    const {
      history,
      location: { state },
    } = this.props;

    const componentClasses = classNames(styles.Media, {
      [styles.MediaVideo]: externalVideo,
    });

    return (
      <FocusTrap>
        <div>
          <div id="recDetailModal" tabIndex="0">
            <Modal.Base
              className={componentClasses}
              onClose={() => history.push(state.from)}
            >
              {externalVideo && this.videoComponent()}
              {imageUrl && this.imageComponent()}
            </Modal.Base>
          </div>
        </div>
      </FocusTrap>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Media;
