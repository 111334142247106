//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
import clientStyles from '@style/common/client.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import { Header, PayoutButton } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { GigyaStatus, GigyaErrorStatus } from '@helpers/constants/gigya';
import { withAuthUser, auth } from '@helpers/auth';
import { gigyaFormatter } from '@helpers/formatter';
import { updateStripeVerification } from '@helpers/account';
import payoutButton from '../../common/payoutButton';
import { findPayoutOption } from '@helpers/constants/user';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export const ProfileKind = {
  PersonalInfo: {
    title: 'Your Account',
    screenIdentified: 'edit-profile',
    shouldAuthenticate: true,
  },
  Password: {
    title: 'Change Your Password',
    screenIdentified: 'password-change',
    parsedError: ({ errorCode, errorMessage }) =>
      errorCode === GigyaErrorStatus.InvalidLoginID
        ? 'Invalid current password. Please try again.'
        : errorMessage,
  },
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
  }

  componentDidMount() {
    const { __authUser, kind } = this.props;
    if (!__authUser) window.location = '/';

    this.setupScreenSet();
  }

  setupScreenSet() {
    const { kind } = this.props;

    gigya.accounts.showScreenSet({
      screenSet: 'registration-signup',
      containerID: 'profile-container',
      startScreen: kind.screenIdentified,
      onAfterSubmit: ({ response }) => {
        if (response.status === GigyaStatus.SUCCESS) {
          if (kind.shouldAuthenticate) {
            auth(gigyaFormatter.response(response), true);

            updateStripeVerification(false);
            window.location = '/';
          }
        } else if (kind.parsedError) {
          this.setState({
            errorMessage: kind.parsedError(response),
          });
        }
      },
      onAfterScreenLoad: () => {
        document.querySelector('.gigya-array-add-btn').style.visibility =
          'hidden';
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setupScreenSet();
    }
  }

  render() {
    const { __authUser, kind, t } = this.props;
    const { errorMessage } = this.state;
    const componentClasses = classNames(styles.Profile);

    const { data } = __authUser || {};
    const foundPayoutOption = data && findPayoutOption(data.payout_option);
    const shouldPresentPayoutButton =
      foundPayoutOption && foundPayoutOption.requireStripeAccount;

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{kind.title}</title>
        </Helmet>

        <Header />

        <div className={styles.Wrapper}>
          <div className={styles.Header}>
            <h2 className={styles.Title}>{kind.title}</h2>
            <div className={styles.Payout}>
              {shouldPresentPayoutButton && (
                <PayoutButton style={styles.Payout} />
              )}
            </div>
            <Link className={styles.Help} to="/help">
              {t('profile_NeedHelp')}
            </Link>
          </div>
          <div className={styles.Content}>
            <div id="profile-container"></div>
            {errorMessage && (
              <span
                className={classNames(
                  styles.ErrorMessage,
                  clientStyles.ErrorMessage
                )}
              >
                {errorMessage}
              </span>
            )}
            {kind.additionalComponent && kind.additionalComponent(this.props)}
          </div>
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withTranslation()(Profile));
