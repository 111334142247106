//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { HowItWorks as HowItWorksSteps } from '@cmp/common';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class HowItWorks extends React.Component {
  render() {
    const { t } = this.props;
    const componentClasses = classNames(styles.HowItWorks);

    return (
      <div className={componentClasses}>
        <div className={styles.Subcontent}>
          <span className={styles.Title}>{t('landing_HowItWorks')}</span>
          <HowItWorksSteps />
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(HowItWorks);
