//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import validator from 'validator';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Form, Header, Button, ButtonStyle, Checkbox } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { ScreenSet, showScreenSet } from '@helpers/gigya';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { DoNotSellMyPersonalDataURL } from '@helpers/constants/colgate';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SignUp extends React.Component {
  componentDidMount() {
    showScreenSet(ScreenSet.SignUp, 'signup-container', {});
  }

  additionalComponent() {
    const { t } = this.props;
    return (
      <div className={styles.AdditionalComponent}>
        <a
          rel="noopener noreferrer"
          className={classNames(styles.Link, styles.SecondaryLink)}
          href={DoNotSellMyPersonalDataURL}
          target="_blank"
        >
          {t('login_DoNotSellPersonalData')}
        </a>
        <Link
          target="_blank"
          className={classNames(styles.Link, styles.SecondaryLink)}
          to="/help"
        >
          {t('login_Help')}
        </Link>
      </div>
    );
  }

  render() {
    const { history } = this.props;
    const componentClasses = classNames(styles.SignUp);

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('login_CreateAnAccount')}</title>
        </Helmet>

        <Header />
        <Form
          className={styles.Form}
          title={t('login_CreateAccount')}
          additionalComponent={this.additionalComponent()}
          id="signup-container"
        />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(SignUp);
