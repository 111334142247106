//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Header, Footer } from '@cmp/common';
import {
  Banner,
  CreateAccount,
  ExclusiveAccess,
  PatientSatisfaction,
  Testimonials,
  HowItWorks,
  InfluencerVideo,
} from './components';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { ScreenSet, showScreenSet } from '@helpers/gigya';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Landing extends React.Component {
  componentDidMount() {
    showScreenSet(ScreenSet.SignIn, 'login-container');
  }

  render() {
    const componentClasses = classNames(styles.Landing);

    return (
      <div className={componentClasses}>
        <Header />
        <div className={styles.center}>
          <div className={styles.mobileBanner}>
            <img
              className={styles.mobileBannerImg}
              src="https://cdn.shopify.com/s/files/1/0399/7427/3181/files/mobile_splash_screen_image.png?v=1711475137"
            />
          </div>
          <div className={styles.splashMessage}>
            <div className={styles.thankyou}>
              <h1>Thank you for being our valued dental partner!</h1>
            </div>
            <div className={styles.centerMessage}>
              <p>
                {' '}
                <a href="https://colgatedirect.com">ColgateDirect.com</a> is
                currently closed for sending recommendations.
              </p>

              <p>
                {' '}
                However, we value your partnership and encourage you to stay
                tuned for more incredible dental professional programs from your
                Territory Manager!{' '}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.splashFooter}>
          <img
            src="https://cdn.shopify.com/s/files/1/0399/7427/3181/files/cp_1.png?v=1711651272"
            className={styles.colgatePalmoliveLogo}
            alt="Colgate logo"
          />
          <img
            src="https://cdn.shopify.com/s/files/1/0399/7427/3181/files/2024_Colgate-Palmolive_Company._All_Rights_Reserved..png?v=1711651575"
            className={styles.allRightsReserved}
            alt="Colgate logo"
          />
        </div>

        {/* <div className={styles.Content}>
          
          <Banner />
          <HowItWorks />
          <InfluencerVideo />
          <PatientSatisfaction />
          <ExclusiveAccess />
          <Testimonials />
          <CreateAccount />
        </div> */}
        {/* <Footer /> */}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Landing;
