//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { DoNotSellMyPersonalDataURL } from '@helpers/constants/colgate';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Footer extends React.Component {
  disclosureComponent() {
    const { t } = this.props;
    const copyRightYear = new Date().getFullYear();
    return (
      <p className={styles.Disclosure}>
        &copy; {copyRightYear} {t('footer_CPCAllRightsReserved')}
        <br />
        {t('footer_YouAreViewingCountrySite')}
      </p>
    );
  }

  docsComponent() {
    return (
      <div className={styles.Docs}>
        <a href="/terms-of-service" target="_blank" className={styles.Link}>
          {t('footer_TermsOfService')}
        </a>
        <a href="/privacy-policy" target="_blank" className={styles.Link}>
          {t('footer_PrivacyPolicy')}
        </a>
        <a
          rel="noopener noreferrer"
          href={DoNotSellMyPersonalDataURL}
          target="_blank"
          className={styles.Link}
        >
          {t('footer_DoNotSellPersonalData')}
        </a>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const componentClasses = classNames(styles.Footer, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {this.disclosureComponent()}
        {this.docsComponent()}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Footer);
