// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ey46gNv02x52vdM1JVV3B{display:flex;justify-content:center;align-items:flex-start;background-color:#fafafb;height:100%}._1ey46gNv02x52vdM1JVV3B ._2QQdln6f83HD4kIW5gBvdx{margin:100px 0 20px 0}@media only screen and (max-width: 955px){._1ey46gNv02x52vdM1JVV3B ._2QQdln6f83HD4kIW5gBvdx{width:90%}}", ""]);
// Exports
exports.locals = {
	"ResetPassword": "_1ey46gNv02x52vdM1JVV3B",
	"Form": "_2QQdln6f83HD4kIW5gBvdx"
};
module.exports = exports;
