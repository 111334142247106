//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';

//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ModalBase extends React.Component {
  constructor(props) {
    super(props);
    this.escfunction = this.escfunction.bind(this);
    this.modalContext = React.createContext();
  }
  escfunction(event) {
    if (event.keyCode === 27) {
      {
        this.props.onClose();
      }
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escfunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.escfunction, false);
  }

  componentDidUpdate(prevProps) {
    const { visible, modalDidOpen, modalDidClose } = this.props;

    if (visible && prevProps.visible !== visible && modalDidOpen) {
      modalDidOpen();
    } else if (!visible && prevProps.visible !== visible && modalDidClose) {
      modalDidClose();
    }
  }

  render() {
    const { className, onClose, children } = this.props;
    const componentClasses = classNames(styles.ModalBase);

    return (
      <div className={componentClasses} onKeyPress={this.escfunction}>
        <div className={styles.BackgroundOverlay} onClick={onClose}></div>
        <div className={classNames(styles.Content, { [className]: className })}>
          {onClose && (
            <div
              className={styles.CloseIcon}
              onClick={onClose}
              onKeyPress={onClose}
              tabIndex="0"
            >
              Close
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ModalBase;
