//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Badge } from '@cmp/common';
import { ChevronLeftIcon, ChevronRightIcon } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { ReferralStatus } from '@helpers/constants/referral';
import { referralsFormatter, referralFormatter } from '@helpers/formatter';
const HistoryPageCount = 5;
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { History as RecommendationHistoryRequest } from '@api/endpoints/get/recommendation';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
    };
  }

  itemComponent(id, fullName, description, status) {
    const { onClick } = this.props;

    return (
      <div
        className={styles.Item}
        onClick={() => onClick(id)}
        onKeyPress={() => onClick(id)}
        key={id}
        tabIndex="0"
      >
        <span className={styles.FullName}>{fullName || '–'}</span>
        <span className={styles.Description}>{description}</span>
        <Badge status={status} className={styles.Badge} />
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    // Ideally the pagination component wouldn't be controlled by History,
    // and all the changes/refetches would come from the top

    const { searchValue, sortBy } = this.props;

    if (searchValue !== prevProps.searchValue || sortBy !== prevProps.sortBy) {
      this.setState({ currentPage: 0 }, this.refetch);
    }
  }

  params() {
    const { currentPage } = this.state;
    const { searchValue, sortBy } = this.props;

    const params = {
      // ReactPaginate has a zero-based page count, while our API doesn't
      page: currentPage + 1,
      limit: HistoryPageCount,
      sentOnly: true,
    };

    if (searchValue && searchValue.length > 0) {
      params.search = searchValue;
    }

    if (sortBy && sortBy.length > 0) {
      params.sort = sortBy;
    }

    if (this.props.startDate && this.props.endDate) {
      params.sentOnly = false;
      params.rangeCol = 'sentAt';
      params.rangeStart = this.props.startDate.toISOString();
      params.rangeEnd = this.props.endDate.toISOString();
    }
    return params;
  }

  render() {
    const { currentPage } = this.state;
    const { className, onChangeRefetch, t } = this.props;

    const componentClasses = classNames(styles.History, {
      [className]: className,
    });

    return (
      <RecommendationHistoryRequest params={this.params()}>
        {({ data, refetch }) => {
          this.refetch = refetch;
          onChangeRefetch(refetch);

          const { referrals, total } = data || {};
          if (!data) return '';

          return (
            <div className={componentClasses}>
              <div className={styles.Table}>
                {referrals.map((referral) => {
                  const totalPurchasePrice = referralFormatter.totalPurchasePrice(
                    referral
                  );
                  const formattedCreatedAt = referralFormatter.formattedCreatedAt(
                    referral
                  );
                  const description = totalPurchasePrice
                    ? `${totalPurchasePrice} on ${formattedCreatedAt}`
                    : formattedCreatedAt;

                  return this.itemComponent(
                    referral.id,
                    referral.consumerName,
                    referralFormatter.formattedDescription(referral, t),
                    referralFormatter.status(referral)
                  );
                })}
              </div>
              {total > 1 && (
                <ReactPaginate
                  // Basic set up
                  initialPage={currentPage}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                  previousLabel={
                    <ChevronLeftIcon alt="" aria-label="Previous" />
                  }
                  nextLabel={<ChevronRightIcon alt="" aria-label="Next" />}
                  // Page
                  forcePage={currentPage}
                  onPageChange={({ selected }) => {
                    if (currentPage !== selected) {
                      this.setState({ currentPage: selected }, refetch);
                    }
                  }}
                  pageCount={Math.ceil(total / HistoryPageCount)}
                  // Styling
                  containerClassName={styles.Pagination}
                  activeLinkClassName={styles.PageActive}
                />
              )}
            </div>
          );
        }}
      </RecommendationHistoryRequest>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(History);
