//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import validator from 'validator';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
import clientStyles from '@style/common/client.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Badge, Button, ButtonStyle, Input } from '@cmp/common';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import FormValidator from '@classes/formValidator';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { withReferral, sendReferral, clearReferral } from '@helpers/referrals';
import { responseFormatter } from '@helpers/formatter';
import { isNameValid } from '@helpers/validation';
import { updateStripeVerification, isRegistered } from '@helpers/account';
//------------------------------------------------------------------------------
// Local Storage ---------------------------------------------------------------
import { getValue, storeValue } from '@helpers/localStorage';
import {
  StripeRegistrationUrl,
  SkipStripe,
} from '@helpers/constants/localStorage';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Stripe extends React.Component {
  headerComponent() {
    const { t } = this.props;
    return (
      <div className={styles.Header}>
        <h2 className={styles.Title}>{t('dashboard_ProvidePayoutDetails')}</h2>
        <span className={styles.Subtitle}>
          {t('dashboard_ProvidePayoutDetailsDescription')}
        </span>
      </div>
    );
  }

  goToStripe() {
    const url = getValue(StripeRegistrationUrl);
    window.location = url;
  }

  formComponent() {
    const { t } = this.props;
    return (
      <div className={styles.Form}>
        <Button
          style={ButtonStyle.Secondary}
          className={styles.Button}
          onClick={this.goToStripe}
        >
          {t('dashboard_CreateStripeAccount')}
        </Button>
      </div>
    );
  }

  render() {
    const { history, location, t } = this.props;
    const componentClasses = classNames(styles.Patient);

    return (
      <Modal.Base
        className={componentClasses}
        onClose={() => history.push(location.state.from)}
      >
        <Helmet>
          <title>{t('dashboard_StripeNotification')}</title>
        </Helmet>

        {this.headerComponent()}
        {this.formComponent()}
      </Modal.Base>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Stripe);
