// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1qj2vNTVjdmY0A49vYPclM{display:flex;align-items:stretch;background-color:#fff;padding:8px 16px;border-radius:100px;border:1px solid rgba(224,224,224,.5);box-shadow:0px 2px 6px rgba(0,0,0,.06);transition:400ms linear border,400ms linear box-shadow}._1qj2vNTVjdmY0A49vYPclM:focus-within{border:1px solid #e23023;box-shadow:0px 4px 12px rgba(226,48,35,.12)}._1qj2vNTVjdmY0A49vYPclM ._2hhnMGWtoEZmvk5NX3EUj3{flex-shrink:0}._1qj2vNTVjdmY0A49vYPclM .oy0jXp6EHnfbVyIUS-N75{font-weight:bold;font-size:14px;color:#000;flex-grow:2;padding:0 8px}", ""]);
// Exports
exports.locals = {
	"SearchInput": "_1qj2vNTVjdmY0A49vYPclM",
	"Icon": "_2hhnMGWtoEZmvk5NX3EUj3",
	"Input": "oy0jXp6EHnfbVyIUS-N75"
};
module.exports = exports;
