// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".rofbUbkCYtzAjTq6KGIRG{display:flex;flex-direction:column;padding:40px 60px;max-width:475px}.rofbUbkCYtzAjTq6KGIRG>*{margin-bottom:32px}.rofbUbkCYtzAjTq6KGIRG>*:last-child{margin-bottom:0}.rofbUbkCYtzAjTq6KGIRG ._1Lh7jczzMAtADt-JFeDP5d{display:flex;flex-direction:column;align-items:center;text-align:center}.rofbUbkCYtzAjTq6KGIRG ._1Lh7jczzMAtADt-JFeDP5d>*{margin-bottom:11px}.rofbUbkCYtzAjTq6KGIRG ._1Lh7jczzMAtADt-JFeDP5d>*:last-child{margin-bottom:0}.rofbUbkCYtzAjTq6KGIRG ._1Lh7jczzMAtADt-JFeDP5d ._1toUMFV0Go7M_Nqvz7HW4s{font-weight:900;color:#d2010d;font-size:30px}@media only screen and (max-width: 955px){.rofbUbkCYtzAjTq6KGIRG ._1Lh7jczzMAtADt-JFeDP5d ._1toUMFV0Go7M_Nqvz7HW4s{font-size:26px}}.rofbUbkCYtzAjTq6KGIRG ._1Lh7jczzMAtADt-JFeDP5d ._2JHZsSkcCehKvc0VH5r8kC{font-size:14px;color:#333}.rofbUbkCYtzAjTq6KGIRG ._1RyJ1jghlMCy1E5_ARBGlr{display:flex;flex-direction:column;align-items:center}.rofbUbkCYtzAjTq6KGIRG ._1RyJ1jghlMCy1E5_ARBGlr>*{margin-bottom:16px}.rofbUbkCYtzAjTq6KGIRG ._1RyJ1jghlMCy1E5_ARBGlr>*:last-child{margin-bottom:0}.rofbUbkCYtzAjTq6KGIRG ._1RyJ1jghlMCy1E5_ARBGlr ._14I7zRf2a5ENwlZGUesZLK{font-size:14px;color:#959392;align-self:center}.rofbUbkCYtzAjTq6KGIRG ._1RyJ1jghlMCy1E5_ARBGlr ._1saam38kI9OsL63rvbu-SK,.rofbUbkCYtzAjTq6KGIRG ._1RyJ1jghlMCy1E5_ARBGlr ._3ncUrfR0ai5rhll-yJYAxg{width:100%;max-width:280px}.rofbUbkCYtzAjTq6KGIRG ._1RyJ1jghlMCy1E5_ARBGlr ._21my9-8Xcb_yrvjveHqdJ-{font-size:12px;color:#959392;text-align:center}@media only screen and (max-width: 955px){.rofbUbkCYtzAjTq6KGIRG{padding:40px 30px}}", ""]);
// Exports
exports.locals = {
	"Patient": "rofbUbkCYtzAjTq6KGIRG",
	"Header": "_1Lh7jczzMAtADt-JFeDP5d",
	"Title": "_1toUMFV0Go7M_Nqvz7HW4s",
	"Subtitle": "_2JHZsSkcCehKvc0VH5r8kC",
	"Form": "_1RyJ1jghlMCy1E5_ARBGlr",
	"Auxiliar": "_14I7zRf2a5ENwlZGUesZLK",
	"Input": "_1saam38kI9OsL63rvbu-SK",
	"Button": "_3ncUrfR0ai5rhll-yJYAxg",
	"Disclosure": "_21my9-8Xcb_yrvjveHqdJ-"
};
module.exports = exports;
