//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Form, Header, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { GigyaStatus, GigyaSessionDuration } from '@helpers/constants/gigya';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  componentDidMount() {
    let that = this;

    gigya.accounts.showScreenSet({
      screenSet: 'registration-login',
      containerID: 'forgot-password-container',
      startScreen: 'gigya-forgot-password-screen',
      sessionExpiration: GigyaSessionDuration,
      onAfterSubmit: ({ response }) => {
        that.setState({ submitted: response.status !== GigyaStatus.FAIL });
      },
    });
  }

  recoverForm() {
    const { t } = this.props;
    return (
      <Form
        className={styles.Form}
        title={t('login_RecoverYourPassword')}
        subtitle={t('login_RecoverYourPasswordSubtitle')}
        id="forgot-password-container"
        additionalComponent={
          <Link to="/login" className={styles.GoBack}>
            {t('login_GoBackToLogin')}
          </Link>
        }
      />
    );
  }

  submittedForm() {
    const { t } = this.props;
    return (
      <Form
        className={styles.Form}
        title={t('login_PasswordRecoveryEmailSent')}
        subtitle={t('login_PasswordRecoveryEmailSentSubtitle')}
        submit={t('login_BackToLogin')}
        submitTo="/"
      />
    );
  }

  render() {
    const { className, t } = this.props;
    const { submitted } = this.state;

    const componentClasses = classNames(styles.ForgotPassword, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('login_ForgotYourPassword')}</title>
        </Helmet>

        <Header />
        {submitted ? this.submittedForm() : this.recoverForm()}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(ForgotPassword);
