// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".k8MPmYjMO28rG7yFExnYO{position:fixed;display:flex;align-items:flex-start;justify-content:center;top:0;left:0;width:100%;height:100%;z-index:10;top:25% !important}.k8MPmYjMO28rG7yFExnYO ._3xXZg4X1tqS30P8_O3oJHw{position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(17,17,17,.3);z-index:0}.k8MPmYjMO28rG7yFExnYO ._1hrL6PMA-euxmzZCeviZh_{position:absolute;bottom:0;margin-bottom:5px;left:5px;right:5px}.k8MPmYjMO28rG7yFExnYO ._1hrL6PMA-euxmzZCeviZh_ ._3wq3H-_wdCmoncQqaMwQaM{position:absolute;top:20px;right:24px;cursor:pointer;font-size:18px}@media only screen and (max-width: 320px){.k8MPmYjMO28rG7yFExnYO{top:2% !important}.k8MPmYjMO28rG7yFExnYO ._1hrL6PMA-euxmzZCeviZh_ ._3wq3H-_wdCmoncQqaMwQaM{position:absolute;top:10px;right:24px;cursor:pointer;font-size:18px}}@media only screen and (max-height: 500px)and (orientation: landscape){.k8MPmYjMO28rG7yFExnYO{top:2% !important}.k8MPmYjMO28rG7yFExnYO ._1hrL6PMA-euxmzZCeviZh_ ._3wq3H-_wdCmoncQqaMwQaM{position:absolute;top:10px;right:24px;cursor:pointer;font-size:18px}}", ""]);
// Exports
exports.locals = {
	"PopoverBase": "k8MPmYjMO28rG7yFExnYO",
	"BackgroundOverlay": "_3xXZg4X1tqS30P8_O3oJHw",
	"Content": "_1hrL6PMA-euxmzZCeviZh_",
	"CloseIcon": "_3wq3H-_wdCmoncQqaMwQaM"
};
module.exports = exports;
