import { withTranslation } from 'react-i18next';
import React from 'react';
import { Header, Footer } from '@cmp/common';
import styles from './index.scss';
import { Helmet } from 'react-helmet';
import { History as RecommendationHistoryRequest } from '@api/endpoints/get/recommendation';
import classNames from 'classnames';
import { referralFormatter } from '@helpers/formatter';
import { CurrencyPrefix } from '@helpers/constants/currency';
import ReactPaginate from 'react-paginate';
import { ChevronLeftIcon, ChevronRightIcon } from '@cmp/images';
import { ReferralStatus } from '@helpers/constants/referral';
import { Modal } from '@cmp/common';

const PatientOrdersPageCount = 20;

class PatientOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
    };
  }

  headerComponent() {
    const { t } = this.props;
    return (
      <div className={styles.Header}>
        <h2 className={styles.Title}>{t('patientDirectory_OrderHistory')}</h2>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    const { searchValue, sortBy } = this.props;
    if (searchValue !== prevProps.searchValue || sortBy !== prevProps.sortBy) {
      this.setState({ currentPage: 0 }, this.refetch);
    }
  }

  orderComponent(purchasedOrder) {
    const { t } = this.props;
    const finalPrice = referralFormatter.finalPrice(purchasedOrder);
    return (
      <div key={purchasedOrder.id} className={styles.orderItem}>
        <div className={styles.orderHeader}>
          <a className={styles.orderNumber}>
            <b>
              {t('patientOrder')} #{purchasedOrder.shopifyOrderNumber}
            </b>
          </a>
          <p className={styles.orderInfo}>
            {referralFormatter.formattedPurchasedAt(
              purchasedOrder,
              'MMMM D, YYYY hh:mmA'
            )}
          </p>
        </div>
        <div className={styles.orderProducts}>
          {purchasedOrder.items
            .filter((orderItem) => orderItem.quantityPurchased > 0)
            .map((orderItem) => this.productComponent(orderItem))}
        </div>
        <div className={styles.orderMonetary}>
          {finalPrice.discountProvided && (
            <div className={styles.MonetaryItem}>
              <p className={styles.MonetaryItemDescription}>
                {t('patientOrderDiscount')}
              </p>
              <p className={styles.MonetaryItemDescription}>
                {CurrencyPrefix.USD}
                {finalPrice.discountProvided}
              </p>
            </div>
          )}
          <div className={styles.MonetaryItem}>
            <p className={styles.MonetaryItemDescription}>
              {t('patientOrderSubTotal')}
            </p>
            <p className={styles.MonetaryItemDescription}>
              {CurrencyPrefix.USD}
              {finalPrice.subtotal}
            </p>
          </div>
        </div>
      </div>
    );
  }

  productComponent(orderItem) {
    return (
      <div className={styles.orderProduct} key={orderItem.id}>
        <img src={orderItem.images[0].src} />
        <div className={styles.productDetail}>
          <p className={styles.productTitle}>
            <b>{orderItem.productName}</b>
          </p>
          <div className={styles.productAuxilar}>
            <p className={styles.productQuantity}>
              {orderItem.quantityPurchased}x
            </p>
            {orderItem.discountedPrice > 0 && (
              <p className={styles.productPrice}>
                <b>
                  {CurrencyPrefix.USD}
                  {orderItem.discountedPrice}
                </b>
              </p>
            )}
            <p className={styles.productOriginalPrice}>
              {CurrencyPrefix.USD}
              {orderItem.fullPrice}
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { currentPage } = this.state;
    const componentClasses = classNames(styles.PatientOrders);
    const { t } = this.props;
    const { history, location } = this.props;
    const { email } = this.props.location.state.fromPatientDirectory;
    const validStatuses = [
      ReferralStatus.Purchased.title,
      ReferralStatus.AdditionalPurchase.title,
      ReferralStatus.PartiallyPurchased.title,
    ];
    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('patientDirectory_OrderHistory')}</title>
        </Helmet>
        <Header />
        <div className={styles.Content}>
          <div className={styles.Container}>
            <RecommendationHistoryRequest
              params={{
                sentOnly: true,
                search: email,
                page: currentPage + 1,
                limit: PatientOrdersPageCount,
              }}
            >
              {({ data, refetch }) => {
                this.refetch = refetch;
                let { total, referrals } = data || {};
                const purchasedOrders =
                  referrals &&
                  referrals.filter((order) =>
                    validStatuses.includes(order.status)
                  );
                return (
                  <>
                    {total > 0 && this.headerComponent()}
                    <div className={styles.sectionOrders}>
                      {purchasedOrders &&
                        purchasedOrders.length > 0 &&
                        purchasedOrders?.map((purchasedOrder) => {
                          return this.orderComponent(purchasedOrder);
                        })}
                      {total == 0 && (
                        <Modal.Base
                          className={styles.modal}
                          onClose={() => history.push(location.state.from)}
                        >
                          <div className={styles.Form}>
                            <div className={styles.Header}>
                              <h2 className={styles.PopupTitle}>
                                {t('patientOrderNoOrders')}
                              </h2>
                            </div>
                          </div>
                        </Modal.Base>
                      )}
                    </div>

                    {total > 0 && (
                      <ReactPaginate
                        // Basic set up
                        initialPage={currentPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        previousLabel={
                          <ChevronLeftIcon alt="" aria-label="Previous" />
                        }
                        nextLabel={
                          <ChevronRightIcon alt="" aria-label="Next" />
                        }
                        // Page
                        forcePage={currentPage}
                        onPageChange={({ selected }) => {
                          if (currentPage !== selected) {
                            this.setState({ currentPage: selected }, refetch);
                          }
                        }}
                        pageCount={Math.ceil(total / PatientOrdersPageCount)}
                        // Styling
                        containerClassName={styles.Pagination}
                        activeLinkClassName={styles.PageActive}
                      />
                    )}
                  </>
                );
              }}
            </RecommendationHistoryRequest>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withTranslation()(PatientOrders);
