//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import moment from 'moment';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { GenericErrorMessage } from '@helpers/constants/api';
import {
  ReferralStatus,
  AllReferralStatuses,
} from '@helpers/constants/referral';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { MetafieldsKeys, MetafieldOrder } from '@helpers/constants/products';
import { CurrencyPrefix } from '@helpers/constants/currency';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { logoPlaceholderUrl } from '@cmp/images';
//------------------------------------------------------------------------------
// Gigya -----------------------------------------------------------------------
export const gigyaFormatter = {
  callbackUrl: (params) => {
    const url = new URL(`${window._env.REACT_APP_PUBLIC_URL}/callback`);
    const searchParams = new URLSearchParams(params);
    url.search = searchParams.toString();
    return url.toString();
  },

  response: (r) => {
    r.requestParams = {};
    return r;
  },

  fullName: (data) => {
    if (
      !data ||
      !data.profile ||
      (!data.profile.lastName && !data.profile.firstName)
    )
      return 'Your Account';
    if (!data.profile.lastName) return data.profile.firstName;
    if (!data.profile.firstName) return data.profile.lastName;
    return (
      data.profile.firstName + ' ' + data.profile.lastName.substring(0, 1) + '.'
    );
  },
};
//------------------------------------------------------------------------------
// Currency --------------------------------------------------------------------
export const currencyFormatter = {
  priceWithCurrency: (price, currencySymbol) => {
    if (!price) return '';
    if (!currencySymbol) return price;
    return currencySymbol + price;
  },

  trimmedPrice: (rawPrice) => {
    if (!rawPrice) return;

    const regex = /(\\|")+/g;
    return rawPrice.replace(regex, '');
  },
};
//------------------------------------------------------------------------------
// Date ------------------------------------------------------------------------
export const dateFormatter = {
  formattedDate: (date) => {
    if (!date) return '';
    return moment(date).format('MMM Do, YY');
  },
};
//------------------------------------------------------------------------------
// Shopify ---------------------------------------------------------------------
export const shopifyFormatter = {
  product: ({ metafields, images, variants }) => ({
    // Pricing -----------------------------------------------------------------
    fullPrice: (currencySymbol) => {
      const formatter = shopifyFormatter.product({
        metafields,
        images,
        variants,
      });

      const found = formatter.metafields.findOne(MetafieldsKeys.FULL_PRICE);

      if (found && found.value) {
        const trimmedPrice = currencyFormatter.trimmedPrice(found.value);
        return currencyFormatter.priceWithCurrency(
          trimmedPrice,
          currencySymbol
        );
      } else {
        const defaultVariant = formatter.defaultVariant();
        return defaultVariant && defaultVariant.price;
      }
    },

    discountedPrice: (currencySymbol) => {
      const formatter = shopifyFormatter.product({
        metafields,
        images,
        variants,
      });

      const found = formatter.metafields.findOne(
        MetafieldsKeys.DISCOUNTED_PRICE
      );
      if (!found || !found.value) return;

      const trimmedPrice = currencyFormatter.trimmedPrice(found.value);
      return currencyFormatter.priceWithCurrency(trimmedPrice, currencySymbol);
    },

    hasDiscount: () => {
      const formatter = shopifyFormatter.product({
        metafields,
        images,
        variants,
      });

      const fullPrice = formatter.fullPrice();
      const discountedPrice = formatter.discountedPrice();

      return fullPrice !== discountedPrice;
    },

    price: (prefix) => {
      const formatter = shopifyFormatter.product({
        metafields,
        images,
        variants,
      });

      const discountedMetafield = formatter.metafields.findOne(
        MetafieldsKeys.DISCOUNTED_PRICE
      );
      if (!discountedMetafield) return formatter.originalPrice();

      const trimmed = discountedMetafield.value.replace(/^"(.*)"$/, '$1');
      return (prefix || '') + trimmed;
    },

    // General -----------------------------------------------------------------
    images: () => {
      if (!images || images.length <= 0) return [];

      const sortedImages = [...images];
      sortedImages.sort((a, b) => a.position - b.position);

      return sortedImages;
    },

    defaultImage: (i18n) => {
      const formatted = shopifyFormatter.product({
        metafields,
        images,
        variants,
      });
      const sortedImages = formatted.images();

      if (!sortedImages || sortedImages.length <= 0)
        return { src: logoPlaceholderUrl(i18n) };
      return sortedImages[0];
    },

    defaultVariant: () => {
      if (!variants || variants.length <= 0) return;
      return variants[0];
    },

    metafields: {
      findOne: (key, trim) => {
        return metafields && metafields.find((x) => x.key === key);
      },

      sorted: () => {
        const sortedMetafields = [...metafields];

        sortedMetafields.sort(
          (a, b) =>
            MetafieldOrder.indexOf(a.key) - MetafieldOrder.indexOf(b.key)
        );

        return sortedMetafields;
      },
    },

    parsedVideos: () => {
      const formattedProduct = shopifyFormatter.product({ metafields });
      const videosMetafield = formattedProduct.metafields.findOne(
        MetafieldsKeys.VIDEOS
      );
      return (
        videosMetafield &&
        videosMetafield.value &&
        JSON.parse(videosMetafield.value)
      );
    },
  }),

  collections: (collections) => ({
    selectOptions: () => {
      if (!collections || collections.length <= 0) return [];

      const allOption = { label: 'All', value: null };
      const mappedCollections = collections.map((x) => ({
        label: x.title,
        value: x.id,
      }));

      return [allOption, ...mappedCollections];
    },
  }),

  variant: ({ inventory_quantity }) => ({
    isAvailable: () => {
      return inventory_quantity > 0;
    },
  }),
};
//------------------------------------------------------------------------------
// Response --------------------------------------------------------------------
export const responseFormatter = {
  error: ({ response }) => ({
    message: () => {
      const { data } = response || {};
      if (!data) return GenericErrorMessage;

      const { error } = data;
      return (error && error.message) || GenericErrorMessage;
    },
  }),

  headers: (headers) => ({
    pagination: () => {
      if (!headers) return {};

      const { link } = headers;
      if (!link) return {};

      const split = link.split(', ');

      const regex = /<(.*)>/g;

      const foundNext = split.find((x) => x.indexOf('next') > -1);
      const nextUrl = foundNext && regex.exec(foundNext)[1];

      const foundPrevious = split.find((x) => x.indexOf('prev') > -1);
      const previousUrl = foundPrevious && regex.exec(foundPrevious)[1];

      return {
        nextUrl,
        previousUrl,
      };
    },
  }),
};
//------------------------------------------------------------------------------
// Referral --------------------------------------------------------------------
export const referralFormatter = {
  formattedCreatedAt: (referral) => {
    if (!referral) return '';
    const date = moment(referral.createdAt);
    return date.format('MMM Do, YYYY');
  },

  formattedPurchasedAt: (referral, format = 'MMM Do, YY') => {
    if (!referral) return '';
    const date = moment(referral.purchasedAt);
    return date.format(format);
  },

  formattedDescription: (referral, t) => {
    if (!referral) return;

    const referralStatus = referralFormatter.status(referral);
    const { items } = referral;

    const createdAt = referralFormatter.formattedCreatedAt(referral);
    let description = createdAt;

    if (
      referralStatus === ReferralStatus.Purchased ||
      referralStatus === ReferralStatus.PartiallyPurchased ||
      referralStatus === ReferralStatus.AdditionalPurchase
    ) {
      const purchasedAmount =
        items &&
        items
          .reduce(
            (acc, currentItem) =>
              acc + parseFloat(currentItem.totalCommissionValue),
            0
          )
          .toFixed(2);

      if (purchasedAmount > 0) {
        const formattedPurchaseAmount = currencyFormatter.priceWithCurrency(
          purchasedAmount,
          CurrencyPrefix.USD
        );

        description = t('dashboard_PurchasedAmountOn', {
          amount: formattedPurchaseAmount,
          on: createdAt,
        });
      } else {
        description = t('dashboard_DonatedOn', { on: createdAt });
      }
    }

    return description;
  },

  status: ({ status }) => {
    return (
      AllReferralStatuses.find((x) => x.title === status) ||
      ReferralStatus.Pending
    );
  },

  totalPurchasePrice: ({ items }) => {
    if (!items || items.length <= 0) return;

    const purchasePrice = items.reduce(
      (acc, currentValue) =>
        acc + currentValue.totalPurchasePrice * currentValue.quantityPurchased,
      0
    );

    return purchasePrice;
  },

  purchaseProducts: (referral) => {
    const referralStatus = referralFormatter.status(referral);
    if (referralStatus === ReferralStatus.Pending) return;
    const { items } = referral;
    if (!items || items.length <= 0) return;
    return items.filter((x) => x.quantityPurchased > 0);
  },

  droppedProducts: (referral) => {
    const referralStatus = referralFormatter.status(referral);
    const { items } = referral;
    if (!items || items.length <= 0) return;
    return items.filter((x) => x.quantityPurchased < x.quantityRecommended);
  },

  sortedItems: ({ items }) => {
    const cloned = [...items];
    cloned.sort((a, b) => a.productName.localeCompare(b.productName));
    return cloned;
  },

  finalPrice: (referral) => {
    const { items } = referral;
    const referralStatus = referralFormatter.status(referral);
    const hasPurchased = referralStatus !== ReferralStatus.Pending;

    const originalSum = items.reduce(
      (acc, currentValue) =>
        acc +
        parseFloat(
          currentValue.fullPrice *
            (hasPurchased
              ? currentValue.quantityPurchased
              : currentValue.quantityRecommended)
        ),
      0
    );
    const discountedSum = items.reduce(
      (acc, currentValue) =>
        acc +
        parseFloat(
          currentValue.discountedPrice *
            (hasPurchased
              ? currentValue.quantityPurchased
              : currentValue.quantityRecommended)
        ),
      0
    );

    if (Number.isNaN(originalSum) || Number.isNaN(discountedSum)) return {};

    return {
      discountProvided: Math.abs(discountedSum - originalSum).toFixed(2),
      subtotal: Number(discountedSum).toFixed(2),
    };
  },

  totalRecommendedCount: ({ items }) => {
    return items.reduce(
      (acc, currentValue) => acc + currentValue.quantityRecommended,
      0
    );
  },
};
//------------------------------------------------------------------------------
// Referrals -------------------------------------------------------------------
export const referralsFormatter = (items) => ({
  sorted: () => {
    if (!items) return [];

    const cloned = [...items];
    cloned.sort((a, b) => b.createdAt - a.createdAt);
    return cloned;
  },

  sent: () => {
    if (!items) return [];

    return items.filter((x) => x.sentAt);
  },
});
//------------------------------------------------------------------------------
// Vimeo -----------------------------------------------------------------------
export const vimeoFormatter = (response) => ({
  idealThumbnail: () => {
    if (!response) return;

    const { data } = response;
    if (!data || data.length <= 0) return;

    const firstPicture = data[0];
    if (!firstPicture.sizes || firstPicture.length <= 0) return;

    const sortedSizes = [...firstPicture.sizes];
    sortedSizes.sort((a, b) => a.width - b.width);

    const idealSize =
      sortedSizes.find((x) => x.width > 500) ||
      sortedSizes[sortedSizes.length - 1];
    return idealSize.link;
  },
});
