// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-moz-keyframes _36SSxFfTevWsCejUWsISKu{100%{-moz-transform:rotate(360deg)}}@-webkit-keyframes _36SSxFfTevWsCejUWsISKu{100%{-webkit-transform:rotate(360deg)}}@keyframes _36SSxFfTevWsCejUWsISKu{100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.S_7I6hkgixug1I5yyff7B{display:flex;align-items:center;justify-content:center;align-self:center;color:#959392;margin:60px 0;width:100%}.S_7I6hkgixug1I5yyff7B ._1yhPsuY8zzyYe346ToSQGI{animation:_36SSxFfTevWsCejUWsISKu 1500ms linear infinite}.S_7I6hkgixug1I5yyff7B ._2HsJaadAeyq4fYd54D-V5I{display:flex;flex-direction:column;align-items:center;text-align:center}.S_7I6hkgixug1I5yyff7B ._2HsJaadAeyq4fYd54D-V5I>*{margin-bottom:8px}.S_7I6hkgixug1I5yyff7B ._2HsJaadAeyq4fYd54D-V5I>*:last-child{margin-bottom:0}", ""]);
// Exports
exports.locals = {
	"StateIndicator": "S_7I6hkgixug1I5yyff7B",
	"LoadingIndicator": "_1yhPsuY8zzyYe346ToSQGI",
	"spin": "_36SSxFfTevWsCejUWsISKu",
	"ErrorContainer": "_2HsJaadAeyq4fYd54D-V5I"
};
module.exports = exports;
